import React, { useEffect } from 'react';
import styles from './personalizeOverlay.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../types';
import CrossRing from '../icons/crossRing';
import classNames from 'classnames';
import IconArrowRight from '../icons/po_icon_arrow_right';
import * as helper from '../../helper/html-helper';
import DesignWithAdviceIcon from '../icons/designWithAdvice';
import SelfDesignIcon from '../icons/selfDesign';

export const PersonalizeOverlay = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const localization = store?.localization;

        useEffect(() => {
            if (window) {
                const searchParams = new URL(window.location.href).searchParams;

                if (searchParams.has(store?.afterLoginParam ?? '')) {
                    //drop param;
                    searchParams.delete(store?.afterLoginParam ?? '');
                    const paramsStr = searchParams.toString();
                    const newUrl = window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname +
                        (paramsStr ? '?'+ paramsStr : '') +
                        window.location.hash;
                    window.history.replaceState({}, 'unused', newUrl);

                    //open LogoCreator 
                    store?.openLogoCreatorDesigner();
                    // maybe LogoCreator is not loaded yet, make sure its opened when its ready
                    window.shell.once('ESPP.LogoCreator.InitializationFinished',
                        () => { store?.openLogoCreatorDesigner(); });
                }
            }
            
        }, []);

        function closeOverlay(): void {
            store?.showPersonalizeOverlay(false);
        }

        function LinkButtonContainer(props: {icon: React.ReactElement, label, description, 
            onClick(): void, dataTestId }): JSX.Element {
            return (
                <div className={styles.link_button} onClick={props.onClick} data-testid={props.dataTestId}>
                    <div className={styles.lbtn_title}>
                        <div className={styles.icon}>{props.icon}</div>
                        <div className={styles.label}>{props.label}</div>
                    </div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }}/>
                    <div className={styles.link_arrow}><IconArrowRight/></div>
                </div>
            );
        }

        function handleDesignYourselfClick() {
            store?.onPersonalizeIntent();
        }

        return <div className={classNames(styles.po_container,
            { [styles.show_po]: store?.poIsVisible })}>
            <div className={styles.personalize_overlay}>
                <div className={styles.po_header}>
                    <div className={styles.h_title}>{localization?.generalL10n.personalise}</div>
                    <div className={styles.h_cross_icon} onClick={closeOverlay} data-testid='pdp-mo-close'>
                        <CrossRing />
                    </div>
                </div>
                <div className={styles.po_body}>
                    <div className={styles.banner_short_info}>
                        <div className={styles.small_banner}/>
                        <p className={styles.short_info}>
                            {localization?.generalL10n.personaliseInfo}
                        </p>
                    </div>
                    <LinkButtonContainer 
                        icon={<SelfDesignIcon />}
                        label={helper.decodeHTML(localization?.generalL10n.designYourself)}
                        description={helper.decodeHTML(localization?.generalL10n.designYourselfInfo)}
                        onClick={handleDesignYourselfClick}
                        dataTestId='pdp-design_yourself'
                    />
                    <LinkButtonContainer 
                        icon={<DesignWithAdviceIcon />}
                        label={helper.decodeHTML(localization?.generalL10n.withConsulting)}
                        description={helper.decodeHTML(localization?.generalL10n.withConsultingInfo)}
                        onClick={()=>{ store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl 
                            && window.open(
                            store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl, 
                            '_self'); }}
                        dataTestId='pdp-design_with_consulting_info'
                    />
                </div>
                <div className={styles.po_footer}>
                <button className={styles.close_button} onClick={closeOverlay}>
                        {helper.decodeHTML(localization?.generalL10n.buttonCancel)}</button>
                </div>
            </div>
        </div>
    })
);
