import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import WorkWearStoreStore from '../../../../stores/WorkWearStoreStore';
import { IModel, VariantType } from '../../../../stores/types';
import styles from '../workwearStore.scss';
import { CustomSwiper } from '../../../variantPanel/customeSwiper/customeSwiper';
import defaultStyles from '../../../../styles/defaults.scss';

const breakpointMobileMinWidth = parseInt(defaultStyles.breakpointMobileMinWidth) - 1;

function WorkwearStoreModels(props: IWorkwearStoreModels) {
    const { workWearStoreStore, variantAvailabilityState, showModels, swiperDirection, width } = props;

    if (!showModels) {
        return null;
    }

    const selectedModelElementRef = useRef<HTMLInputElement>(null);

    const modelWrapperRef = useRef<HTMLInputElement>(null);
    const [modelIconsWidth, setModelIconsWidth] = React.useState(0);
    const [modelsFinishedRendering, setModelsFinishedRendering] = useState(false);

    function handleSelectedModelChangeClick(model: IModel) {
        workWearStoreStore.changeSelectedModel(model);
    }

    function calculateModelIconWidth() {
        if (modelWrapperRef.current) {
            const wrapperChildren = Array.from(modelWrapperRef.current.children as HTMLCollectionOf<HTMLElement>);
            let maxChildWidth = 0;
            wrapperChildren.forEach(element => {
                if (element.clientWidth > maxChildWidth) maxChildWidth = element.clientWidth;
            });

            if (maxChildWidth <= 37) {
                maxChildWidth = 37;
            }

            setModelIconsWidth(maxChildWidth);
            setModelsFinishedRendering(true);
        }
    }

    useEffect(() => {
        calculateModelIconWidth();
    }, []);

    let modelSwipeable;
    const modelsInRow = width >= 769 ? 5 : 1;
    let selectedModelIndex;

    if (workWearStoreStore.availableModels instanceof Array) {
        selectedModelIndex = workWearStoreStore.availableModels.findIndex(model => {
            return model.code === workWearStoreStore.selectedModel?.code;
        });

        modelSwipeable =
            (width < breakpointMobileMinWidth && workWearStoreStore.availableModels.length >= 4) ||
            (width >= breakpointMobileMinWidth && workWearStoreStore.availableModels.length >= modelsInRow * 4);
    }

    return (
        <div className={styles.model_content_wrapper}>
            <div role='model_content' className={styles.model_content}>
                <div
                    role='model_icons'
                    ref={modelWrapperRef}
                    className={classNames(styles.model_icons, {
                        [styles.vertical]: swiperDirection === 'vertical',
                        [styles.disabled]: !modelSwipeable,
                    })}
                >
                    <CustomSwiper
                        type='wws-model'
                        slidesPerColumn={modelsInRow}
                        selectedElementIndex={selectedModelIndex}
                        direction={swiperDirection}
                        mousewheel={swiperDirection === 'vertical'}
                        enabled={modelsFinishedRendering && modelSwipeable}
                        isWWS={true}
                    >
                        {workWearStoreStore.availableModels.map((availableModel, index) => (
                            <div
                                key={index + availableModel.label}
                                ref={selectedModelElementRef}
                                data-testid='model_icon'
                                style={modelIconsWidth > 0 ? { width: `${modelIconsWidth}px` } : {}}
                                className={classNames(
                                    variantAvailabilityState(availableModel.code,
                                        VariantType.Model, availableModel.salesArticleVariantKey),
                                    styles.model_icon,
                                    styles.wws,
                                    {
                                        [styles.selected]: workWearStoreStore.selectedModel?.code == availableModel.code,
                                    }
                                )}
                                onClick={() => handleSelectedModelChangeClick(availableModel)}
                            >
                                {availableModel.label}
                            </div>
                        ))}
                    </CustomSwiper>
                </div>
            </div>
        </div>
    );
}

interface IWorkwearStoreModels {
    workWearStoreStore: WorkWearStoreStore;
    variantAvailabilityState(variantNumber: number, variantType: VariantType, savKey: string): string[];
    showModels: boolean;
    swiperDirection: 'horizontal' | 'vertical';
    width: number;
}

export default WorkwearStoreModels;
