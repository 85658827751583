import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { IInjectedProps } from '../../types';
import styles from './debugBar.scss';

@inject('store')
export default class DebugBar extends Component<IInjectedProps> {
    constructor(props) {
        super(props);

        this.showDebugInfo = this.showDebugInfo.bind(this);
    }

    private showDebugInfo(): void {
        this.props.store?.turnOnDebugger();
    }

    render() {
        return (
            <div className={styles.debug_bar}>
                <button onClick={this.showDebugInfo}>{'PDP debug'}</button>
            </div>
        );
    }
}
