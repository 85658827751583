import { inject, observer } from 'mobx-react';
import React from 'react';
import { IMixedMediaStore } from '../../../types';
import IconNovelty from '../../icons/icon_novelty';
import styles from './eyeCatcher.scss';

export const EyeCatcher = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore)
            return null;

        return (
            <div data-testid='eye_catcher' className={styles.eye_catcher}>
                {mixedMediaStore.isNew && <div data-testid='eye_catcher_is_new' className={styles.ec_is_new}>
                    <span className={styles.inner_text_area}>
                        {mixedMediaStore.mainStore.localization.generalL10n.isNew}
                    </span>
                    <span className={styles.star_icon}><IconNovelty /></span>
                    </div>}
            </div>
        );
    })
);
