import React from 'react';

/* eslint-disable */
const IconArrowCircleGreyLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
        <circle cx="22.5" cy="22.5" r="22.5" />
        <path d="M24.99984,30.90058a.89856.89856,0,0,1-.65771-.28614l-7-7.5a.90037.90037,0,0,1,0-1.22851l7-7.5a.89994.89994,0,1,1,1.31543,1.22851L19.2308,22.50019l6.42676,6.88574a.90014.90014,0,0,1-.65772,1.51465Z" />
    </svg>
);

export default IconArrowCircleGreyLeft;
