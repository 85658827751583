import React from 'react';
import { observer, inject } from 'mobx-react';
import { IVariantStore } from '../../../types';
import { SizeDimension } from './sizeDimension/sizeDimension';
import styles from './sizeDimensions.scss';

export const SizeDimensions = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const availableSizeTypes = variantStore?.variantSelectionCopy.availableSizeTypes;
        const kioskAvaliableSizeTypes = variantStore?.mainStore.workWearStoreStore.availableSizeTypes;

        const mapSizeTypes = sizeType => <SizeDimension sizeType={sizeType} key={sizeType.sizeTypeName} />;

        function renderVariantStoreSizeDimensions(): JSX.Element {
            return (
                <>
                    {availableSizeTypes && availableSizeTypes.length > 1 ? (
                        <div data-testid='size_dimensions' className={styles.size_dimensions}>
                            {availableSizeTypes.map(mapSizeTypes)}
                        </div>
                    ) : null}
                </>
            );
        }

        function renderKioskSizeDimensions(): JSX.Element {
            return (
                <>
                    {kioskAvaliableSizeTypes && kioskAvaliableSizeTypes.length > 1 && (
                        <div data-testid='size_dimensions' className={styles.size_dimensions}>
                            {variantStore?.mainStore.workWearStoreStore.availableSizeTypes.map(mapSizeTypes)}
                        </div>
                    )}
                </>
            );
        }

        return (
            <>
                {variantStore?.mainStore.showKioskLayout
                    ? renderKioskSizeDimensions()
                    : renderVariantStoreSizeDimensions()}
            </>
        );
    })
);
