import React, { useEffect } from 'react';
import * as helper from '../../../helper/html-helper';
import { observer, inject } from 'mobx-react';
import { SizeDimensions } from '../sizeDimensions/sizeDimensions';
import { IVariantStore } from '../../../types';
import styles from './sizePanel.scss';
import { SizeValues } from '../sizeValues/sizeValues';
import SizeTable from '../../icons/sizeTable';
import classNames from 'classnames';
import Triangle from '../../icons/triangle';
import WWSKioskInfo from '../../workwearStoreAvailabilityModal/wwsKioskInfo/wwsKioskInfo';

export const SizePanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const isMobile = variantStore?.mainStore.isMobileShop;

        function redirectToSizeTable() {
            if (variantStore?.variantSelectionCopy.sizeTableType == 'clothing')
                window.location.href = `${variantStore.mainStore.localization.generalL10n.clothingSizeTableUrl}`;
            if (variantStore?.variantSelectionCopy.sizeTableType == 'gloves')
                window.location.href = `${variantStore.mainStore.localization.generalL10n.glovesSizeTableUrl}`;
        }

        useEffect(() => {
            if (variantStore?.showSizeAccuracy) {
                // Setting marker position on fit_accuracy_scale
                const marker = document.getElementById('marker');
                const fit_accuracy_scales = document.getElementById('fit_accuracy_scales');

                if (marker && fit_accuracy_scales && (variantStore?.selectedSize?.size.fittingAccuracy !== undefined)) {
                    const halfScaleWidth = fit_accuracy_scales.offsetWidth / 2;
                    const markerPosition = (halfScaleWidth * variantStore.selectedSize.size.fittingAccuracy) / 0.5;
                    marker.style.marginLeft = halfScaleWidth + markerPosition + 'px';
                }
            }
        });

        return (
            <div data-testid='size_panel_wrapper' className={styles.size_panel_wrapper}>
                <div className={styles.size_content}>
                    {variantStore?.variantSelectionCopy.availableSizeTypes && (
                        <div className={styles.size_panel_content}>
                            {isMobile ? (
                                <label className={styles.choosen_size_label}>
                                    {variantStore.mainStore.localization.generalL10n.selection}:
                                    {' ' + variantStore?.selectedSize?.size.label}
                                </label>
                            ) : null}
                            <SizeDimensions />
                            <SizeValues />
                            <WWSKioskInfo />
                            {!isMobile ? (
                                <label className={styles.choosen_size_label}>
                                    {variantStore.mainStore.localization.generalL10n.selection}:
                                    {' ' + variantStore?.selectedSize?.size.label}
                                </label>
                            ) : null}
                            {variantStore.variantSelectionCopy.sizeTableType && (
                                <button
                                    data-testid='button_size_table'
                                    className={styles.size_table_button}
                                    onClick={redirectToSizeTable}
                                >
                                    <SizeTable />
                                    <span className={styles.size_table_text}>
                                        {helper.decodeHTML(variantStore.mainStore.localization.generalL10n.sizeTable)}
                                    </span>
                                </button>
                            )}
                            {variantStore.showSizeAccuracy && (
                                <div data-testid='fit_accuracy_wrapper' className={styles.fit_accuracy_wrapper}>
                                    <label>
                                        {helper.decodeHTML(
                                            variantStore.mainStore.localization.generalL10n.fittingAccuracy
                                        )}
                                    </label>
                                    <div className={styles.fit_accuracy_container}>
                                        <div className={classNames(styles.fit_accuracy_border, styles.border_left)} />
                                        <div id='fit_accuracy_scales' className={styles.fit_accuracy_scales}>
                                            <div className={styles.fit_accuracy_scale} />
                                            <div className={styles.fit_accuracy_scale} />
                                            <div className={styles.fit_accuracy_scale} />
                                        </div>
                                        <div id='marker' className={styles.marker}>
                                            <Triangle />
                                        </div>
                                        <div className={classNames(styles.fit_accuracy_border, styles.border_right)} />
                                    </div>
                                    <table className={styles.section_headlines}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {helper.decodeHTML(
                                                        variantStore.mainStore.localization.generalL10n.smallerSize
                                                    )}
                                                </td>
                                                <td>
                                                    {helper.decodeHTML(
                                                        variantStore.mainStore.localization.generalL10n.matchingSize
                                                    )}
                                                </td>
                                                <td>
                                                    {helper.decodeHTML(
                                                        variantStore.mainStore.localization.generalL10n.largerSize
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    })
);
