import React from 'react';
import styles from './functionalLayers.scss';
import { IFunctionalLayer } from '../../../stores/types';
import FunctionalLayerBase from '../../icons/functionalLayerBase';
import FunctionalLayerWarmth from '../../icons/functionalLayerWarmth';
import FunctionalLayerWeather from '../../icons/functionalLayerWeather';

export default class FunctionalLayers extends React.Component<
    {functionalLayers: IFunctionalLayer[]}, {functionalLayers: IFunctionalLayer[]}> {

    constructor(props) {
        super(props);

        this.state = {
            functionalLayers: this.props.functionalLayers
        };

        this.update = this.update.bind(this);
    }

    public update(functionalLayers: IFunctionalLayer[]): void {
        this.setState({ functionalLayers: functionalLayers });
    }

    private openPopup(url): void {
        if (url)
            window.open(url, '_blank');
    }

    private getButtonIcon(key: string): JSX.Element {
        switch (key) {
            case 'Basis-Schicht':
            return <FunctionalLayerBase />
            case 'Wärme-Schicht':
                return <FunctionalLayerWarmth />
            case 'Wetterschutz-Schicht':
                return <FunctionalLayerWeather />
            default:
                return <></>
        }
    }
    
    public render() {
        return (
            this.state.functionalLayers &&
            <div className={styles.functional_layers}>
                {this.state.functionalLayers.map(fLayer => {
                    return (
                        <div key={fLayer.key} data-testid={fLayer.key}
                            className={fLayer.redirectUrl ? styles.fl_btn_active : styles.fl_btn}
                            onClick={() => this.openPopup(fLayer.redirectUrl)}>
                            {this.getButtonIcon(fLayer.key)}
                            <span dangerouslySetInnerHTML={{ __html: fLayer.buttonText }} />
                        </div>
                    );
                })}
            </div>
        );
    }
}
