import axios, { AxiosError, AxiosResponse } from 'axios';
import { IResponseData } from './types';
export class Fetch {
    private apiMagicLink: string;

    private detailComponentEndpoint = 'productdetailspage/componentsdata';
    constructor(apiMagicLink) {
        this.apiMagicLink = apiMagicLink;
        this.handleError.bind(this);
    }

    public fetch(
        salesArticleNo: string,
        colorCode: string,
        masterArticleNo: number,
        sizeCode: string,
        navigationKey?: string,
        wwsStatus?: string
    ): Promise<IResponseData> {
        const urlToHost = this.apiMagicLink;
        const apiUrl = urlToHost + this.detailComponentEndpoint;

        const requestUrl = new URL(apiUrl);
        requestUrl.searchParams.set('salesArticleNo', salesArticleNo);
        requestUrl.searchParams.set('colorCode', colorCode);
        requestUrl.searchParams.set('masterArticleNo', masterArticleNo.toString());

        if (navigationKey) requestUrl.searchParams.set('navigationKey', navigationKey);
        if (sizeCode) requestUrl.searchParams.set('sizeCode', sizeCode);
        if (wwsStatus) requestUrl.searchParams.set('availabilityStatus', wwsStatus);

        return axios
            .get(requestUrl.toString(), { timeout: 3500 })
            .then((response: AxiosResponse) => (response.status === 200 ? response.data : undefined))
            .catch(error => this.handleError(error));
    }

    public getBasketItemCount(apiUrl: string, savKey: string): Promise<number> {
        const requestUrl = new URL(apiUrl);
        requestUrl.searchParams.set('savKeyList', savKey);

        return axios
            .get(requestUrl.toString())
            .then((response: AxiosResponse) => {
                if (response.status !== 200 || !response.data) return 1;
                //service can handle multiple but we only care about one
                const savKeyToCount: { [savKey: string]: number } = response.data;
                if (!(savKey in savKeyToCount)) return 1;
                return savKeyToCount[savKey];
            })
            .catch(error => this.handleError(error));
    }

    
    public getPriceListForSizes(apiMagicLink: string, savKeys: string[]):
        Promise<object> {
        const apiUrl = apiMagicLink + 'productdetailspage/sizesprice';
        const requestUrl = new URL(apiUrl);
        savKeys.forEach(savKey => {
            requestUrl.searchParams.append('sizeSavKeys', savKey);
        });

        return axios
            .get(requestUrl.toString())
            .then((response: AxiosResponse) => {
                if (response.status !== 200 || !response.data) return 1;
                return response.data;
            })
            .catch(error => this.handleError(error));
    }

    private handleError(error: Error | AxiosError) {
        const errorMsg = this.isAxiosError(error) ? error.message : `${error.name}: ${error.message}`;
        const errorStack = this.isAxiosError(error) ? JSON.stringify(error.toJSON()) : error.stack;
        // eslint-disable-next-line no-console
        console.warn(`ajax fetch failed with "${errorMsg}". Full error:\n${errorStack}`);
        return 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isAxiosError(error: any): error is AxiosError {
        return typeof error.toJSON !== 'undefined';
    }
}
