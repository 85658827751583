import React from 'react';

/* eslint-disable */
const ExpanderIconOpenSmall = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='12.80003' height='6.3' viewBox='0 0 12.80003 6.3'>
        <path d='M6.4,6.3a.89755.89755,0,0,1-.56983-.2041l-5.5-4.5A.89973.89973,0,1,1,1.46984.20332L6.4,4.23652,11.33019.20332A.89973.89973,0,1,1,12.46984,1.5959l-5.5,4.5A.89754.89754,0,0,1,6.4,6.3Z' />
    </svg>
);

export default ExpanderIconOpenSmall;
