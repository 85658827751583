import React, { Component, ReactNode } from 'react';
import styles from './productFeature.scss';
import Content from './content';
import IconArrowCircleGreyLeft from '../../icons/icon_arrow_circle_grey_left';
import IconArrowCircleGreyRight from '../../icons/icon_arrow_circle_grey_right';
import { IProductFeaturesGroup } from '../../../stores/types';
import classNames from 'classnames';

export default class ProductFeature extends Component<
    { featuresGroup: IProductFeaturesGroup, masterArticleNo: number, colorCode: number,
        groupIndex: number, isMobileShop: boolean },
    { activeContentKey: number; pfViewPortReached: boolean }
> {
    private animationContainerId = '';
    private activeTabs: number[] = [];
    private pfRef = React.createRef<HTMLDivElement>();
    private pFArrayLength: number;
    private staticMasterArticleNo: number;
    private staticColorCode: number;

    constructor(props) {
        super(props);
        this.state = {
            activeContentKey: 1,
            pfViewPortReached: false,
        };

        this.animationContainerId = 'G_' + this.props.groupIndex + '_ProductFeature_animations_';
        this.staticMasterArticleNo = this.props.masterArticleNo;
        this.staticColorCode = this.props.colorCode;

        this.pFArrayLength = this.props.featuresGroup.features.length;
        this.handleLeftRightArrowClick = this.handleLeftRightArrowClick.bind(this);
        this.handleControlDotClick = this.handleControlDotClick.bind(this);
    }

    private handleControlDotClick(event): void {
        const tmpValue = parseInt(event.currentTarget.dataset.value);
        this.setState({
            activeContentKey: tmpValue,
        });

        this.publishSCSShowThreeSixtyView(tmpValue);
    }

    private handleLeftRightArrowClick(value: number): void {
        let tmpValue = this.state.activeContentKey + value;
        // endless navigation between product features:
        if (tmpValue > this.pFArrayLength) tmpValue = 1;

        if (tmpValue < 1) tmpValue = this.pFArrayLength;
        // ---

        this.setState({
            activeContentKey: tmpValue,
        });

        this.publishSCSShowThreeSixtyView(tmpValue);
    }

    private publishSCSShowThreeSixtyView(contentKey: number): void {
        if (!this.activeTabs.includes(contentKey)) {
            this.activeTabs.push(contentKey);
            window.shell.publishTo('SCS.Show.ThreeSixtyView', {
                containerId: this.animationContainerId + contentKey,
                showThreeDView: true,
            });
        }
    }

    private renderControlDots(): ReactNode {
        let index = 0;
        return this.props.featuresGroup.features.map(() => {
            index++;
            const cdClassName = this.state.activeContentKey === index ? styles.active : '';

            return (
                <li key={index}
                    data-testid={'pf-control-dot'}
                    className={classNames(styles.c_dot, cdClassName)}
                    data-value={index}
                    onClick={this.handleControlDotClick}
                />
            );
        });
    }

    render() {
        return (
            <div className={styles.product_feature_shutter} ref={this.pfRef}>
                {this.pFArrayLength > 1 && (
                    <div
                        className={styles.arrow_left}
                        data-testid='pf-arrow-left'
                        onClick={() => {
                            this.handleLeftRightArrowClick(-1);
                        }}
                    >
                        <IconArrowCircleGreyLeft />
                    </div>
                )}

                {this.props.featuresGroup.features.map((feature, index) => {
                    const tmpIndex = index + 1;
                    return (
                        <Content
                            showCase={feature.showCase}
                            masterArticleNo={this.staticMasterArticleNo}
                            colorCode={this.staticColorCode}
                            pfRichTextId={feature.emotionalDescriptionText.id} 
                            title={feature.emotionalDescriptionTitle}
                            logo={feature.logo}
                            key={tmpIndex}
                            contentKey={tmpIndex}
                            activeContentKey={this.state.activeContentKey}
                            containerId={this.animationContainerId + tmpIndex}
                            isMobileShop={this.props.isMobileShop}
                            singleProductFeature={this.pFArrayLength === 1}
                        />
                    );
                })}
                {this.pFArrayLength > 1 && (
                    <div
                        className={styles.arrow_right}
                        data-testid='pf-arrow-right'
                        onClick={() => {
                            this.handleLeftRightArrowClick(1);
                        }}
                    >
                        <IconArrowCircleGreyRight />
                    </div>
                )}
                {this.pFArrayLength > 1 && (
                    <>
                        <div className={styles.quick_select_and_status_info}>
                            <div className={styles.content_status_info}>
                                <span className={styles.current_article_features}>{this.state.activeContentKey}</span>
                                <span className={styles.status_divider}>/</span>
                                <span className={styles.total_article_features}>
                                    {this.pFArrayLength}
                                </span>
                            </div>
                            <ul className={styles.control_dots}>{this.renderControlDots()}</ul>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
