import React from 'react';
import classNames from 'classnames';
import styles from './bullets.scss';
import { RichText } from '../../../helper/richText';
import { IRichTextItemsInternal } from '../../../stores/types';

export default class Bullets extends React.Component<
    { structuredTextId: string; injectedRichTexts?: IRichTextItemsInternal[] },
    { structuredTextId: string; }> {

    constructor(props) {
        super(props);

        this.state = {
            structuredTextId: this.props.structuredTextId
        };
    }

    public updateBullets(structuredTextId: string): void {
        this.setState({ structuredTextId: structuredTextId });
    }

    public render() {
        return (
            <div data-testid='bullet_text'
                className={classNames(styles.bullet_text, styles.text)}>
                <RichText richTextId={this.state.structuredTextId}
                    injectedRichTexts={this.props.injectedRichTexts} />
            </div>
        )
    }
}
