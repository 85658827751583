import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './storeAvailability.scss';
import WorkwearstoreIcon from '../../icons/workwearstoreIcon';
import { IWorkWearStoreStore } from '../../../types';
import WorkwearStoreAvailabilityModal from '../../workwearStoreAvailabilityModal/workwearStoreAvailabilityModal';
import * as helper from '../../../helper/html-helper';
import classNames from 'classnames';

const StoreAvailability = inject('workWearStoreStore')(
    observer(({ workWearStoreStore }: IWorkWearStoreStore) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = workWearStoreStore!.mainStore.localization;
        const retailStore = workWearStoreStore?.wwsData?.variantsStoreInformation.find(
            store => store.retailStoreNo.toString() === workWearStoreStore.mainStore.wwsId
        );

        function handleWorkwearStoreAvailabilityClick(value) {
            value ? workWearStoreStore?.mainStore.scrollLock.lock() : workWearStoreStore?.mainStore.scrollLock.unlock();
            workWearStoreStore?.changeModalOpen(value);
            workWearStoreStore?.changeWWSVariantSelectionCopy(
                workWearStoreStore.mainStore.variantStore.variantSelectionCopyForWWS
            );
        }

        const renderStoreAvailabilityText = () => {
            let result, className;
            className = styles.not_available_in_wws_text;

            if (workWearStoreStore?.retailStoreServiceUpAndRunning) {
                let onClick, text;

                if (workWearStoreStore?.mainStore.showKioskLayout && retailStore) {
                    const availableText = helper.decodeHTML(localization.wwsOnlyL10n?.wwsLocallyAvailable.replace(
                            '{0}',
                            retailStore.retailStoreName
                        )
                    );
                    const notAvailableText = helper.decodeHTML(localization.wwsOnlyL10n?.wwsLocallyNotAvailable.replace(
                            '{0}',
                            retailStore.retailStoreName
                        )
                    );
                    text = workWearStoreStore.availableStatusInKiosk ? availableText : notAvailableText;
                    onClick = null;
                } else {
                    if (workWearStoreStore.availableInWws) {
                        className = styles.available_in_wws_text;
                        onClick = () => handleWorkwearStoreAvailabilityClick(true);
                        text = localization.wwsOnlyL10n?.storeAvailabilityLocale;
                    } else {
                        onClick = null;
                        text = helper.decodeHTML(localization.wwsOnlyL10n?.notAvailableInWorkwearStore);
                    }
                }
                result = (
                    <span data-testid='store-availability-text' className={className} onClick={onClick}>
                        {text}
                    </span>
                );
            } else {
                result = (
                    <span className={className}>
                        {helper.decodeHTML(
                            workWearStoreStore?.retailStoreServiceNotAvailable
                                ? localization.wwsOnlyL10n?.workwearStoreAvailabilityFallback
                                : localization.wwsOnlyL10n?.workwearStoreAvailabilityCheck
                        )}
                    </span>
                );
            }

            return result;
        };

        return (
            <div
                className={classNames(
                    styles.storeAvailability_container,
                    {
                        [styles.kiosk_not_avaliable]:
                            workWearStoreStore?.availableStatusInKiosk !== null &&
                            !workWearStoreStore?.availableStatusInKiosk,
                    },
                    { [styles.kiosk_avaliable]: workWearStoreStore?.availableStatusInKiosk }
                )}
            >
                <WorkwearstoreIcon />
                {renderStoreAvailabilityText()}
                {!workWearStoreStore?.mainStore.showKioskLayout && (
                    <WorkwearStoreAvailabilityModal
                        localization={localization}
                        isOpen={!!workWearStoreStore?.modalOpen}
                        onClose={() => handleWorkwearStoreAvailabilityClick(false)}
                        workWearStoreStore={workWearStoreStore}
                    />
                )}
            </div>
        );
    })
);

export default StoreAvailability;
