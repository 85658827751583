import React from 'react';
import styles from './additionalDescription_title.scss';
import { IAdditionalDescriptionTitle } from '../../../stores/types';

function AdditionalDescriptionTitle(props: IAdditionalDescriptionTitle) {
    function titleContent(type: string) {
        switch (type) {
            case 'h1-tag': return <h1>{props.text}</h1>;
            case 'h2-tag': return <h2>{props.text}</h2>;
            case 'h3-tag': return <h3>{props.text}</h3>;
            case 'h4-tag': return <h4>{props.text}</h4>;
            case 'h5-tag': return <h5>{props.text}</h5>;
            case 'h6-tag': return <h6>{props.text}</h6>;
            default: return <>{props.text}</>;
        }
    }

    return (<div className={styles.title}>
        {titleContent(props.types[0])}
    </div>);
}

export default AdditionalDescriptionTitle;
