import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import styles from './specialPanel.scss';
import { ISizeType, ISalesArticleVariantSize } from '../../../stores/types';
import { IVariantStore } from '../../../types/index';
import Length from '../../icons/length';
import Diameter from '../../icons/diameter';
import WWSKioskInfo from '../../workwearStoreAvailabilityModal/wwsKioskInfo/wwsKioskInfo';

export const SpecialPanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const sizeTypeClick = (event: React.MouseEvent<HTMLDivElement>) => {
            const clickedSizeType = event.currentTarget.dataset.sizetype;
            if (clickedSizeType) variantStore?.changeSelectedSizeType(clickedSizeType);
        };

        const sizeClick = (event: React.MouseEvent<HTMLDivElement>) => {
            if (event.currentTarget.dataset.index) {
                const index = parseInt(event.currentTarget.dataset.index);
                const clickedSize = variantStore?.selectedSizeType?.sizes[index].size;
                if (clickedSize) variantStore?.changeSelectedSize(clickedSize);
            }
        };

        return (
            <div className={styles.special_panel}>
                {variantStore?.mainStore.isMobileShop && (
                    <div data-testid='subheader' className={classNames(styles.subheader, styles.subheader_mobile)}>
                        <label>{variantStore.mainStore.localization.generalL10n.textDiameter}</label>
                        <Diameter />
                    </div>
                )}
                <div data-testid='special-size-type' className={styles.special_content}>
                    {variantStore?.mainStore.dataStore.variantSelection.availableSizeTypes.map(
                        (sizeType: ISizeType, index) => (
                            <div
                                key={index}
                                className={classNames(styles.special_value, {
                                    ['pdp-selected']:
                                        variantStore?.selectedSizeType?.sizeTypeName === sizeType.sizeTypeName,
                                    [styles.sold_out]: !variantStore.anySizeExistForSizeType(sizeType),
                                    [styles.special_value_kiosk_sold_out]: variantStore.mainStore.showKioskLayout,
                                })}
                                data-sizetype={sizeType.sizeTypeName}
                                data-testid={sizeType.sizeTypeName}
                                onClick={sizeTypeClick}
                            >
                                {sizeType.sizeTypeName}
                            </div>
                        )
                    )}
                </div>
                <label className={styles.chosen_special_size_type}>
                    {variantStore?.mainStore.localization.generalL10n.selection}:
                    {' ' + variantStore?.selectedSizeType?.sizeTypeName}
                </label>
                <div data-testid='subheader' className={styles.subheader}>
                    <label>{variantStore?.mainStore.localization.generalL10n.textLength}</label>
                    <Length />
                </div>
                <div data-testid='special-size' className={styles.special_content}>
                    {variantStore?.selectedSizeType?.sizes.map((savSize: ISalesArticleVariantSize, index) => (
                        <div
                            key={index}
                            data-index={index}
                            data-testid={savSize.size.name}
                            className={classNames(styles.special_value, {
                                ['pdp-selected']: variantStore?.selectedSize?.size.name === savSize.size.name,
                                [styles.sold_out]: !savSize.salesArticleVariantKey,
                                [styles.special_value_kiosk_sold_out]: variantStore.mainStore.showKioskLayout,
                            })}
                            onClick={sizeClick}
                        >
                            {savSize.size.name}
                        </div>
                    ))}
                </div>
                <label className={styles.chosen_special_size}>
                    {variantStore?.mainStore.localization.generalL10n.selection}:
                    {' ' + variantStore?.selectedSize?.size.name}
                </label>
                <WWSKioskInfo />
            </div>
        );
    })
);
