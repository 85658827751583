export default class DebugBarHelper {
    private static _instance: DebugBarHelper;

    // data sources:
    public static Wsd_source = 0;
    public static Pim_source = 1;
    public static Pim_Wsd_source = 2;
    public static LayoutedContentGrid_source = 3;
    public static AdditionalDescription_source = 4;
    public static Hardcode_source = 5;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public getDebuggerClassName(dataSource: number): string {
        let result: string;

        switch(dataSource) {
            case DebugBarHelper.Wsd_source:
                result = 'pdp-debug_wsd';
                break;
            case DebugBarHelper.Pim_source:
                result = 'pdp-debug_pim';
                break;
            case DebugBarHelper.Pim_Wsd_source:
                result = 'pdp-debug_pim_wsd';
                break;
            case DebugBarHelper.LayoutedContentGrid_source:
                result = 'pdp-layoutedContentGrid_source';
                break;
            case DebugBarHelper.AdditionalDescription_source:
                result = 'pdp-additionalDescription_source';
                break;
            case DebugBarHelper.Hardcode_source:
                result = 'pdp-hardcode_source';
                break;
            default:
                result = '';
                break;
        }

        return result;
    }
}
