/* eslint-disable react/no-unknown-property */
import React from 'react';
import styles from '../element_1/mainImage.scss';
import { inject, observer } from 'mobx-react';
import { IMixedMediaStore } from '../../../types';

export const SmallMainImage = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore)
            return null;

        return (
            <div className={styles.main_image_container}>
                <img data-testid='main_image' className={styles.main_image}
                    alt={mixedMediaStore.getAltValue()}
                    src={mixedMediaStore.mainImage.fullPath}
                    srcSet={mixedMediaStore.mainImage.srcSet.urls}
                    fetchpriority="high"
                />
            </div>
        );
    })
);
