import React from 'react';
import { RichText } from '../../../helper/richText';
import { IDisclaimer } from '../../../stores/types';
import styles from './disclaimers.scss';
import classNames from 'classnames';
import DebugBarHelper from '../../../helper/debugBarHelper';

export default class Disclaimers extends React.Component<{ disclaimers: IDisclaimer[], 
    debuggerIsOn: boolean, structuredTextExists: boolean, isFromArticleSet?: boolean},
    { disclaimers: IDisclaimer[], debuggerIsOn: boolean , 
        structuredTextExists: boolean, isFromArticleSet?: boolean}> {

    private debugBarHelper: DebugBarHelper;

    constructor(props) {
        super(props);

        this.state = {
            disclaimers: this.props.disclaimers,
            debuggerIsOn: this.props.debuggerIsOn,
            structuredTextExists: this.props.structuredTextExists,
            isFromArticleSet: this.props.isFromArticleSet
        };

        this.debugBarHelper = DebugBarHelper.instance;

        this.turnOnDebugger = this.turnOnDebugger.bind(this);
    }

    public turnOnDebugger(value: boolean): void {
        this.setState({ debuggerIsOn: value });
    }

    public update(disclaimers: IDisclaimer[]): void {
        this.setState({ disclaimers: disclaimers });
    }

    public render() {
        return (
            this.state.disclaimers &&
            <div className={styles.disclaimers}>
                {this.state.structuredTextExists && 
                !this.state.isFromArticleSet && <hr data-testid='disclaimers_dividing_line' />}
                {
                    this.state.disclaimers.map((disclaimer: IDisclaimer, index) => {
                        if (disclaimer.rtfText && disclaimer.rtfText.length > 0) {
                            return (<div key={index} className={classNames(styles.disclaimer,
                                {
                                    [this.debugBarHelper.getDebuggerClassName(
                                        disclaimer.dataMode)]: this.state.debuggerIsOn
                                })}
                                dangerouslySetInnerHTML={{ __html: disclaimer.rtfText }} />
                            );
                        }
                        else {
                            return (<div key={index} className={classNames(styles.disclaimer,
                                {
                                    [this.debugBarHelper.getDebuggerClassName(
                                        disclaimer.dataMode)]: this.state.debuggerIsOn
                                })}>
                                <RichText richTextId={disclaimer.structuredText.id} />
                            </div>)
                        }
                    })
                }
            </div>
        );
    }
}
