import React from 'react';

/* eslint-disable */
const IconArrowExtraSmallRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.30008" height="11.80146" viewBox="0 0 6.30008 11.80146">
        <path d="M.89956,11.80146a.90065.90065,0,0,1-.66845-1.50292l3.958-4.39747L.23111,1.50361A.9003.9003,0,0,1,1.569.29854l4.5,5a.9011.9011,0,0,1,0,1.20507l-4.5,5A.89825.89825,0,0,1,.89956,11.80146Z" />
    </svg>
);

export default IconArrowExtraSmallRight;
