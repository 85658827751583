/* eslint-disable react/no-unknown-property */
import React from 'react';
import { inject, observer } from 'mobx-react';

import { IMixedMediaStore } from '../../../../types';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styles from '../mainImage.scss';

export const NormalImage = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore)
            return null;

        let result, src, srcSet;

        if (mixedMediaStore.shouldRenderMainImageInElement2) {
            src = mixedMediaStore.get1ElementImage?.fullPath;
            srcSet = mixedMediaStore.get1ElementImage?.srcSet.urls;
        } else {
            src = mixedMediaStore.mainImage.fullPath;
            srcSet = mixedMediaStore.mainImage.srcSet.urls;
        }

        if (mixedMediaStore.shouldEnablePinchZoom()) {
            result = (
                <TransformWrapper centerZoomedOut={true}>
                    <TransformComponent>
                        <img
                            data-testid='main_image'
                            className={styles.main_image}
                            alt={mixedMediaStore.getAltValue()}
                            src={src}
                            srcSet={srcSet}
                            fetchpriority="high"
                        />
                    </TransformComponent>
                </TransformWrapper>
            );
        } else {
            result = (
                <img
                    data-testid='main_image'
                    className={styles.main_image}
                    alt={mixedMediaStore.getAltValue()}
                    src={src}
                    srcSet={srcSet}
                    fetchpriority="high"
                />
            );
        }

        return result;
    })
);

export default NormalImage;
