import React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import defaultStyles from '../../../styles/defaults.scss';
import styles from './compareShareBookmarkMobile.scss';
import Cross from '../../icons/cross';
import MoreIcon from '../../icons/moreIcon';
import ShareIcon from '../../icons/shareIcon';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import CompareBtn from '../compareShareBookmark/compareBtn';
import classNames from 'classnames';
import LogoService from '../../icons/logoservice';

const breakpointMobileExtraSmall = parseInt(defaultStyles.breakpointMobileExtraSmall);
const parsedButtonHeight = parseInt(styles.buttonHeight);
const buttonHeight = !isNaN(parsedButtonHeight) ? parsedButtonHeight : 0;

const CompareShareBookmarkMobile = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const refPersonaliseButton = React.useRef<HTMLButtonElement>(null);
        const seoSlug = store?.dataStore.breadcrumb.category.seoSlug ?? '';
        const savKey = store?.dataStore.orderModule.articleInfo.salesArticleVariantKey ?? '';
        const mpcItemOrigin = store?.orderModuleStore.mpcItemOrigin ?? '';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const generalL10n = store!.localization.generalL10n;
        const expanded = store?.orderModuleStore.compareShareBookmarkMobileExpanded;
        const [buttonCount, setButtonCount] = React.useState(4);

        const isExtraSmall = typeof window !== 'undefined' ? window.innerWidth <= breakpointMobileExtraSmall : false;

        React.useEffect(() => {
            const container = document.getElementById('compareShareBookmark-mobile-container');
            if (container) {
                const buttons = container.getElementsByTagName('BUTTON');
                let visibleButtonCount = buttons.length;

                if (refPersonaliseButton && refPersonaliseButton.current && isExtraSmall) {
                    visibleButtonCount--;
                }

                setButtonCount(visibleButtonCount > 0 ? visibleButtonCount : 0);
            }
        }, [isExtraSmall]);

        const handleExpandClick = React.useCallback(() => {
            store?.orderModuleStore.toggleCompareShareBookmarkMobileExpanded();
        }, [expanded]);

        const handleShareClick = React.useCallback(() => {
            if (navigator.share) {
                navigator.share({
                    title: generalL10n.shareLinkText.replace(
                        '{0}',
                        store?.dataStore.orderModule.articleInfo.title ?? ''
                    ),
                    text: generalL10n.shareLinkBody
                        .replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? '')
                        .replace('{1}', generalL10n.companyNameSimple),
                    url: window.location.href,
                });
            } else {
                window.location.href =
                    'mailto:?subject=' +
                    encodeURIComponent(
                        generalL10n.shareLinkText.replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? '')
                    ) +
                    '&body=' +
                    encodeURIComponent(
                        generalL10n.shareLinkBody
                            .replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? '')
                            .replace('{1}', generalL10n.companyNameSimple)
                    ) +
                    ' ' +
                    window.location.href.split('?')[0];
            }
        }, []);

        const expandButtonClassNames = expanded
            ? [styles.compareShareBookmark_mobile_expand_button, styles.expanded].join(' ')
            : styles.compareShareBookmark_mobile_expand_button;
        const buttonContainerClassNames = expanded
            ? [styles.compareShareBookmark_mobile_button_container, styles.expanded].join(' ')
            : styles.compareShareBookmark_mobile_button_container;

        const actionButtonsTop = -(buttonHeight * buttonCount);

        return (
            <div
                id='compareShareBookmark-mobile-container'
                data-testid='compareShareBookmark-mobile-container'
                className={classNames(styles.compareShareBookmark_mobile_container, {
                    [styles.compareShareBookmark_mobile_expand]:
                        store?.orderModuleStore.compareShareBookmarkMobileExpanded,
                })}
            >
                <button data-testid='expand-button' className={expandButtonClassNames} onClick={handleExpandClick}>
                    {expanded ? <Cross /> : <MoreIcon />}
                </button>
                <div
                    className={styles.compareShareBookmark_mobile_action_buttons}
                    style={{ top: actionButtonsTop, pointerEvents: expanded ? 'initial' : 'none' }}
                >
                    {/* smake variant is maintained and ft is on */}
                    {store?.dataStore.contextData.featureToggles.enableLogoCreator &&
                        (store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false) && (
                        <div className={classNames(buttonContainerClassNames, styles.personalizeButton)}>
                            <button
                                ref={refPersonaliseButton}
                                onClick={() => { store.showPersonalizeOverlay(true) }}
                                className={styles.compareShareBookmark_mobile_action_button}
                            >
                                <LogoService />
                                {generalL10n.personalise}
                            </button>
                        </div>
                        )}
                    {/* smake variant is not maintained but ft is on */}
                    {store?.dataStore.contextData.featureToggles.enableLogoCreator
                        && store.dataStore.bannerModule.logoServiceRedirectUrl
                        && !(store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false) && (
                            <div className={classNames(buttonContainerClassNames, styles.personalizeButton)}>
                                <button
                                    ref={refPersonaliseButton}
                                onClick={() => {
                                    store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl
                                    && window.open(
                                        store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl,
                                        '_self'); }}
                                    className={styles.compareShareBookmark_mobile_action_button}
                                >
                                    <LogoService />
                                    {generalL10n.personalise}
                                </button>
                            </div>
                        )}
                    {!store?.showKioskLayout && (
                        <div className={buttonContainerClassNames}>
                            <button
                                onClick={handleShareClick}
                                className={styles.compareShareBookmark_mobile_action_button}
                            >
                                <ShareIcon />
                                {generalL10n.shareButtonLocale}
                            </button>
                        </div>
                    )}
                    <div className={buttonContainerClassNames}>
                        <CompareBtn
                            savKey={savKey}
                            seoSlug={seoSlug}
                            itemOrigin={mpcItemOrigin}
                            data-testid='mpc-button'
                        />
                    </div>
                    {store?.dataStore?.contextData?.portalMandantToken != 'GLB' && (
                        <div className={buttonContainerClassNames}>
                            <FragmentPlaceholder
                                src={`/ESPP1-Bookmark/bookmark-add-button?containerId=${store?.getBookmarkContainerID}`}
                                ignoreErrors={true}
                                timeout={2}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    })
);

export default CompareShareBookmarkMobile;
