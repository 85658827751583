import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './variantPanel.scss';
import classNames from 'classnames';
import Cross from '../icons/cross';
import Apply from '../icons/apply';
import { IVariantStore } from '../../types';
import { VariantType } from '../../stores/types';
import { ColorPanel } from './colorPanel/colorPanel';
import { SizePanel } from './sizePanel/sizePanel';
import { SpecialPanel } from './specialPanel/specialPanel';
import { VariantButton } from './variantButton/variantButton';
import { ModelPanel } from './modelPanel/modelPanel';
import { useViewportWidth } from '../../helper/viewPortWidth';
import Diameter from '../icons/diameter';

export const VariantPanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };
        const variantSelection = variantStore?.mainStore.dataStore.variantSelection;
        const closeClick = () => {
            if (variantStore?.isVariantPanelOpen) variantStore.changeVariantPanelDisplay(false);
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            window.addEventListener('resize', handleWindowResize, false);

            variantStore?.mainStore.setStickyHeaderTop();

            if (variantStore?.mainStore.showKioskLayout) {
                variantStore.changeVariantPanelDisplay(true, VariantType.Kiosk, width);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                window.removeEventListener('resize', handleWindowResize);
            };
        }, []);

        function handleWindowResize(): void {
            if (variantStore?.isVariantPanelOpen) variantStore.setPanelPosition(window.innerWidth);
        }

        function handleClickOutside(event) {
            if (variantStore?.isVariantPanelOpen && variantStore.allowedInteraction) {
                const variantPanel = document.getElementById('variant_panel');
                const variantButtonList = Array.from(document.getElementsByClassName('pdp-variant_button'));
                const variantButtonClicked = variantButtonList.find(element => {
                    return element.contains(event.target);
                });

                if (variantPanel && !variantStore.mainStore.isMobileShop && !variantButtonClicked) {
                    if (!variantPanel.contains(event.target)) {
                        closeClick();
                        event.stopPropagation();
                    }
                }
            }
        }

        const applyChange = () => {
            // variant change already applied on desktop when this event is called
            variantStore?.mainStore.isMobileShop && variantStore?.updateAll();
            if (variantStore?.isVariantPanelOpen) variantStore.changeVariantPanelDisplay(false);
        };

        const revertSelectionAndClosePanel = () => {
            variantStore?.resetVariantState();
            if (variantStore?.isVariantPanelOpen) variantStore.changeVariantPanelDisplay(false);
        };

        const desktopHeader = (labelText: string) => {
            return (
                <>
                    <label>{labelText}</label>
                    <div role='close_button' className={styles.close_button} onClick={closeClick}>
                        <Cross />
                    </div>
                </>
            );
        };

        const colorButton = () => {
            if (variantSelection?.showColorSwitcher)
                return <VariantButton variantType={VariantType.Color} isMobileHeader={true} />;
            else {
                if (variantSelection?.availableColors[0]?.code !== 0)
                    return <VariantButton variantType={VariantType.Color} isMobileHeader={true} isDeactive={true} />;
                else return <></>;
            }
        };

        const modelButton = () => {
            if (variantSelection?.showModelSwitcher)
                return <VariantButton variantType={VariantType.Model} isMobileHeader={true} />;
            else if (variantSelection?.availableModels[0]?.code !== 0)
                return <VariantButton variantType={VariantType.Model} isMobileHeader={true} isDeactive={true} />;
            else return <></>;
        };

        return (
            <div
                role='panel'
                id='variant_panel'
                style={{ ...variantStore?.panelPosition }}
                className={classNames(
                    styles.variant_panel,
                    {
                        [styles.mobile_variant_panel]: variantStore?.mainStore.isMobileShop,
                    },
                    {
                        // For test reason to use pure string
                        ['pdp-show']: variantStore?.isVariantPanelOpen,
                    },
                    {
                        [styles.block]: !variantStore?.allowedInteraction,
                    }
                )}
            >
                <div role='mobile_header' className={classNames(styles.header, styles.mobile_h)}>
                    {colorButton()}
                    {variantSelection?.showSizeSwitcher && !variantSelection.isScrew && (
                        <VariantButton variantType={VariantType.Size} isMobileHeader={true} />
                    )}
                    {variantSelection?.showSizeSwitcher && variantSelection.isScrew && (
                        <VariantButton variantType={VariantType.Special} isMobileHeader={true} />
                    )}
                    {modelButton()}
                </div>
                <div role='header' className={classNames(styles.header, styles.desktop_h)}>
                    {variantStore?.variantType === VariantType.Color &&
                        desktopHeader(variantStore.mainStore.localization.generalL10n.textColor)}
                    {variantStore?.variantType === VariantType.Size &&
                        desktopHeader(variantStore.mainStore.localization.generalL10n.textSize)}
                    {variantStore?.variantType === VariantType.Special && (
                        <>
                            <div className={styles.special_header}>
                                <label>{variantStore.mainStore.localization.generalL10n.textDiameter}</label>
                                <Diameter />
                            </div>
                            <div role='button' className={styles.close_button} onClick={closeClick}>
                                <Cross />
                            </div>
                        </>
                    )}
                    {variantStore?.variantType === VariantType.Model &&
                        desktopHeader(variantStore.mainStore.localization.generalL10n.textModel)}
                </div>

                <div role='content' className={styles.content}>
                    {variantStore?.variantType === VariantType.Color && <ColorPanel />}
                    {variantStore?.variantType === VariantType.Size && <SizePanel />}
                    {variantStore?.variantType === VariantType.Special && <SpecialPanel />}
                    {variantStore?.variantType === VariantType.Model && <ModelPanel />}
                </div>
                <div role='footer' className={classNames(styles.footer, styles.mobile_f)}>
                    <div
                        className={classNames(styles.button, styles.cancel)}
                        data-testid='footer-cancel-button'
                        onClick={revertSelectionAndClosePanel}
                    >
                        <Cross />
                        <div className={styles.button_title}>
                            {variantStore?.mainStore.localization.generalL10n.buttonClose}
                        </div>
                    </div>
                    <div
                        className={classNames(styles.button, styles.apply)}
                        onClick={applyChange}
                        data-testid='footer-apply-button'
                    >
                        <Apply />
                        <div className={styles.button_title}>
                            {variantStore?.mainStore.localization.generalL10n.buttonApply}
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
