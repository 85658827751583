import { observable, makeObservable, action, computed } from 'mobx';
// eslint-disable-next-line max-len
import {
    IColor,
    ISizeType,
    ISalesArticleVariantSize,
    ISize,
    IModel,
    IAvailableColor,
    IAvailableModel,
    IVariantSelection,
    ITranslationFragment,
    VariantType,
    AvailabilityStatusEnum,
} from './types';

import defaultStyles from '../styles/defaults.scss';
import { ScrollLock } from '../helper/scrollLock';
import { MainStore } from './MainStore';
import * as helper from '../helper/html-helper';

export class VariantStore {
    public mainStore: MainStore;
    public readonly scrollLock = new ScrollLock();
    public variantSelectionCopy: IVariantSelection;
    public variantSelectionCopyForWWS: IVariantSelection;
    private mobileMinWidth = parseInt(defaultStyles.breakpointMobileMinWidth);

    // @observable:
    // UI
    public isVariantPanelOpen: boolean;
    public variantType: VariantType;
    public allowedInteraction: boolean;
    public panelPosition: { top: number; height: string } | null;

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        // If we have more than one avaliable color MAINTAINED, showColorSwitcher will be True.
        // showColorSwitcher false can have two cases:
        // 1. No maintained color, then availableColors will have 1 element but with code 0.
        // 2. Has maintained one color, the availableColors also will have 1 element but have code > 0.
        // showColorSwitcher true case can still lead to availableColors to only have 1 element.
        // That means all other colors are sold out.
        this.variantType = mainStore.dataStore.variantSelection.showColorSwitcher
            ? VariantType.Color
            : VariantType.None;
        this.isVariantPanelOpen = false;
        this.panelPosition = null;
        this.variantSelectionCopy = JSON.parse(JSON.stringify(mainStore.dataStore.variantSelection));
        this.variantSelectionCopyForWWS = this.variantSelectionCopy;
        this.allowedInteraction = true;

        makeObservable(this, {
            isVariantPanelOpen: observable,
            panelPosition: observable,
            variantType: observable,
            variantSelectionCopy: observable,
            allowedInteraction: observable,
            changeVariantPanelDisplay: action,
            setPanelPosition: action,
            selectedColor: computed,
            changeSelectedColor: action,
            changeSelectedSizeType: action,
            changeSelectedSize: action,
            changeSelectedModel: action,
            selectedSizeType: computed,
            selectedSize: computed,
            sideImagePath: computed,
            variantButtonCount: computed,
            selectedModel: computed,
            resetVariantState: action,
            variantChange: action,
            updateAll: action,
            changeInteraction: action,
            updateVariantSelectionCopy: action,
            showSizeAccuracy: computed,
        });
    }

    // Normal method:
    public getUrlByAvailableColor(color: IAvailableColor): string {
        const salesArticleVariantKey = color.salesArticleVariantKey.split('-');
        return this.getUrl(salesArticleVariantKey[0], salesArticleVariantKey[1]);
    }

    public anySizeExistForSizeType(sizeType: ISizeType): boolean {
        const existSize = sizeType.sizes.find((size: ISalesArticleVariantSize) => {
            return !!size.salesArticleVariantKey;
        });
        return !!existSize;
    }

    private updateTranslatedUrl(translateFragmentJSON: string) {
        let translateFragment: ITranslationFragment | null = null;
        try {
            translateFragment = JSON.parse(translateFragmentJSON.trim());
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('An error on casting object has occurred', e);
        }

        if (translateFragment) {
            translateFragment.translations.forEach((translationObject) => {
                this.variantSelectionCopy.translationUrls.forEach((translationUrl) => {
                    const cultureCode = translationUrl.culture.split('-', 1);
                    if (cultureCode.length > 0 && cultureCode[0] === translationObject.code)
                        translationObject.url = translationUrl.url;
                });
            });
            helper.setTranslateUrl(JSON.stringify(translateFragment));
        }
    }

    private getUrl(salesArticleNo: string, colorCode: string): string {
        // this.mainStore.dataStore.contextData.originalUrl needed only for startup, 
        // when server side rendering is in progress and window object is not yet defined.
        // During that moment it is the only source of truth and it is correct 
        const rawUrl = typeof window !== 'undefined' ? window.location.href
            : this.mainStore.dataStore.contextData.originalUrl;
        const originalUrl = new URL(rawUrl);
        const lastItem = originalUrl.pathname.substring(originalUrl.pathname.lastIndexOf('/') + 1);
        const subStrings = lastItem.split('-');
        const colorCodeIndex = subStrings.length - 1;
        const salesArticleNoIndex = subStrings.length - 2;
        subStrings[colorCodeIndex] = colorCode;
        subStrings[salesArticleNoIndex] = salesArticleNo;
        const newLastPath = subStrings.join('-') + '.html';
        const url = new URL(newLastPath, originalUrl);
        const query = new URLSearchParams(originalUrl.search);
        query.forEach((value, key) => {
            url.searchParams.set(key, value);
        });
        return url.href;
    }

    private changeVariantType(variantType?: VariantType) {
        if (typeof variantType !== 'undefined') {
            if (variantType !== VariantType.Kiosk) {
                this.variantType = variantType;
            } else {
                if (this.variantSelectionCopy.showSizeSwitcher) {
                    if (this.variantSelectionCopy.isScrew) {
                        this.variantType = VariantType.Special;
                    } else {
                        this.variantType = VariantType.Size;
                    }
                } else if (this.variantSelectionCopy.showModelSwitcher) {
                    this.variantType = VariantType.Model;
                } else if (this.variantSelectionCopy.showColorSwitcher) {
                    this.variantType = VariantType.Color;
                }
            }
        }
    }

    // @action:
    changeVariantPanelDisplay(open: boolean, variantType?: VariantType, width?: number): void {
        this.changeVariantType(variantType);

        // don't open panel when don't have proper type setup
        if (this.variantType === VariantType.None || this.variantType === VariantType.Kiosk) open = false;

        if (!this.isVariantPanelOpen || !open)
            if (open) {
                this.scrollLock.lock();
                this.mainStore.hideEsAppButton();

                // set panel position:
                if (width)
                    this.panelPosition = this.getPanelPosition(width);
            } else {
                this.scrollLock.unlock();
                this.mainStore.showEsAppButton();
            }

        this.isVariantPanelOpen = open;
    }

    // @action:
    setPanelPosition(width: number): void {
        this.panelPosition = this.getPanelPosition(width);
    }

    private getPanelPosition(width: number): { top: number; height: string } | null {
        const minDeskTopWidth = this.mobileMinWidth;

        const showVariantPanelBelowStickyHeader = width > minDeskTopWidth - 1;
        const adjustStickHeaderHeight = this.mainStore.orderModuleStore.stickHeaderHeight + this.mainStore.stickyHeaderTop;
        return adjustStickHeaderHeight > 0 && showVariantPanelBelowStickyHeader
            ? { top: adjustStickHeaderHeight, height: `calc(100% - ${adjustStickHeaderHeight}px)` }
            : null;
    }

    changeSelectedColor(updatedSelectedColor: IColor): void {
        if (this.variantSelectionCopy.selectedColor?.code !== updatedSelectedColor.code) {
            const selectedAvailableColor = this.variantSelectionCopy.availableColors.find(color => {
                return color.code === updatedSelectedColor.code;
            });

            if (selectedAvailableColor) {
                this.variantSelectionCopy.selectedColor = updatedSelectedColor;
                const salesArticleVariantKey = selectedAvailableColor.salesArticleVariantKey.split('-');
                let availabilityState: string | undefined;
                if (this.mainStore.showKioskLayout) {
                    const tmp = this.mainStore.workWearStoreStore.variantAvailabilityState(updatedSelectedColor.code,
                        VariantType.Color,
                        selectedAvailableColor.salesArticleVariantKey,
                        this.mainStore.workWearStoreStore.variantInfoInKiosk);
                    availabilityState = AvailabilityStatusEnum[tmp];
                }
                this.variantChange(
                    this.isDeskTopView(),
                    salesArticleVariantKey[0],
                    salesArticleVariantKey[1],
                    salesArticleVariantKey[2],
                    availabilityState,
                );
                if (this.mainStore.showKioskLayout)
                    this.mainStore.workWearStoreStore.changeSelectedColor(updatedSelectedColor);
            }
        }
    }

    changeSelectedModel(updatedSelectedModel: IModel): void {
        if (this.variantSelectionCopy.selectedModel?.code !== updatedSelectedModel.code) {
            this.variantSelectionCopy.selectedModel = updatedSelectedModel;
            this.updateModel(this.isDeskTopView());
        }
    }

    changeSelectedSizeType(updatedSelectedSizeType: string): void {
        const sizeType = this.variantSelectionCopy.availableSizeTypes.find(aSizeType => {
            return aSizeType.sizeTypeName === updatedSelectedSizeType;
        });
        if (sizeType) {
            this.variantSelectionCopy.selectedSizeType = updatedSelectedSizeType;
            const defaultSize = sizeType.sizes.find(size => {
                return size.salesArticleVariantKey;
            });
            if (defaultSize)
                this.changeSelectedSize(defaultSize.size);
        }
    }

    changeSelectedSize(updatedSelectedSize: ISize): void {
        if (this.variantSelectionCopy.selectedSize?.code !== updatedSelectedSize.code) {
            this.variantSelectionCopy.selectedSize = updatedSelectedSize;
            this.updateVariantSizeSelected(this.isDeskTopView());
        }
    }

    resetVariantState() {
        this.variantSelectionCopy = JSON.parse(JSON.stringify(this.mainStore.dataStore.variantSelection));
    }

    private updateVariantSizeSelected(shouldUpdateWholePage: boolean) {
        const salesArticleVariantKey = this.selectedSize?.salesArticleVariantKey.split('-');
        if (salesArticleVariantKey) {
            let availabilityState: string | undefined;
            if (this.mainStore.showKioskLayout) {
                if (this.selectedSize?.size.code) {
                    const tmp = this.mainStore.workWearStoreStore.variantAvailabilityState(
                        this.selectedSize?.size.code,
                        VariantType.Model,
                        this.selectedSize?.salesArticleVariantKey,
                        this.mainStore.workWearStoreStore.variantInfoInKiosk);
                    availabilityState = AvailabilityStatusEnum[tmp];
                }
            }
            this.variantChange(
                shouldUpdateWholePage,
                salesArticleVariantKey[0],
                salesArticleVariantKey[1],
                salesArticleVariantKey[2],
                availabilityState,
            );
        }
    }

    private updateModel(shouldUpdateWholePage: boolean) {
        const selectedAvailableModel = this.variantSelectionCopy.availableModels.find(model => {
            return model.code === this.selectedModel?.code;
        });
        if (selectedAvailableModel) {
            const splitVariantKey = selectedAvailableModel.salesArticleVariantKey.split('-');
            const salesArticleNo = splitVariantKey[0];
            const colorCode = splitVariantKey[1];
            const sizeCode = splitVariantKey[2];
            let availabilityState: string | undefined;
            if (this.mainStore.showKioskLayout) {
                const tmp = this.mainStore.workWearStoreStore.variantAvailabilityState(selectedAvailableModel.code,
                    VariantType.Model,
                    selectedAvailableModel.salesArticleVariantKey,
                    this.mainStore.workWearStoreStore.variantInfoInKiosk);
                availabilityState = AvailabilityStatusEnum[tmp];
            }
            this.variantChange(shouldUpdateWholePage, salesArticleNo, colorCode, sizeCode, availabilityState);
        }
    }

    private isDeskTopView(): boolean {
        return window.innerWidth >= this.mobileMinWidth;
    }

    public updateAll() {
        if (this.variantSelectionCopy.selectedModel &&
            this.variantSelectionCopy.selectedModel.code != 0) this.updateModel(true);
        else this.updateVariantSizeSelected(true);
    }

    public changeInteraction(allowedInteraction: boolean) {
        this.allowedInteraction = allowedInteraction;
    }

    public updateVariantSelectionCopy(data: IVariantSelection) {
        this.variantSelectionCopy = data;
        const translateFragmentText = helper.getTranslateUrl();
        if (translateFragmentText)
            this.updateTranslatedUrl(translateFragmentText);
    }

    public variantChange(shouldUpdateWholePage: boolean,
                         salesArticleNo: string, colorCode: string, sizeCode: string, wwsStatus?: string) {
        const fetchedData = this.mainStore.fetchedData;

        if (fetchedData.orderModule.articleInfo.salesArticleNo === salesArticleNo &&
            fetchedData.variantSelection.selectedColor?.code.toString() === colorCode &&
            fetchedData.variantSelection.selectedSize?.code.toString() === sizeCode) {
            return;
        }

        const navKey = fetchedData.breadcrumb.category.navigationKey;
        const masterarticleNo = fetchedData.orderModule.articleInfo.masterArticleNo;
        this.changeInteraction(false);
        this.mainStore.fetch.fetch(
            salesArticleNo, colorCode, masterarticleNo, sizeCode, navKey, wwsStatus)
            .then(response => {
                if (response) {
                    this.mainStore.checkSelectedSizeType(response.variantSelection);
                    this.updateVariantSelectionCopy(response.variantSelection);
                    this.variantSelectionCopyForWWS = response.variantSelection;
                    if (shouldUpdateWholePage) {
                        this.mainStore.variantChanged(response);
                        this.mainStore.updateUrl(this.getUrl(salesArticleNo, colorCode));
                    }
                } else this.resetVariantState();
                this.changeInteraction(true);
            });
    }

    // @computed:
    get selectedColor(): IColor {
        return {
            code: this.variantSelectionCopy.selectedColor?.code ?? 0,
            name: this.variantSelectionCopy.selectedColor?.name.split('/').join(' / ') ?? '',
        };
    }

    get selectedModel(): IModel | undefined {
        return this.variantSelectionCopy.selectedModel;
    }

    get selectedSizeType(): ISizeType | undefined {
        const updatedSelectedSizeType = this.variantSelectionCopy.selectedSizeType;
        return this.variantSelectionCopy.availableSizeTypes.find(sizeType => {
            return sizeType.sizeTypeName === updatedSelectedSizeType;
        });
    }

    get selectedSize(): ISalesArticleVariantSize | undefined {
        let result: ISalesArticleVariantSize | undefined;
        const preselectedSizeType = this.selectedSizeType?.sizes.find(size => {
            return size.size.code === this.variantSelectionCopy.selectedSize?.code;
        });

        if (preselectedSizeType) result = preselectedSizeType;
        else result = this.selectedSizeType?.sizes[0];

        if (result?.size.fittingAccuracy)
            if (result.size.fittingAccuracy < -0.5) result.size.fittingAccuracy = -0.5;
            else if (result.size.fittingAccuracy > 0.5) result.size.fittingAccuracy = 0.5;

        return result;
    }

    get sideImagePath(): string {
        if (this.selectedModel && this.selectedModel.code != 0) {
            const selectedModel: IAvailableModel | undefined = this.variantSelectionCopy.availableModels.find(model => {
                return model.code === this.selectedModel?.code;
            });
            return selectedModel ? selectedModel.defaultArticleImagePath : '';
        } else if (this.selectedColor.code != 0) {
            const selectedColor: IAvailableColor | undefined = this.variantSelectionCopy.availableColors.find(color => {
                return color.code === this.selectedColor.code;
            });
            return selectedColor ? selectedColor.defaultArticleImagePath : '';
        }
        return '';
    }

    get variantButtonCount(): number {
        let variantButtonCount = 0;

        if (this.variantSelectionCopy.showColorSwitcher) {
            variantButtonCount++;
        }
        if (this.variantSelectionCopy.showSizeSwitcher) {
            variantButtonCount++;
        }
        if (this.variantSelectionCopy.showModelSwitcher) {
            variantButtonCount++;
        }
        return variantButtonCount;
    }

    get showAvailableStatusInKiosk(): boolean {
        return !this.variantSelectionCopy.showModelSwitcher && !this.variantSelectionCopy.showSizeSwitcher;
    }

    get showSizeAccuracy(): boolean {
        // Fitting Accuracy can be 0 and its a valid data to present
        return (this.selectedSize?.size.fittingAccuracy !== null) &&
            (this.selectedSize?.size.fittingAccuracy !== undefined);
    }
}
