import React from 'react';

/* eslint-disable */
const IconArrowCircleGreyRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
        <circle cx="22.5" cy="22.5" r="22.5"/>
        <path d="M19.99976,30.90058a.90014.90014,0,0,1-.65771-1.51465l6.42676-6.88574-6.42676-6.88575a.89994.89994,0,1,1,1.31543-1.22851l7,7.5a.90039.90039,0,0,1,0,1.22851l-7,7.5A.89779.89779,0,0,1,19.99976,30.90058Z"/>
    </svg>
);

export default IconArrowCircleGreyRight;
