import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './variantButtonList.scss';
import { VariantButton } from '../variantButton/variantButton';
import { VariantType } from '../../../stores/types';
import { IInjectedProps } from '../../../types';

export const VariantButtonList = inject('store')(
    observer(({ store, isStickyHeader, isMobileHeader }: IInjectedProps) => {
        const variantStore = store?.variantStore;
        const variantSelection = store?.dataStore.variantSelection;

        const variantContainerClassNames = (isStickyHeader
            ? [styles.variants_container, styles.isStickyHeader]
            : [styles.variants_container]
        ).join(' ');

        const variantListClassNames = (isStickyHeader
            ? [styles.variants_list, styles.isStickyHeader]
            : [styles.variants_list]
        ).join(' ');

        const colorButton = () => {
            if (!variantSelection?.showColorSwitcher && variantSelection?.availableColors[0]?.code === 0) {
                return <></>;
            }

            return (
                <div className={styles.variants_container_item}>
                    <VariantButton
                        variantType={VariantType.Color}
                        isMobileHeader={isMobileHeader}
                        isStickyHeader={isStickyHeader}
                        isDeactive={!variantSelection?.showColorSwitcher}
                    />
                    <span className={variantListClassNames}>{variantStore?.selectedColor.name}</span>
                </div>
            );
        };

        const modelButton = () => {
            if (!variantSelection?.showModelSwitcher && variantSelection?.availableModels[0]?.code === 0) {
                return <></>;
            }

            return (
                <div className={styles.variants_container_item}>
                    <VariantButton
                        variantType={VariantType.Model}
                        isMobileHeader={isMobileHeader}
                        isStickyHeader={isStickyHeader}
                        isDeactive={!variantSelection?.showModelSwitcher}
                    />
                    <span className={variantListClassNames} />
                </div>
            );
        };

        return (
            <div data-testid='variant_button_list_container' className={variantContainerClassNames}>
                {colorButton()}
                {variantSelection?.showSizeSwitcher && !variantSelection.isScrew && (
                    <div className={styles.variants_container_item}>
                        <VariantButton
                            variantType={VariantType.Size}
                            isMobileHeader={isMobileHeader}
                            isStickyHeader={isStickyHeader}
                        />
                    </div>
                )}
                {variantSelection?.showSizeSwitcher && variantSelection.isScrew && (
                    <div className={styles.variants_container_item}>
                        <VariantButton
                            variantType={VariantType.Special}
                            isMobileHeader={isMobileHeader}
                            isStickyHeader={isStickyHeader}
                        />
                        <span className={variantListClassNames} />
                    </div>
                )}
                {modelButton()}
            </div>
        );
    })
);

export default VariantButtonList;
