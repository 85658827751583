import React from 'react';

/* eslint-disable */
const Cross = () => (
    <svg
        role='img'
        xmlns='http://www.w3.org/2000/svg'
        width='14.20654'
        height='14.20654'
        viewBox='0 0 14.20654 14.20654'
    >
        <g data-name='Ebene 2'>
            <g data-name='Header &amp; Footer'>
                <path data-name='Entfernen Step 3'
                    // eslint-disable-next-line max-len
                    d='M8.37573,7.10327l5.56714-5.56714A.89977.89977,0,0,0,12.67041.26367L7.10327,5.83081,1.53613.26367A.89977.89977,0,0,0,.26367,1.53613L5.83081,7.10327.26367,12.67041a.89977.89977,0,1,0,1.27246,1.27246L7.10327,8.37573l5.56714,5.56714a.89977.89977,0,0,0,1.27246-1.27246Z'
                />
            </g>
        </g>
    </svg>
);

export default Cross;
