import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { throttle } from '../../../helper/html-helper';
import styles from './basketPanel.scss';
import { IInjectedProps } from '../../../types';
import BasketIcon from '../../icons/basketIcon';
import BasketSuccessIcon from '../../icons/basketSuccessIcon';
import MinusIcon from '../../icons/minusIcon';
import PlusIcon from '../../icons/plusIcon';

const BasketPanel = inject('store')(
    observer(({ store, isStickyHeader }: IInjectedProps) => {
        const articleCountMin = store?.orderModuleStore.articleCountMin ?? 1;
        const articleCountMax = store?.orderModuleStore.articleCountMax ?? 9999;
        const articleCount = store?.orderModuleStore.articleCount || articleCountMin;
        const [basketSuccess, setBasketSuccess] = React.useState(false);

        React.useEffect(() => {
            if (basketSuccess) {
                setTimeout(() => setBasketSuccess(false), 3000);
            }
        }, [basketSuccess]);

        if (store && !store.dataStore.contextData.featureToggles.enableFullBasketLayout) {
            React.useEffect(store.orderModuleStore.updateBasketItemCount,
                [store?.fetchedData.orderModule.articleInfo.salesArticleVariantKey]);
        }

        function handleArticleCountChange(evt) {
            if (evt.target.value) {
                const newValue = Number(evt.target.value);
                if (newValue % 1 === 0 && newValue >= articleCountMin && newValue <= articleCountMax) {
                    store?.orderModuleStore.setArticleCount(newValue);
                }
            } else {
                store?.orderModuleStore.setArticleCount('');
            }
        }

        function handleArticleCountBlur() {
            if (!articleCount && articleCount !== 0) {
                store?.orderModuleStore.setArticleCount(articleCountMin);
            }
        }

        function handleArticleCountMinus() {
            const count = Number(articleCount);
            if (count > articleCountMin) {
                store?.orderModuleStore.setArticleCount(count - 1);
            }
        }

        function handleArticleCountPlus() {
            const count = Number(articleCount);
            if (count < articleCountMax) {
                store?.orderModuleStore.setArticleCount(count + 1);
            }
        }

        function handleBasketOpenClick() {
            window.shell.publishTo('ESPP.BasketModal.Open', store?.orderModuleStore.basketModalData);
            setBasketSuccess(true);
        }

        const minusClasses = [styles.count_buttons, styles.minus_button].join(' ');
        const plusClasses = [styles.count_buttons, styles.plus_button].join(' ');
        const basketButtonClasses = classNames(styles.basket_button, {
            [styles.basket_button_success]: basketSuccess},
            {[styles.sold_out_layout]: store?.isMobileShop && store?.articleSoldOut});

        const baseBasketPanelClasses = isStickyHeader ? styles.basket_panel_sticky : styles.basket_panel;
        const basketPanelClasses = classNames(baseBasketPanelClasses, {[styles.article_sold_out] : store?.articleSoldOut});

        const throttledHandleBasketOpenClick = React.useMemo(
            () => throttle(handleBasketOpenClick)
            , []);

        return (
            <div>
                <div className={basketPanelClasses} data-testid='basket_panel'>
                    <input type='hidden' name='CategoryNavKey'
                           value={store?.dataStore.breadcrumb.category.navigationKey}
                           autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' />
                    <input type='hidden' name='MasterArticleNo'
                           value={store?.fetchedData.orderModule.articleInfo.masterArticleNo}
                           autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' />
                    <input type='hidden' name='SalesArticleVariantKey'
                           value={store?.fetchedData.orderModule.articleInfo.salesArticleVariantKey}
                           autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false' />

                    {!(store?.articleSoldOut && store?.isMobileShop) &&
                        <span className={styles.basket_counter} data-testid={'basket_counter'}>
                            <button className={minusClasses} onClick={handleArticleCountMinus}
                                title={store?.localization.generalL10n.less}>
                                <MinusIcon />
                            </button>
                            <span className={styles.basket_counter_input_container}>
                                <span className={styles.basket_counter_input}>
                                    <div>
                                        <label htmlFor={'pdp_articleUnit_input'}
                                            data-testid='label_for_articleUnit'
                                            style={{ display: 'none' }}>
                                            {store?.orderModuleStore.articleUnit}
                                        </label>
                                        <input
                                            title={store?.orderModuleStore.articleUnit}
                                            data-testid={'pdp_articleUnit_input'}
                                            value={articleCount}
                                            type='number'
                                            size={5}
                                            min={articleCountMin}
                                            max={articleCountMax}
                                            onChange={handleArticleCountChange}
                                            onBlur={handleArticleCountBlur}
                                        />
                                    </div>
                                    <span data-testid='articleUnit'>{store?.orderModuleStore.articleUnit}</span>
                                </span>
                            </span>
                            <button className={plusClasses} onClick={handleArticleCountPlus}
                                title={store?.localization.generalL10n.more}>
                                <PlusIcon />
                            </button>
                        </span>}
                    <button className={basketButtonClasses} data-testid='basket_button'
                        onClick={throttledHandleBasketOpenClick}>
                        <>
                            {!basketSuccess && <BasketIcon />}
                            {basketSuccess && <BasketSuccessIcon />}
                            {isStickyHeader && (
                                <p className={styles.basket_button_articleCount_text}>{articleCount}</p>
                            )}
                            <span className={styles.basket_button_text}>
                                {store?.articleSoldOut ? store?.localization.generalL10n.articleSoldOut 
                                : store?.localization.generalL10n.basketButtonLocale}
                            </span>
                        </>
                    </button>
                </div>
            </div>
        );
    }),
);

export default BasketPanel;
