import React from 'react';
import styles from './mediaoverlay.scss';
import { inject, observer } from 'mobx-react';
import { IMixedMediaStore } from '../../types';
import { MainImage } from '../mixedMedia/element_1/mainImage';
import CrossRing from '../icons/crossRing';

export const MediaOverlay = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        function closeMediaOverlay(): void {
            mixedMediaStore?.updateMediaOverlayVisibility(false);
            mixedMediaStore?.mainStore.scrollLock.unlock();
            mixedMediaStore?.mainStore.showEsAppButton();
        }

        if (mixedMediaStore?.displayMediaOverlay)
            return (
                <div className={styles.mediaoverlay} data-testid='pdp-mediaoverlay'>
                    <div className={styles.cross_icon} onClick={closeMediaOverlay} data-testid='pdp-mo-close'>
                        <CrossRing />
                    </div>
                    <MainImage />
                </div>)
        else
            return null;
    })
);
