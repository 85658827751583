import React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import styles from './compareShareBookmark.scss';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import CompareBtn from './compareBtn';
import ShareIcon from '../../icons/shareIcon';
import LogoService from '../../icons/logoservice';

function CompareShareBookmarkButton(props: ICompareShareBookmarkButton) {
    const { icon, label, onClick } = props;
    return (
        <button onClick={onClick}>
            {icon}
            <span>{label}</span>
        </button>
    );
}

interface ICompareShareBookmarkButton {
    icon: React.ReactElement;
    label: string;
    onClick(): void;
}

export function CompareShareBookmarkButtonContainer(props: { children: JSX.Element;['data-testid']?: string }) {
    const [childHasButton, setChildHasButton] = React.useState(false);
    const containerRef = React.useRef<HTMLElement>();

    React.useEffect(() => {
        // search for fragment button and set flag
        if (containerRef.current) {
            let ref = containerRef.current;
            let searchingForButton = true;
            while (searchingForButton) {
                if (ref.firstElementChild) {
                    if (ref.firstElementChild?.tagName === 'BUTTON') {
                        searchingForButton = false;
                        setChildHasButton(true);
                    } else {
                        ref = ref.firstElementChild as HTMLElement;
                    }
                } else {
                    searchingForButton = false;
                    setChildHasButton(false);
                }
            }
        }
    }, []);

    return (
        <div
            data-testid={props['data-testid']}
            style={{ display: childHasButton ? 'flex' : 'none' }}
            ref={containerRef as React.LegacyRef<HTMLDivElement>}
            className={styles.compareShareBookmark_button_container}
        >
            {props.children}
        </div>
    );
}

const CompareShareBookmark = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const seoSlug = store?.dataStore.breadcrumb.category.seoSlug ?? '';
        const savKey = store?.dataStore.orderModule.articleInfo.salesArticleVariantKey ?? '';
        const mpcItemOrigin = store?.orderModuleStore.mpcItemOrigin ?? '';

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const generalL10n = store!.localization.generalL10n;
        function handleShareClick() {
            if (navigator.share) {
                navigator.share({
                    title: generalL10n.shareLinkText.replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? ''),
                    text: generalL10n.shareLinkBody
                        .replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? '')
                        .replace('{1}', generalL10n.companyNameSimple),
                    url: window.location.href.split('?')[0],
                });
            } else {
                window.location.href =
                    'mailto:?subject=' +
                    encodeURIComponent(generalL10n.shareLinkText.replace(
                        '{0}', store?.dataStore.orderModule.articleInfo.title ?? '')) +
                    '&body=' +
                    encodeURIComponent(
                        generalL10n.shareLinkBody
                            .replace('{0}', store?.dataStore.orderModule.articleInfo.title ?? '')
                            .replace('{1}', generalL10n.companyNameSimple)
                    ) +
                    ' ' +
                    window.location.href.split('?')[0];
            }
        }

        return (
            <div data-testid='compareShareBookmark-desktop-container' className={styles.compareShareBookmark_container}>
                {store?.dataStore?.contextData?.portalMandantToken != 'GLB' && (
                    <CompareShareBookmarkButtonContainer data-testid='bookmark-button'>
                        <FragmentPlaceholder
                            src={`/ESPP1-Bookmark/bookmark-add-button?containerId=${store?.getBookmarkContainerID}`}
                            ignoreErrors={true}
                            timeout={2}
                        />
                    </CompareShareBookmarkButtonContainer>
                )}
                {!store?.showKioskLayout && (
                    <CompareShareBookmarkButtonContainer data-testid='share-button'>
                        <CompareShareBookmarkButton
                            icon={<ShareIcon />}
                            label={generalL10n.shareButtonLocale}
                            onClick={handleShareClick}
                        />
                    </CompareShareBookmarkButtonContainer>
                )}
                <CompareShareBookmarkButtonContainer data-testid='compare-button'>
                    <CompareBtn
                        savKey={savKey}
                        seoSlug={seoSlug}
                        itemOrigin={mpcItemOrigin}
                        data-testid='mpc-button'
                    />
                </CompareShareBookmarkButtonContainer>

                {/* smake variant is maintained and ft is on */}
                {store?.dataStore.contextData.featureToggles.enableLogoCreator
                    && store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable
                    && !store.showKioskLayout && (
                        <CompareShareBookmarkButtonContainer data-testid='logo-creator-button'>
                            <CompareShareBookmarkButton
                                icon={<LogoService />}
                                label={generalL10n.personalise}
                                onClick={() => { store.showPersonalizeOverlay(true); }}
                            />
                        </CompareShareBookmarkButtonContainer>
                    )}
                {/* smake variant is not maintained but ft is on */}
                {store?.dataStore.contextData.featureToggles.enableLogoCreator
                    && store.dataStore.bannerModule.logoServiceRedirectUrl
                    && !store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable &&
                    !store.showKioskLayout && (
                        <CompareShareBookmarkButtonContainer data-testid='no-logo-creator-button'>
                            <CompareShareBookmarkButton
                                icon={<LogoService />}
                                label={generalL10n.personalise}
                                onClick={() => {
                                    store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl
                                        && window.open(
                                        store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl,
                                            '_self');
                                }}
                            />
                        </CompareShareBookmarkButtonContainer>
                    )}
            </div>
        );
    })
);

export default CompareShareBookmark;
