export class AASFragmentHelper {
    public updateAAS(savKeyToReplace: string, savKeyToLoad: string, isMobile: boolean): void {

        window.shell.publishTo('AAS.update', 
            {
                detail:
                {
                    savKeyToReplace: savKeyToReplace,
                    savKeyToLoad: savKeyToLoad,
                    content: 'full',
                    target: isMobile ? 'modal' : null
                }
            });
    }

    public showAASModal(): void {
        window.shell.publishTo('AAS.view.shown', {detail: {useStickyHeader: true}});
        window.shell.publishTo('AAS.view.changed', {detail: {cssModification: {
                salesdesignationHeaderViewSticky: 'transition: all 0.3s ease-in-out; top:47px;'}
        }});
    }
}
