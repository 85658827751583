/* eslint-disable max-len */
import React from 'react';

const Icon_3D = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 31.80371 31.80469'>
        <path d='M30.90332,31.80078h-9a.90039.90039,0,1,1,0-1.80078h8.09961V21.90039a.90039.90039,0,0,1,1.80078,0v9A.90065.90065,0,0,1,30.90332,31.80078Z' />
        <path d='M21,30.9043a.90316.90316,0,0,1,.90039-.90039h0a.89692.89692,0,0,1,.90039.90039h0a.90316.90316,0,0,1-.90039.90039h0A.90956.90956,0,0,1,21,30.9043Z' />
        <path d='M9.90332,31.80078h-9a.90065.90065,0,0,1-.90039-.90039v-9a.90039.90039,0,0,1,1.80078,0V30H9.90332a.90039.90039,0,0,1,0,1.80078Z' />
        <path d='M0,21.9043a.90527.90527,0,0,1,.90039-.91016h0a.90527.90527,0,0,1,.90039.91016h0a.894.894,0,0,1-.90039.88965h0A.894.894,0,0,1,0,21.9043Z' />
        <path d='M.90332,10.80078a.90065.90065,0,0,1-.90039-.90039v-9A.90065.90065,0,0,1,.90332,0h9a.90039.90039,0,0,1,0,1.80078H1.80371V9.90039A.90065.90065,0,0,1,.90332,10.80078Z' />
        <path d='M9,.89453A.90116.90116,0,0,1,9.90039.00391h0a.89486.89486,0,0,1,.90039.89062h0a.8961.8961,0,0,1-.90039.89942h0A.90232.90232,0,0,1,9,.89453Z' />
        <path d='M30.90332,10.80078a.90065.90065,0,0,1-.90039-.90039V1.80078H21.90332a.90039.90039,0,1,1,0-1.80078h9a.90065.90065,0,0,1,.90039.90039v9A.90065.90065,0,0,1,30.90332,10.80078Z' />
        <path d='M30,9.9043a.90316.90316,0,0,1,.90039-.90039h0a.89692.89692,0,0,1,.90039.90039h0a.894.894,0,0,1-.90039.88965h0A.90033.90033,0,0,1,30,9.9043Z' />
        <path d='M10.12988,15.1416h.93653a.87687.87687,0,0,0,.7832-.37207,1.76682,1.76682,0,0,0,.249-.99512,1.47926,1.47926,0,0,0-.25683-.92675.82224.82224,0,0,0-.68653-.33106.81.81,0,0,0-.65918.31445,1.209,1.209,0,0,0-.25683.793H8.30469a2.72259,2.72259,0,0,1,.35937-1.39746A2.51444,2.51444,0,0,1,9.668,11.25879a2.97026,2.97026,0,0,1,1.43262-.34863,2.95578,2.95578,0,0,1,2.15332.75879,2.76584,2.76584,0,0,1,.77929,2.07812,2.372,2.372,0,0,1-.35156,1.25488,2.404,2.404,0,0,1-.92676.88477,2.12685,2.12685,0,0,1,1.04981.90234,2.81586,2.81586,0,0,1,.34472,1.42188,2.78981,2.78981,0,0,1-.84472,2.126,3.09111,3.09111,0,0,1-2.21778.79981,2.72016,2.72016,0,0,1-2.87109-2.86426h1.93457a1.421,1.421,0,0,0,.28027.89551.86306.86306,0,0,0,.71094.3623.95246.95246,0,0,0,.79-.36914,1.54951,1.54951,0,0,0,.29-.98437q0-1.46924-1.1416-1.47656h-.9502Z' />
        <path d='M16.33984,21V11.04688h2.63184A3.625,3.625,0,0,1,21.751,12.1543a4.34331,4.34331,0,0,1,1.05566,3.03515v1.61328a4.35519,4.35519,0,0,1-1.03613,3.08008A3.70008,3.70008,0,0,1,18.917,21Zm2.00977-8.27832V19.332h.60156a1.68786,1.68786,0,0,0,1.415-.5293,3.1541,3.1541,0,0,0,.43067-1.8291V15.24414a3.53766,3.53766,0,0,0-.38965-1.94531,1.57565,1.57565,0,0,0-1.32618-.57715Z' />
    </svg>
);

export default Icon_3D;
