import { IImage, ImageTypesEnum, LayoutTypesEnum } from '../stores/types';

export class UrlHelper {
    private sizes = '768px, 1200px, 1800px';

    public getSrcSet(imgUrl, layout: LayoutTypesEnum, imageType: ImageTypesEnum): IImage {
        return {
            fullPath: this.getImageUrl(imgUrl, layout, imageType),
            path: this.getImageUrl(imgUrl, layout, imageType),
            srcSet: {
                sizes: this.sizes,
                urls: this.getUrls(imgUrl, layout, imageType)
            },
        }
    }

    private getImageUrl(imgUrl: string, layout: LayoutTypesEnum, imageType: ImageTypesEnum): string {
        return this.replaceScaleName(imgUrl, 'Original', this.formScaleName(layout, imageType, 'Desktop'));
    }

    private getUrls(originalUrl: string, layout: LayoutTypesEnum, imageType: ImageTypesEnum) {
        return `${this.replaceScaleName(originalUrl, 'Original', this.formScaleName(layout, imageType, 'Mobile'))} 768w,
                ${this.replaceScaleName(originalUrl, 'Original', this.formScaleName(layout, imageType, 'Tablet'))} 1200w, 
                ${this.replaceScaleName(originalUrl, 'Original', this.formScaleName(layout, imageType, 'Desktop'))} 1800w`;
    }

    private replaceScaleName(src: string, target: string, value: string) {
        return src.replace(target, value);
    }

    private formScaleName(layout: LayoutTypesEnum, imageType: ImageTypesEnum, screen: string): string {
        let layoutName = '';
        let imageTypeName = '';
        if (screen === 'Mobile') {
            return 'MainImage_Mobile'
        }
        switch (layout) {
            case LayoutTypesEnum.Single_Element:
                {
                    switch (imageType) {
                        case ImageTypesEnum.MainImage:
                        case ImageTypesEnum.ShoeSoleImage:
                        case ImageTypesEnum.SecondaryImage:
                            imageTypeName = 'MainImage';
                            break;
                        case ImageTypesEnum.MainActionImage:
                        case ImageTypesEnum.DetailedImage:
                        case ImageTypesEnum.AdditionalImages:
                            imageTypeName = 'MainImage_Crop';
                            break;
                    }
                    layoutName = 'One';
                    break;
                }

            case LayoutTypesEnum.Two_Elements:
                {
                    switch (imageType) {
                        case ImageTypesEnum.MainImage:
                        case ImageTypesEnum.ShoeSoleImage:
                        case ImageTypesEnum.SecondaryImage:
                            imageTypeName = 'MainImage';
                            break;
                        case ImageTypesEnum.MainActionImage:
                        case ImageTypesEnum.DetailedImage:
                        case ImageTypesEnum.AdditionalImages:
                            imageTypeName = 'MainImage_Crop';
                            break;
                    }
                    layoutName = 'Two';
                    break;
                }

            case LayoutTypesEnum.Three_Elements:
                {
                    switch (imageType) {
                        case ImageTypesEnum.MainImage:
                        case ImageTypesEnum.SecondaryImage:
                        case ImageTypesEnum.ShoeSoleImage:
                            imageTypeName = 'MainImage';
                            break;
                        case ImageTypesEnum.MainActionImage:
                        case ImageTypesEnum.AdditionalImages:
                        case ImageTypesEnum.DetailedImage:
                            imageTypeName = 'MainImage_Crop';
                            break;
                    }
                    layoutName = 'Three';
                    break;
                }

            default:
                break;
        }
        return `${layoutName}_${imageTypeName}_${screen}`
    }
}
