/* eslint-disable react/no-unknown-property */
import React from 'react';
import { ICarouselSlide } from '../../../../../types';

function CarouselSlide(props: ICarouselSlide) {
    return (<img className={props.className} alt={props.alt} src={props.src} srcSet={props.srcSet} key={props.slideIndex}
        fetchpriority={props.slideIndex === 0 ? 'high': undefined}>
    </img>)
}

export default CarouselSlide;
