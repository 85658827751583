import React from 'react';
import DataStore from '../../../stores/DataStore';
import styles from './salesTeaser.scss';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import StopWatch from '../../icons/stopwatch';

export const SalesTeaser = inject('dataStore')(
    observer(({ dataStore }: IInjectedProps) => {
        const isRemainingStock = dataStore?.orderModule.articleInfo.isRemainingStock;
        const hasRecommendedRetailPrice = dataStore?.orderModule.articlePrice.hasRecommendedRetailPrice;
        const hasPriceDeduction = !!dataStore?.orderModule.articlePrice.priceDeduction;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = dataStore!.mainStore.localization;

        if (hasPriceDeduction && isRemainingStock) {
            return (
                <div>
                    <div data-testid='sales-teaser' className={classNames(styles.teaser, styles.sales_teaser)}>
                        <StopWatch />
                        {localization.generalL10n.whileStocksLast}
                    </div>
                </div>
            );
        } else if (hasPriceDeduction && hasRecommendedRetailPrice) {
            return (
                <div>
                    <div data-testid='rrp-teaser' className={classNames(styles.teaser, styles.rrp_teaser)}>
                        <div className={styles.teaser_text}>
                            <span>{localization.generalL10n.rrp}: </span>
                            {localization.generalL10n.priceAdvantage}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    })
);

export interface IInjectedProps {
    dataStore?: DataStore;
}
