import React from 'react';

/* eslint-disable */
const FunctionalLayerWarmth = () => (
    <svg id="Wärme_Icon" data-name="Wärme Icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <path fill="#1d1d1b" 
            id="Pfad_14" data-name="Pfad 14" d="M447.907,324.278a13,13,0,1,1,13-13A13.016,13.016,0,0,1,447.907,324.278Zm0-24.347a11.346,11.346,0,1,0,11.342,11.346A11.356,11.356,0,0,0,447.907,299.931Z" transform="translate(-434.907 -298.278)" />
        <path fill="#f49b00"
            id="Pfad_15" data-name="Pfad 15" d="M447.259,319.31h-.026a8.71,8.71,0,1,1,.025-17.42h.032a8.71,8.71,0,1,1-.031,17.42Zm0-15.653a6.943,6.943,0,1,0-.022,13.887h.024a6.969,6.969,0,0,0,6.976-6.92,6.959,6.959,0,0,0-6.951-6.967Z" transform="translate(-434.233 -297.603)" />
        <path id="Pfad_16" data-name="Pfad 16" d="M446.6,314.455h-.015a4.464,4.464,0,0,1,.014-8.929h.018a4.464,4.464,0,0,1-.017,8.929Zm0-7.266a2.8,2.8,0,0,0-.011,5.6h0a2.817,2.817,0,0,0,1.986-.813,2.8,2.8,0,0,0-1.964-4.79Z" transform="translate(-433.583 -296.991)" />
    </svg>
);

export default FunctionalLayerWarmth;
