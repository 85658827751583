import React from 'react';

/* eslint-disable */
const CrossRing = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
        <path className="pdp-ring" d="M27.5,1A26.5,26.5,0,1,1,1,27.5,26.53,26.53,0,0,1,27.5,1m0-1A27.5,27.5,0,1,0,55,27.5,27.50005,27.50005,0,0,0,27.5,0Z" />
        <path className="pdp-r_cross" d="M28.77344,27.5004l8.36328-8.36328a.90046.90046,0,0,0-1.27344-1.27344L27.5,26.227l-8.36328-8.36328a.90046.90046,0,0,0-1.27344,1.27344l8.36328,8.36328-8.36328,8.36328a.90046.90046,0,0,0,1.27344,1.27344L27.5,28.77383l8.36328,8.36329a.90046.90046,0,0,0,1.27344-1.27344Z" />
    </svg>
);

export default CrossRing;
