import React from 'react';
import { observer, inject } from 'mobx-react';
import { IVariantStore } from '../../../../types/index';
import classNames from 'classnames';
import styles from './sizeDimension.scss';
import SizeRegularMan from '../../../icons/size_regular_man';
import SizeLongMan from '../../../icons/size_long_man';
import SizeShortMan from '../../../icons/size_short_man';
import { ISizeType } from '../../../../stores/types';

export const SizeDimension = inject('variantStore')(
    observer(({ variantStore, sizeType }: IVariantStore & ISizeDimensionProps) => {
        const handleSizeDimensionClick = (event: React.MouseEvent<HTMLDivElement>) => {
            if (event.currentTarget.dataset.dimension) {
                // We need this in order to have kiosk selected size dimension update in variant panel
                if (variantStore?.mainStore.showKioskLayout)
                    variantStore?.mainStore.workWearStoreStore.changeSelectedSizeType(event.currentTarget.dataset.dimension);
                variantStore?.changeSelectedSizeType(event.currentTarget.dataset.dimension);
            }
        };

        const isSizeDimensionInactive = sizeType.sizes.every(size => {
            return size.salesArticleVariantKey == '';
        });

        return (
            <div role='size_dimension' className={classNames(styles.size_dimension, {
                [styles.active]:
                    variantStore?.selectedSizeType?.sizeTypeName === sizeType.sizeTypeName && !isSizeDimensionInactive,
                [styles.size_dimension_inactive]: isSizeDimensionInactive,
            })}
                data-dimension={sizeType.sizeTypeName}
                onClick={handleSizeDimensionClick}>
                {
                    sizeType.sizeTypeCode == 1 || sizeType.sizeTypeCode == 130 ? (
                        <SizeRegularMan />
                    ) : null
                }
                {
                    sizeType.sizeTypeCode == 2 || sizeType.sizeTypeCode == 129 ? (
                        <SizeLongMan />
                    ) : null
                }
                {
                    sizeType.sizeTypeCode == 3 || sizeType.sizeTypeCode == 128 ? (
                        <SizeShortMan />
                    ) : null
                }
                <div className={styles.selected_size_dimension_label}>{sizeType.sizeTypeName}</div>
            </div>
        );
    })
);

export interface ISizeDimensionProps {
    sizeType: ISizeType;
}
