import React from 'react';
import styles from './personalizeInfoOverlay.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../types';
import CrossRing from '../icons/crossRing';
import classNames from 'classnames';

export const PersonalizeInfoOverlay = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const localization = store?.localization;

        function closeOverlay(): void {
            store?.showPersonlizeRegistrationConfirmHint(false); 
        }

        return <div className={classNames(styles.po_info_container,
            { [styles.show_po]: store?.confirmRegistrationHintIsVisible })}>
            <div className={styles.personalize_overlay}>
                <div className={styles.po_header}>
                    <div className={styles.h_title}>{localization?.generalL10n.note}</div>
                    <div className={styles.h_cross_icon} onClick={closeOverlay} data-testid='pdp-mo-close'>
                        <CrossRing />
                    </div>
                </div>
                <div className={styles.po_body}>
                    {localization?.generalL10n.personalizationRegistrationConfirmHint}
                </div>
            </div>
        </div>
    })
);
