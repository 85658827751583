import React from 'react';

/* eslint-disable */
const Diameter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.80078" height="24.80078" viewBox="0 0 24.80078 24.80078">
        <path d="M12.40039,0A12.40039,12.40039,0,1,0,24.80078,12.40039,12.41532,12.41532,0,0,0,12.40039,0Zm0,23A10.59961,10.59961,0,1,1,23,12.40039,10.61151,10.61151,0,0,1,12.40039,23Z" /><path d="M17.6123,6.53027,13.835,6.30859a.72153.72153,0,0,0-.74023.65821.70157.70157,0,0,0,.6582.74023l2.2207.12988-8.137,8.137-.13062-2.221a.74316.74316,0,0,0-.74023-.6582.70156.70156,0,0,0-.6582.74023l.22265,3.77734a.70159.70159,0,0,0,.65821.65821l3.77734.22265.042.001a.7003.7003,0,0,0,.04-1.39941l-2.221-.13062L16.96411,8.8269l.13062,2.22193a.70107.70107,0,0,0,.69824.65918l.042-.001a.70157.70157,0,0,0,.6582-.74023l-.22265-3.77832A.70159.70159,0,0,0,17.6123,6.53027Z" />
    </svg>
);

export default Diameter;
