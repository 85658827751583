import React from 'react';
import classNames from 'classnames';
import * as helper from '../../../helper/html-helper';
import styles from './contentTab.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';

export default class ContentTab extends React.Component<{
    logoSrc?: string, title?: string, pfName: string | undefined,
    description?: string, cdnAssetsUrl: string,
    redirectUrl: string, redirectBtnTitle?: string, altTextTitle?: string
}, { logoSrc?: string, description?: string, title?: string }> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.logoSrc)
            this.setState(
                { logoSrc: helper.formCdnUrl(this.props.logoSrc, this.props.cdnAssetsUrl) }
            );

        this.setState({description: helper.decodeHTML(this.props.description),
                        title: helper.decodeRtfText(this.props.title)})
    }

    public render() {
        return (
            <div className={classNames(styles.sc_container, 
                `pdp-${this.props.pfName}_background`)}
                data-testid={`${this.props.pfName}_shutter`}>
                <div className={styles.logoContainer}>
                    <img className={styles.logo}
                        src={this.state.logoSrc}
                        alt={helper.decodeHTML(this.props.altTextTitle)}
                    />
                </div>
                <span className={styles.description}>
                    <div 
                        className={styles.tf_headline} 
                        dangerouslySetInnerHTML={{__html: this.state.title ?? ''}} />                    
                    <p>
                        {this.state.description}
                    </p>
                </span>
                <a href={this.props.redirectUrl}>
                    <div className={styles.redirect_button}>
                        <span className={styles.rb_title}>{this.props.redirectBtnTitle}</span>
                        <span className={styles.rb_arrow_icon}>
                            <IconArrowExtraSmallRight/>
                        </span>
                    </div>
                </a>
            </div>
        );
    }
}
