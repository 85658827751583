import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { IInjectedProps } from '../../types';
import ShutterModul from '../shutterModul/shutterModul';
import MixMatch from '../icons/mixMatch';
import MixAndMatchFragmentWrapper from './mixAndMatchFragmentWrapper';

//this component exists because MnM fragment sends us an event indicates MnM is filled/empty
//here we hide/show the shutter accordingly

@inject('store')
class MixAndMatchShutterWrapper extends Component<
    IInjectedProps,
    { hideContent: boolean; }
> {
    private mmRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.state = {
            hideContent: true //render server side, hide initially client side
        };
    }

    render() {
        return (
            <div className={this.state.hideContent ? 'hidden' : ''} ref={this.mmRef}>
                <ShutterModul
                    title={this.props.store?.localization.generalL10n.mixAndMatch}
                    isOpen={true}
                    icon={<MixMatch />}
                    isMobileShop={this.props.store?.isMobileShop}
                >
                    <MixAndMatchFragmentWrapper />
                </ShutterModul>
            </div>
        );
    }

    componentDidMount() {
        if (window && window.shell) {
            window.shell.subscribeTo(
                'ESPP2.MixAndMatch.OnLoaded',
                payload => {
                    // when this fragment loads client-side
                    // is emits this event that tells us if it contains articles
                    const hideContent = !payload || !payload.available;
                    this.setState({ hideContent });
                },
                'ESPP1.Detailspage.MixAndMatchShutter'
            );
        }
    }

    componentWillUnmount() {
        if (window && window.shell) {
            window.shell.unsubscribeFrom('ESPP2.MixAndMatch.OnLoaded', 'ESPP1.Detailspage.MixAndMatchShutter');
        }
    }
}

export default MixAndMatchShutterWrapper;
