import React from 'react';

/* eslint-disable */
function ShoesBannerHeader() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30.48407 17.78918">
            <path d="M27.58557,9.93079A5.615,5.615,0,0,0,25.54163,9.631a6.73935,6.73935,0,0,1-2.74805-.51758,37.62939,37.62939,0,0,1-6.6875-4.03711L16.0946,5.07l-.00317-.00342a5.442,5.442,0,0,1-.76855-.75488A.89988.89988,0,1,0,13.942,5.46594a8.75317,8.75317,0,0,0,.66113.694,2.56314,2.56314,0,0,1-1.85156,1.85974,3.60438,3.60438,0,0,1-.95215.1416.90006.90006,0,0,0-.87207.92676.91332.91332,0,0,0,.92676.87207,5.37364,5.37364,0,0,0,1.43164-.22168A4.33066,4.33066,0,0,0,16.108,7.295c.45935.32227,1.04236.71827,1.677,1.12989l-.98364,1.7832a.60013.60013,0,1,0,1.05078.58008l.94995-1.72193c.48218.29444.9751.57862,1.46509.84278l-.55078,1.07055a.59845.59845,0,0,0,.25879.80762.58932.58932,0,0,0,.27344.06738.60024.60024,0,0,0,.53418-.32617l.55468-1.07788c.28516.13184.56373.252.82813.35034a8.50769,8.50769,0,0,0,3.37207.63086,3.88175,3.88175,0,0,1,1.459.2002c1.252.43359,1.69239,1.86914,1.68653,3.06738a.95529.95529,0,0,1-.71.96289,21.67133,21.67133,0,0,1-4.18848.32617c-3.22559,0-8.18848-.01367-12.27832-.02441l-4.19434-.01172a.78567.78567,0,0,1-.583-.27246,1.10423,1.10423,0,0,1-.27539-.75195c.00195-.5752.03125-1.21289.07519-1.86328a.89841.89841,0,0,0-.83594-.959.872.872,0,0,0-.959.83594c-.04785.6914-.0791,1.36914-.08106,1.98047a2.901,2.901,0,0,0,.74512,1.96972,2.57327,2.57327,0,0,0,1.90918.86133l4.19434.01074c4.09082.01172,9.05664.02539,12.2832.02539a23.62805,23.62805,0,0,0,4.5459-.36328A2.75766,2.75766,0,0,0,30.484,14.70715C30.49182,12.88684,29.735,10.6759,27.58557,9.93079Z" /><path d="M10,5a5,5,0,1,0-5,5A5.00009,5.00009,0,0,0,10,5ZM1.2,5A3.80005,3.80005,0,1,1,5,8.80005,3.80437,3.80437,0,0,1,1.2,5Z" /><path d="M5.01917,3.63293a.59961.59961,0,1,0,0-1.19921H5.0094a.59559.59559,0,0,0-.59473.59961A.60392.60392,0,0,0,5.01917,3.63293Z" /><path d="M5.59924,6.96692v-2a.59961.59961,0,0,0-1.19922,0v2a.59961.59961,0,0,0,1.19922,0Z" />
        </svg>
    );
}

export default ShoesBannerHeader;