import React from 'react';

/* eslint-disable */
const FunctionalLayerBase = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"> 
        <g id="Basis_Icon" data-name="Basis Icon" transform="translate(-409.605 -286.842)">
            <path fill="#1d1d1b"
                id="Pfad_11" data-name="Pfad 11" d="M422.6,312.842a13,13,0,1,1,13-13A13.014,13.014,0,0,1,422.6,312.842Zm0-24.346a11.346,11.346,0,1,0,11.341,11.347A11.357,11.357,0,0,0,422.6,288.5Z" />
            <path fill="#1d1d1b"
                id="Pfad_12" data-name="Pfad 12" d="M421.967,307.892h-.028a8.719,8.719,0,1,1,.027-17.438h.027a8.719,8.719,0,1,1-.026,17.438Zm0-15.67a6.973,6.973,0,0,0-6.982,6.929,6.952,6.952,0,0,0,6.957,6.972h.026a6.974,6.974,0,0,0,6.98-6.929,6.966,6.966,0,0,0-6.959-6.972Z" transform="translate(0.664 0.666)" />
            <path fill="#c7d300"
                id="Pfad_13" data-name="Pfad 13" d="M421.286,303h-.015a4.454,4.454,0,0,1-3.123-7.614,4.537,4.537,0,0,1,3.154-1.295,4.454,4.454,0,0,1-.015,8.909Zm0-7.249a2.8,2.8,0,0,0-.011,5.591h.012a2.8,2.8,0,0,0,.011-5.591Z" transform="translate(1.344 1.295)" />
        </g>
    </svg>
);

export default FunctionalLayerBase;
