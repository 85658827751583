import React from 'react';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import styles from './additionalDescription_showcase.scss';
import { IAdditionalDescriptionShowcase } from '../../../stores/types';

function AdditionalDescriptionShowCase(props: IAdditionalDescriptionShowcase) {
    return (<div className={styles.showcase}>
        <FragmentPlaceholder
            src={
                '/ESPP1-Showcase/threesixtyview?masterArticleNo=' +
                props.masterArticleNo + '&colorCode=' + props.colorCode +
                '&containerId=' + props.threeDContainerId +
                '&doNotUpdateAfterVariantChange=true&noBackground=true' +
                '&noImageActions=true' +
                '&fallBackImageUrl='+ props.fallBackImageUrl +
                '&showThreeSixtyViewAfterPageLoad=true' // because add description is open always if it is available
            }
            ignoreErrors={true}
            timeout={2}
        />
    </div>);
}

export default AdditionalDescriptionShowCase;
