import React from 'react';
import styles from './personalizationBanner.scss';
import classNames from 'classnames';
import SelfDesignIcon from '../icons/selfDesign';
import { MainStore } from '../../stores/MainStore';
import * as helper from '../../helper/html-helper';

function PersonalizationBanner(props: IPersonalizationBanner) {
    
    function handleDesignYourselfNowClick() {
        props.mainStore?.onPersonalizeIntent(false);
    }
    
    return (
        <div data-testid='personalization_banner_modul' className={styles.personalization_banner_modul}>
            <div id='personalization_banner_modul_wrapper'
                data-testid='personalization_banner_modul_wrapper' className={styles.personalization_banner_modul_wrapper}>
                <div role='heading' className={classNames(styles.personalization_banner_header)}>
                    <div data-testid='personalization_banner_gif' className={styles.personalization_banner_gif} />
                    <div data-testid='personalization_banner_text' className={styles.personalization_banner_text}>
                        <div data-testid='personalization_banner_hardcoded'
                            className={styles.personalization_banner_hardcoded}>
                            <div className={styles.small_hardcoded_text}>
                        {helper.decodeHTML(props.mainStore.localization.generalL10n.personalizationBannerDescriptionText)}
                            </div>
                            <div className={styles.large_hardcoded_text}>
                            {helper.decodeHTML(props.mainStore.localization.generalL10n.personalizationBannerHeadline)}
                            </div>
                        </div>
                        <button data-testid='designYourselfNowBtn' className={styles.design_yourself_now_button}
                            onClick={handleDesignYourselfNowClick}>
                            <SelfDesignIcon />
                            <span className={styles.design_yourself_now_text}>
                            {helper.decodeHTML(props.mainStore.localization.generalL10n.personalizationBannerButtonLabel)}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IPersonalizationBanner {
    mainStore: MainStore;
}

export default PersonalizationBanner;
