import React from 'react';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import styles from './content.scss';
import { ILogo } from '../../../stores/types';
import {RichText} from '../../../helper/richText';

// Product feature content element
export default class Content extends React.Component<{
    contentKey: number, activeContentKey: number
    showCase: string, 
    pfRichTextId: string,
    title: string, masterArticleNo: number, colorCode: number,
    logo: ILogo, containerId: string, isMobileShop: boolean
    singleProductFeature: boolean
}, {}>
{
    public constructor(props) {
        super(props);
    }

    private getStyles(): string {
        let result = styles.pf_content;

        this.props.contentKey != this.props.activeContentKey ? result += ' ' + styles.hidden : '';

        this.props.singleProductFeature === true ? result += ' ' + styles.single_product_feature : ''

        return result;
    }

    public render() {
        return (
            <div className={this.getStyles()} data-testid='pf_content'>
                <div className={styles.pf_animation}>
                    <FragmentPlaceholder
                        src={
                            '/ESPP1-Showcase/productfeatureanimation?masterArticleNo=' +
                            this.props.masterArticleNo + '&colorCode=' + this.props.colorCode +
                            '&containerId=' + this.props.containerId + 
                            '&showCase=$$$LocalizationShowCase.' + this.props.showCase + '$$$'
                        }
                        ignoreErrors={true}
                        timeout={2}
                    />
                </div>
                <div className={styles.text_container}>
                    <div className={styles.emt_title} 
                        dangerouslySetInnerHTML={{ __html: this.props.title}} />
                    <div className={styles.emt_text}>
                        <RichText richTextId={this.props.pfRichTextId} />
                    </div>
                    {this.props.logo && 
                        <img className={styles.emt_logo}
                            src={this.props.logo.imageUrl} 
                            alt={this.props.logo.altText} />
                    }
                </div>
            </div>
        );
    }
}
