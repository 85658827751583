import React from 'react';
import DebugBarHelper from '../../../helper/debugBarHelper';
import { RichText } from '../../../helper/richText';
import { IProductFeatureInExtras, IRichTextItemsInternal } from '../../../stores/types';
import styles from './extras.scss';
import classNames from 'classnames';

export default class Extras extends React.Component<IExtras,
    { debuggerIsOn: boolean; extras: IProductFeatureInExtras[];
        injectedRichText?: IRichTextItemsInternal[]; }> {
    private debugBarHelper: DebugBarHelper;

    constructor(props) {
        super(props);

        this.state = {
            debuggerIsOn: this.props.debuggerIsOn,
            extras: this.props.extras,
            injectedRichText: this.props.injectedRichText,
        };

        this.debugBarHelper = DebugBarHelper.instance;

        this.turnOnDebugger = this.turnOnDebugger.bind(this);
    }

    public turnOnDebugger(value: boolean): void {
        this.setState({debuggerIsOn: value});
    }

    public updateExtras(extras: IProductFeatureInExtras[]): void {
        this.setState({ extras: extras });
    }

    public render() {
        return (
            <div>
                {this.state.extras.map(module => {
                    return (
                        <div key={module.title} className={styles.text_module}>
                            <div className={styles.title} dangerouslySetInnerHTML={{ __html: module.title }} />
                            <div className={styles.tm_container}>
                                {(module.rtfText && module.rtfText.length > 0) &&
                                    <div className={classNames(styles.text,
                                        {[this.debugBarHelper.getDebuggerClassName(module.dataMode)]:
                                            this.state.debuggerIsOn})}
                                        dangerouslySetInnerHTML={{ __html: module.rtfText }} />
                                }
                                {(module.structuredText && module.structuredText.id) &&
                                    <div className={classNames(styles.text,
                                        {[this.debugBarHelper.getDebuggerClassName(module.dataMode)]:
                                            this.state.debuggerIsOn})}>
                                        <RichText richTextId={module.structuredText.id}
                                            injectedRichTexts={this.props.injectedRichText}/>
                                    </div>
                                }
                                {module.image && (
                                    <img
                                        onLoad={() => {this.props.updateProductInfoContentHeight(true)}}
                                        src={module.image.imageUrl}
                                        className={styles.tm_image}
                                        alt={module.image.altText}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

interface IExtras {
    extras: IProductFeatureInExtras[];
    debuggerIsOn: boolean;
    updateProductInfoContentHeight(recalculateClientHeight: boolean);
    injectedRichText?: IRichTextItemsInternal[];
}
