import React, { Component } from 'react';
import styles from './debugBar.scss';

export default class DebuggerDataSource extends Component<{value: string}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span data-testid='debugger-data-source' 
                className={styles.dds_info}>{this.props.value}
            </span>
        );
    }
}
