import React from 'react';
/* eslint-disable max-len */
const SizeRegularMan = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='65' viewBox='0 0 30 65'>
        <path d='M15.02734,19.03076a5.25049,5.25049,0,1,0-5.25-5.251A5.25678,5.25678,0,0,0,15.02734,19.03076Zm0-8.70019a3.44971,3.44971,0,1,1-3.44922,3.44922A3.45339,3.45339,0,0,1,15.02734,10.33057Z' />
        <path d='M24.54,26.05713a6.90683,6.90683,0,0,0-.92188-3.68457,4.45467,4.45467,0,0,0-3.542-2.1709,9.40146,9.40146,0,0,0-2.26562.293,13.54951,13.54951,0,0,1-2.8125.373,12.77867,12.77867,0,0,1-2.82715-.37207,9.34,9.34,0,0,0-2.2461-.29395,4.45642,4.45642,0,0,0-3.543,2.17188A6.91862,6.91862,0,0,0,5.46,26.05713c-.07715,1.60742-.24707,12.00879-.25488,12.46582a2.90079,2.90079,0,0,0,3.98755,2.70184c-.10157,6.03986-.21509,12.82752-.29517,17.71515A3.5011,3.5011,0,0,0,15.002,61.27979a3.49843,3.49843,0,0,0,6.10059-2.3545c-.09082-5.60351-.18164-10.96386-.27344-16.332l-.023-1.3692a2.88753,2.88753,0,0,0,1.07666.212,2.91787,2.91787,0,0,0,2.91211-2.92774C24.78809,38.06592,24.61816,27.66455,24.54,26.05713ZM21.88281,39.63525a1.11279,1.11279,0,0,1-1.11133-1.1123c0-.02081-.01049-.03827-.01171-.05872L20.57129,27.272a.89993.89993,0,0,0-.89942-.88574l-.01464.001a.89847.89847,0,0,0-.88477.91406l.25684,15.32324c.09179,5.36719.18261,10.72656.27343,16.31543a1.69971,1.69971,0,0,1-3.39941,0V40.53564a.90039.90039,0,1,0-1.80078,0v18.4043a1.70268,1.70268,0,0,1-3.40527.01465c.17089-10.45215.49609-29.63672.52929-31.5127l.002-.13964a.89892.89892,0,0,0-.88282-.915l-.0166-.001a.90018.90018,0,0,0-.89941.88379v.001l-.001.00293c0,.01855-.001.05664-.002.1123L9.23047,38.50732l.00049.00873-.00147.0069a1.11235,1.11235,0,0,1-2.22461.01465c.002-.1084.17676-10.81446.25293-12.39258a5.24831,5.24831,0,0,1,.63867-2.79981,2.71212,2.71212,0,0,1,2.10059-1.34472,7.57486,7.57486,0,0,1,1.80078.25683,13.77137,13.77137,0,0,0,3.20606.41114,15.04744,15.04744,0,0,0,3.17578-.41211,7.40514,7.40514,0,0,1,1.82422-.25586,2.71009,2.71009,0,0,1,2.09961,1.34375,5.23659,5.23659,0,0,1,.63867,2.80078c.07715,1.57812.252,12.28418.25293,12.37793A1.11363,1.11363,0,0,1,21.88281,39.63525Z' />
    </svg>
);

export default SizeRegularMan;
