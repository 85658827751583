import React from 'react';
import classNames from 'classnames';
import styles from './gallery3dOverlay.scss';
import * as Gallery3dOverlayTypes from './gallery3dOverlay.d';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';

export default class Gallery3dOverlay extends React.Component<
    Gallery3dOverlayTypes.IGallery3dOverlayProps,
    Gallery3dOverlayTypes.IGallery3dOverlayState> {
    constructor(props) {
        super(props);
        this.state = {
            displayG3dOverlay: this.props.displayG3dOverlay
        };
    }

    public render() {
        return (
            <div className={classNames(styles.gallery_3d,
                { [styles.display_g3d_overlay]: this.props.displayG3dOverlay })}>
                <div className={styles.gallery_3d_container}>
                    <FragmentPlaceholder
                        src={
                            '/ESPP1-Showcase/threesixtyoverlayview?masterArticleNo=' +
                            this.props.masterArticleNo + '&colorCode=' + this.props.colorCode +
                            '&containerId=Gallery3dOverlay_mixedMedia'
                        }
                        ignoreErrors={true}
                        timeout={2}
                    />
                </div>
            </div>
        );
    }
}
