import React from 'react';

/* eslint-disable */
const ArrowDownDesktopShutters = () => (
    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="72.99988" height="30.99988" viewBox="0 0 72.99988 30.99988">
        <path d="M71.499,30.99988a1.49342,1.49342,0,0,1-.93653-.3291L36.5,3.42078,2.43652,30.66589a1.50012,1.50012,0,1,1-1.873-2.34375l35-27.99414a1.50064,1.50064,0,0,1,1.874.001l35,28a1.5,1.5,0,0,1-.93847,2.6709Z" />
    </svg>
);

export default ArrowDownDesktopShutters;
