import React from 'react';
/* eslint-disable max-len */
const SelfDesignIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.126"
        height="39.8"
        viewBox="0 0 47.126 39.8"
    >
        <path
            d="M12.892 38.6a4.078 4.078 0 01-4.072-4.073v-18.08l-.874.5a4.038 4.038 0 01-2.026.545 4.11 4.11 0 01-1.062-.141 4.043 4.043 0 01-2.474-1.9l-3.037-5.259A4.079 4.079 0 01.838 4.625L9.772-.537l.01-.005a4.887 4.887 0 012.453-.662h3.17a1.2 1.2 0 011.112.662 6.47 6.47 0 005.815 3.6 6.466 6.466 0 005.815-3.6 1.2 1.2 0 011.112-.662h3.166a4.855 4.855 0 012.552.721l8.917 5.151a4.078 4.078 0 011.489 5.563l-3.037 5.261a4.046 4.046 0 01-2.474 1.9 4.086 4.086 0 01-1.06.141 4.051 4.051 0 01-2.031-.549l-1.692-.973a1.2 1.2 0 01-.441-1.639 1.2 1.2 0 011.638-.441l1.694.975a1.66 1.66 0 001.272.167 1.654 1.654 0 001.015-.78l3.039-5.261a1.676 1.676 0 00-.612-2.285l-8.928-5.158-.035-.021a2.465 2.465 0 00-1.3-.372h-2.5a8.925 8.925 0 01-2.85 2.891 8.879 8.879 0 01-4.743 1.367 8.868 8.868 0 01-4.741-1.367 8.92 8.92 0 01-2.852-2.891h-2.5a2.47 2.47 0 00-1.254.342h-.008L2.049 6.7a1.676 1.676 0 00-.613 2.288l3.037 5.259a1.667 1.667 0 001.016.782 1.667 1.667 0 001.27-.169l2.674-1.534a1.2 1.2 0 01.689-.155h.029a1.2 1.2 0 011.081 1.194v20.152a1.674 1.674 0 001.671 1.671h18.882a1.2 1.2 0 011.2 1.2 1.2 1.2 0 01-1.2 1.2z"
            transform="translate(1.199 1.2)"
        ></path>
        <path
            d="M15.695 22.437l-1.386-1.385-.036-.034L1.319 8.062a1.094 1.094 0 01-.3-.492L-1.06.303A1.1 1.1 0 01-.78-.778 1.1 1.1 0 01-.002-1.1a1.045 1.045 0 01.3.043l7.25 2.072a1.086 1.086 0 01.183.067h.01l.016.008.016.008h.006a1.1 1.1 0 01.29.223l12.894 12.895a.778.778 0 01.052.048l1.419 1.421a3.9 3.9 0 010 5.517l-1.227 1.228a3.884 3.884 0 01-2.758 1.141 3.883 3.883 0 01-2.754-1.134zm1.555-1.556a1.7 1.7 0 002.406 0l1.227-1.228a1.7 1.7 0 000-2.406l-.643-.644-3.632 3.634zM3.635 7.268l11.416 11.416 3.634-3.632L7.269 3.634zm-1.013-2.1l2.547-2.545-3.566-1.02z"
            transform="translate(23.448 16.022)"
        ></path>
    </svg>
);

export default SelfDesignIcon;

