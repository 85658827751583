import React from 'react';

/* eslint-disable */
const IconNovelty = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.6"
        height="15.947"
        viewBox="0 0 16.6 15.947"
    >
        <path d="M8.395 15.947a.8.8 0 01-.711-.433l-2.207-4.282-4.779-.609a.8.8 0 01-.466-1.357l3.39-3.422-.897-4.734A.8.8 0 013.87.248l4.302 2.168L12.397.1a.8.8 0 011.176.822l-.733 4.763 3.508 3.3a.8.8 0 01-.419 1.374l-4.755.774-2.056 4.357a.8.8 0 01-.71.458zM2.509 9.24l3.588.457a.799.799 0 01.61.428l1.657 3.215 1.543-3.271a.801.801 0 01.594-.449l3.57-.58-2.633-2.48a.8.8 0 01-.243-.703l.55-3.576L8.572 4.02a.807.807 0 01-.745.013l-3.23-1.628.674 3.555a.8.8 0 01-.218.712z"></path>
    </svg>
);

export default IconNovelty;
