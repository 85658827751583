import React from 'react';
import { inject, observer } from 'mobx-react';
import StatusIcon from '../../icons/statusIcon';
import styles from './deliveryTime.scss';
import ModalWrapper from '../../modalwrapper/modalWrapper';
import { IInjectedProps } from '../../../types';
import classNames from 'classnames';

export enum AvailabilityState {
    InStock,
    SoonInStock,
}

const DeliveryTime = inject('store')(
    observer(({ store }: IInjectedProps) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = store!.localization;
        const [modalOpen, setModalOpen] = React.useState(false);

        const showHalfWidth = store?.dataStore.contextData.portal.includes('DE') && !store.showKioskLayout;


        const handleDeliveryTimeClick = React.useCallback((open) => {
            setModalOpen(open);
            open ? store?.scrollLock.lock() : store?.scrollLock.unlock();
            open ? store?.hideEsAppButton() : store?.showEsAppButton();
        }, []);

        return (
            <div
                className={classNames(
                    styles.delivery_time_container,
                    showHalfWidth ? styles.half_width : styles.full_width
                )}
            >
                <StatusIcon statusColor={
                    store?.articleSoldOut ? store?.orderModuleStore.soldOutArticleStatusColor
                    : store?.orderModuleStore.deliveryTimeStatusColor} />
                <span
                    data-testid='delivery_time'
                    className={classNames(styles.delivery_time_text, {[styles.isGlobal]: store?.isGlobal})}
                    onClick={!store?.isGlobal ? () => handleDeliveryTimeClick(true) : undefined}>
                      {store?.articleSoldOut ? store?.localization.generalL10n.articleNotAvailable
                       : store?.orderModuleStore.deliveryTimeString}
                </span>
                <ModalWrapper
                    isOpen={modalOpen}
                    title={localization.generalL10n.deliveryTime}
                    onClose={() => handleDeliveryTimeClick(false)}
                    content={
                        <div
                            className={styles.delivery_time_modal_content}
                            dangerouslySetInnerHTML={{ __html: localization.generalL10n.deliveryTimeText }}
                        />
                    }
                />
            </div>
        );
    })
);

export default DeliveryTime;
