import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import styles from './stickyHeader.scss';

const placeholderRef = {
    current: {
        clientHeight: 0,
        getBoundingClientRect() {
            return { top: 0 };
        },
    },
};

function StickyHeader({ top = 0, ...props }: {
    children: JSX.Element | JSX.Element[]; onHeightChange: (param: number) => void; isEsApp?: boolean;
    variantButtonCount?: number; top?: number;
}) {
    const { children, onHeightChange, variantButtonCount } = props;
    const [contentHeight, setContentHeight] = React.useState(0);
    const anchorRef = React.useRef<HTMLDivElement>();
    const contentRef = React.useRef<HTMLDivElement>();

    function closeStickyHeader() {
        window.removeEventListener('scroll', handleScrollOrResize);
        window.removeEventListener('resize', handleScrollOrResize);
        setContentHeight(0);
    }

    function restoreStickyHeader() {
        calcContentHeight();
        window.addEventListener('scroll', handleScrollOrResize);
        window.addEventListener('resize', handleScrollOrResize);
    }

    function calcContentHeight() {
        const outerRef = anchorRef?.current ? anchorRef : placeholderRef;
        const outerRefTop = outerRef.current?.getBoundingClientRect().top ?
            outerRef.current.getBoundingClientRect().top : 0;
        const visibility = outerRefTop <= 130;

        const innerRef = contentRef?.current ? contentRef : placeholderRef;
        const innerRefHeight = innerRef.current?.clientHeight ? innerRef.current?.clientHeight : 0;
        const newHeight = visibility ? innerRefHeight : 0;

        setContentHeight(newHeight);
        onHeightChange(newHeight);
    }

    function handleScrollOrResize() {
        calcContentHeight();
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScrollOrResize);
        window.addEventListener('resize', handleScrollOrResize);

        if (isMobileOnly) {
            window.shell.subscribeTo('ESMKT.HeadR.FlyoutClosed',
                () => {
                    restoreStickyHeader();
                }, 'ESMKT.HeadR.FlyoutClosed');

            window.shell.subscribeTo('ESMKT.HeadR.FlyoutOpening',
                () => {
                    closeStickyHeader();
                }, 'ESMKT.HeadR.FlyoutOpening');
        }

        return () => {
            window.removeEventListener('scroll', handleScrollOrResize);
            window.removeEventListener('resize', handleScrollOrResize);
            window.shell.unsubscribeFrom('ESMKT.HeadR.FlyoutClosed',
                'ESMKT.HeadR.FlyoutClosed');
            window.shell.unsubscribeFrom('ESMKT.HeadR.FlyoutOpening',
                'ESMKT.HeadR.FlyoutOpening');
        };
    }, []);

    return (
        <>
            <div ref={anchorRef as React.LegacyRef<HTMLDivElement>} />
            <div
                data-testid='sticky_header_container'
                className={classNames(styles.sticky_header_container, { [styles.no_headr]: props.isEsApp })}
                style={{ height: `${contentHeight}px`, position: 'fixed', top: `${top}px` }}
                suppressHydrationWarning
            >
                <div
                    data-testid='sticky_header_content'
                    ref={contentRef as React.LegacyRef<HTMLDivElement>}
                    className={classNames(styles.sticky_header_content, {
                        [styles.no_variant_buttons_all_in_one_line]: variantButtonCount === 0,
                    })}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default StickyHeader;
