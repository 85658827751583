import React from 'react';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import styles from './compareShareBookmark.scss';

export default class CompareBtn extends React.Component<
    { savKey: string; seoSlug: string; itemOrigin: string },
    { savKey: string; seoSlug: string; itemOrigin: string }
> {
    constructor(props) {
        super(props);
        this.state = {
            savKey: this.props.savKey,
            seoSlug: this.props.seoSlug,
            itemOrigin: this.props.itemOrigin,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.savKey !== this.props.savKey) this.updateMpc_SavKey();
    }

    updateMpc_SavKey(): void {
        const pdpContainer: HTMLElement | null = document.querySelector('.pdp-details_page_container');
        if (!pdpContainer) {
            return;
        }

        const newBtn: HTMLElement | null = pdpContainer.querySelector('.mpc-compare-btn');
        if (!newBtn) {
            return;
        }

        newBtn.setAttribute('data-savkey', this.props.savKey);
    }

    public render() {
        return (
            <div data-testid='mpc-button' className={styles.compareShareBookmark_button_container}>
                <FragmentPlaceholder
                    // eslint-disable-next-line max-len
                    src={`/ESPP1-ManualProductComparison/TouchPoint/ForDetailsPageNew?seoSlug=${this.state.seoSlug}&itemOrigin=${this.state.itemOrigin}&savKey=${this.state.savKey}`}
                    ignoreErrors={true}
                    timeout={2}
                />
            </div>
        );
    }
}
