import React from 'react';
import classNames from 'classnames';
import styles from './mainImage.scss';
import '../../../styles/customCarouselStyles.scss';
import { inject, observer } from 'mobx-react';
import { IMixedMediaStore } from '../../../types';
import { EyeCatcher } from '../eyeCatcher/eyeCatcher';
import Zoom from '../../icons/zoom';
import NormalImage from './normalImage/normalImage';
import MainImageCarousel from './mainImageCarousel/mainImageCarousel';

export const MainImage = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        function openMediaOverlay(): void {
            mixedMediaStore?.updateMediaOverlayVisibility(true);
            mixedMediaStore?.mainStore.scrollLock.lock();
            mixedMediaStore?.mainStore.hideEsAppButton();
        }

        return (
            <div className={styles.main_image_container}>
                <div
                    className={classNames(styles.zoom_icon, { [styles.zoom_only]: !mixedMediaStore?.has3DView })}
                    onClick={openMediaOverlay}
                    data-testid={'pdp-zoom-button'}
                >
                    <Zoom />
                </div>
                <EyeCatcher />
                {mixedMediaStore?.shouldRenderMainImageCarousel ? <MainImageCarousel /> : <NormalImage />}
            </div>
        );
    })
);
