import { observable, makeObservable, action, computed } from 'mobx';
import { MainStore } from './MainStore';
import {
    AvailabilityStatusEnum,
    IAvailableColor,
    IAvailableModel,
    IColor,
    IModel,
    ISalesArticleVariantSize,
    ISize,
    ISizeType,
    IVariantSelection,
    IVariantsStoreInformation,
    IWorkWearStoreAvailabilityData,
    VariantType,
    IArticleVariant,
} from './types';
import axios from 'axios';

class WorkWearStoreStore {
    mainStore: MainStore;
    availableColors: IAvailableColor[];
    availableModels: IAvailableModel[];
    availableSizeTypes: ISizeType[];
    apiEndpoint = 'productdetailspage/RetailStoreAvailabilityInformation';

    // @observables:
    wwsVariantSelectionCopy: IVariantSelection;
    modalOpen: boolean;
    openedStore: string;
    wwsData: IWorkWearStoreAvailabilityData | null;
    retailStoreServiceUpAndRunning: boolean;
    retailStoreServiceNotAvailable: boolean;
    availableInWws: boolean;
    availableStatusInKiosk: boolean | null;
    variantInfoInKiosk: IVariantsStoreInformation | undefined;

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        this.wwsVariantSelectionCopy = mainStore.dataStore.variantSelection;
        this.modalOpen = false;
        this.openedStore = '';
        this.wwsData = null;
        const wwsDataFromMainStore = mainStore.fetchedData.workWearStoreAvailability;
        if (wwsDataFromMainStore) {
            this.availableColors = wwsDataFromMainStore.availableColors.filter(color => color.code !== 0);
            this.availableSizeTypes = wwsDataFromMainStore.availableSizeTypes;
            this.availableModels = wwsDataFromMainStore.availableModels.filter(model => model.code !== 0);
        }

        this.retailStoreServiceUpAndRunning = false;
        this.retailStoreServiceNotAvailable = false;
        this.availableInWws = false;
        this.availableStatusInKiosk = null;
        this.variantInfoInKiosk = undefined;

        makeObservable(this, {
            changeModalOpen: action,
            selectedColor: computed,
            noVariants: computed,
            changeSelectedColor: action,
            selectedSizeType: computed,
            changeSelectedSizeType: action,
            selectedSize: computed,
            changeSelectedSize: action,
            changeSelectedModel: action,
            changeOpenedStore: action,
            changeWWSVariantSelectionCopy: action,
            changeWWSData: action,
            wwsData: observable,
            modalOpen: observable,
            openedStore: observable,
            wwsVariantSelectionCopy: observable,
            retailStoreServiceUpAndRunning: observable,
            retailStoreServiceNotAvailable: observable,
            availableInWws: observable,
            updateRetailStoreServiceAvailabilityStatus: action,
            updateAvailableInWws: action,
            updateAvailableInKioskStore: action,
            availableStatusInKiosk: observable,
            variantInfoInKiosk: observable,
            availableColors: observable,
            availableModels: observable,
            availableSizeTypes: observable,
            changeWWSVariant: action
        });

        // jest do not understands this line:
        if (
            typeof window !== 'undefined' &&
            process.env.NODE_ENV !== 'test' &&
            this.mainStore.dataStore.contextData.portal.includes('DE')
        )
            this.isRetailStoreServiceUpAndRunning();
    }

    // @actions:
    updateAvailableInKioskStore(wwsData: IWorkWearStoreAvailabilityData): void {
        const kioskStoreInfo = wwsData.variantsStoreInformation.find(value => {
            return value.retailStoreNo.toString() === this.mainStore.wwsId;
        });

        if (typeof kioskStoreInfo !== 'undefined') {
            const exist = kioskStoreInfo.variants.find((value) => value.colorCode ===
                this.mainStore.variantStore.selectedColor.code && value.salesArticleNo ===
                this.mainStore.variantStore.selectedSize?.salesArticleVariantKey.split('-')[0]);
            this.availableStatusInKiosk = exist ? exist.availabilityStatus === AvailabilityStatusEnum.InStock : false;
        } else {
            this.availableStatusInKiosk = false;
        }
    }

    updateAvailableInWws(value: boolean): void {
        this.availableInWws = value ?? false;
    }

    updateRetailStoreServiceAvailabilityStatus(
        retailStoreServiceUpAndRunning: boolean,
        retailStoreServiceNotAvailable: boolean
    ): void {
        this.retailStoreServiceUpAndRunning = retailStoreServiceUpAndRunning;
        this.retailStoreServiceNotAvailable = retailStoreServiceNotAvailable;
    }

    changeWWSData(updatedWWSData: IWorkWearStoreAvailabilityData) {
        this.wwsData = updatedWWSData;
        if (this.mainStore.showKioskLayout) {
            this.updateAvailableInKioskStore(updatedWWSData);
            this.variantInfoInKiosk = updatedWWSData.variantsStoreInformation.find(
                store => store.retailStoreNo.toString() === this.mainStore.wwsId
            );
        }
    }

    changeSelectedColor(updatedSelectedColor: IColor): void {
        if (this.wwsVariantSelectionCopy.selectedColor?.code !== updatedSelectedColor.code) {
            const callBack = () => {
                this.wwsVariantSelectionCopy.selectedColor = updatedSelectedColor;
            }
            const selectedColor = this.availableColors.find(color => color.code === updatedSelectedColor.code);
            if (selectedColor) {
                this.changeWWSVariant(selectedColor.salesArticleVariantKey, callBack.bind(this));
            }
        }
    }

    changeWWSVariantSelectionCopy(updatedVariantSelectionCopy: IVariantSelection): void {
        this.wwsVariantSelectionCopy = updatedVariantSelectionCopy;
    }

    changeSelectedSizeType(updatedSelectedSizeType: string): void {
        const sizeType = this.wwsVariantSelectionCopy.availableSizeTypes.find(aSizeType => {
            return aSizeType.sizeTypeName === updatedSelectedSizeType;
        });
        if (sizeType) {
            this.wwsVariantSelectionCopy.selectedSizeType = updatedSelectedSizeType;
            const defaultSize = sizeType?.sizes.find(size => {
                return size.salesArticleVariantKey;
            });
            if (defaultSize)
                this.changeSelectedSize(defaultSize.size);
        }
    }

    changeSelectedSize(updatedSelectedSize: ISize): void {
        if (this.wwsVariantSelectionCopy.selectedSize?.code !== updatedSelectedSize.code) {
            this.wwsVariantSelectionCopy.selectedSize = updatedSelectedSize;
        }
    }

    changeSelectedModel(updatedSelectedModel: IModel): void {
        if (this.wwsVariantSelectionCopy.selectedModel?.code !== updatedSelectedModel.code) {
            const callBack = () => {
                this.wwsVariantSelectionCopy.selectedModel = updatedSelectedModel
            };
            const selectedModel = this.availableModels.find(model => model.code === updatedSelectedModel.code);
            if (selectedModel) {
                this.changeWWSVariant(selectedModel.salesArticleVariantKey, callBack.bind(this));
            }
        }
    }

    changeModalOpen(modalOpen: boolean): void {
        this.modalOpen = modalOpen;
        modalOpen ? this.mainStore.hideEsAppButton() : this.mainStore.showEsAppButton();
    }

    changeOpenedStore(openedStore: string): void {
        this.openedStore = openedStore;
    }

    // @computed:
    get selectedColor(): IColor | undefined {
        return this.wwsVariantSelectionCopy.selectedColor;
    }

    get selectedSizeType(): ISizeType | undefined {
        const updatedSelectedSizeType = this.wwsVariantSelectionCopy.selectedSizeType;
        return this.wwsVariantSelectionCopy.availableSizeTypes.find(sizeType => {
            return sizeType.sizeTypeName === updatedSelectedSizeType;
        });
    }

    get selectedModel(): IModel | undefined {
        return this.wwsVariantSelectionCopy.selectedModel;
    }

    get selectedSize(): ISalesArticleVariantSize | undefined {
        let result: ISalesArticleVariantSize | undefined;
        const preselectedSizeType = this.selectedSizeType?.sizes.find(size => {
            return size.size.code === this.wwsVariantSelectionCopy.selectedSize?.code;
        });

        if (preselectedSizeType) result = preselectedSizeType;
        else result = this.selectedSizeType?.sizes[0];
        return result;
    }

    get noVariants(): boolean {
        return (
            (!(this.availableColors instanceof Array) || this.availableColors.length <= 1) &&
            (!(this.selectedSizeType?.sizes instanceof Array) || this.selectedSizeType.sizes.length <= 1) &&
            (!(this.availableModels instanceof Array) || this.availableModels.length <= 1)
        );
    }

    variantAvailabilityState(
        variantCode: number,
        variantType: VariantType,
        savKey: string,
        variantStoreInformation?: IVariantsStoreInformation
    ): AvailabilityStatusEnum {
        if (!variantStoreInformation)
            return AvailabilityStatusEnum.NotInStock;

        let articleVariant: IArticleVariant | undefined;
        const saNo = savKey.split('-')[0];
        switch (variantType) {
            case VariantType.Color: {
                articleVariant = variantStoreInformation?.variants.find(aVariant => {
                    return aVariant.salesArticleNo === saNo && aVariant.colorCode === variantCode;
                });
                break;
            }
            case VariantType.Model: {
                articleVariant = variantStoreInformation?.variants.find(aVariant => {
                    return aVariant.salesArticleNo === saNo && aVariant.modelCode === variantCode;
                });
                break;
            }
            case VariantType.Size: {
                articleVariant = variantStoreInformation?.variants.find(aVariant => {
                    return aVariant.sizeCode === variantCode &&
                        aVariant.colorCode === this.selectedColor?.code &&
                        aVariant.modelCode === this.selectedModel?.code;
                });
                break;
            }
        }
        return articleVariant ? articleVariant.availabilityStatus : AvailabilityStatusEnum.NotInStock;
    }

    isRetailStoreServiceUpAndRunning(): void {
        if (!this.retailStoreServiceUpAndRunning || this.retailStoreServiceNotAvailable) {
            const masterArticleNoString = this.mainStore.fetchedData.orderModule.articleInfo.masterArticleNo.toString();
            const apiUrl = new URL(this.mainStore.fetchedData.contextData.apiMagicLink + this.apiEndpoint);
            apiUrl.searchParams.set('masterArticleNo', masterArticleNoString);
            axios
                .get<IWorkWearStoreAvailabilityData>(apiUrl.toString())
                .then(result => {
                    // Retails store service answered and now depends everything from response data:
                    this.updateRetailStoreServiceAvailabilityStatus(true, false);

                    // Collect response data: true/false:
                    this.updateAvailableInWws(result?.data?.availableInAnyStore);

                    // Update wwsData:
                    this.changeWWSData(result?.data);
                })
                .catch(error => {
                    this.updateRetailStoreServiceAvailabilityStatus(false, true);
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        }
    }

    changeWWSVariant(savKey: string, callBack?: () => void): void {
        const fetchedData = this.mainStore.fetchedData;
        const navKey = fetchedData.breadcrumb.category.navigationKey;
        const masterarticleNo = fetchedData.orderModule.articleInfo.masterArticleNo;
        const salesArticleVariantKey = savKey.split('-');
        this.mainStore.fetch.fetch(
            salesArticleVariantKey[0],
            salesArticleVariantKey[1],
            masterarticleNo,
            salesArticleVariantKey[2],
            navKey).then(response => {
                if (response) {
                    const wwsDataFromMainStore = response.workWearStoreAvailability;
                    if (wwsDataFromMainStore) {
                        this.availableColors = wwsDataFromMainStore.availableColors.filter(color => color.code !== 0);
                        this.availableSizeTypes = wwsDataFromMainStore.availableSizeTypes;
                        this.availableModels = wwsDataFromMainStore.availableModels.filter(model => model.code !== 0);
                        this.wwsVariantSelectionCopy = response.variantSelection;
                    }
                    if (callBack) callBack();
                } // else this.resetVariantState();
            });
    }
}

export default WorkWearStoreStore;
