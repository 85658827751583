import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { configure } from 'mobx';
configure({ enforceActions: 'observed', isolateGlobalState: true });

import { Provider } from 'mobx-react';
import { MainStore } from './stores/MainStore';
import DetailsPageLayout from './components/detailsPage/detailsPageLayout';
import './thirdPartyStyles/styles';

function hydrateApp() {
    const state = JSON.parse(JSON.stringify(window.__PRELOADED_PDP_STATE__));
    const store: MainStore = new MainStore(state.otherData, state.localization);
    delete window.__PRELOADED_PDP_STATE__;
    ReactDOM.hydrate(
        <Provider
            store={store}
            orderModuleStore={store.orderModuleStore}
            mixedMediaStore={store.mixedMediaStore}
            variantStore={store.variantStore}
            workWearStoreStore={store.workWearStoreStore}
            dataStore={store.dataStore}
        >
            <DetailsPageLayout />
            <script suppressHydrationWarning={true} />
        </Provider>,
        document.getElementById('product-details-page-entry')
    );
}

hydrateApp();
