import React from 'react';
/* eslint-disable max-len */
const DataSheetIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 17.80078 21.80078'>
        <path d='M14.90039,21.80078h-12A2.90451,2.90451,0,0,1,0,18.90039v-16A2.90451,2.90451,0,0,1,2.90039,0h12a2.90451,2.90451,0,0,1,2.90039,2.90039v16A2.90451,2.90451,0,0,1,14.90039,21.80078Zm-12-20A1.10071,1.10071,0,0,0,1.80078,2.90039v16A1.1007,1.1007,0,0,0,2.90039,20h12A1.1007,1.1007,0,0,0,16,18.90039v-16a1.1007,1.1007,0,0,0-1.09961-1.09961Z' />
        <path d='M12.90039,6.67871h-8a.7002.7002,0,0,1,0-1.40039h8a.7002.7002,0,1,1,0,1.40039Z' />
        <path d='M12.80762,10.42383H4.90039a.7002.7002,0,0,1,0-1.40039h7.90723a.7002.7002,0,0,1,0,1.40039Z' />
        <path d='M8.47168,14.169H4.90039a.7002.7002,0,0,1,0-1.4004H8.47168a.7002.7002,0,0,1,0,1.4004Z' />
    </svg>
);

export default DataSheetIcon;
