import React, { useEffect, useState } from 'react';
import ProductDescription from './description/productDescription';
import ProductDescriptionLinks from './links/productDescriptionLinks';
import styles from './productInfo.scss';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../types';
import Details from './details/details';
import Extras from './extras/extras';
import * as helper from '../../helper/html-helper';
import ProductAdditionalLayout from './additionalLayout/productAdditionalLayout';
import {RichText} from '../../helper/richText';
import Bullets from './bullets/bullets';
import Disclaimers from './disclaimer/disclaimers';
import ShoeWeights from './shoeWeights/shoeWeights';
import Materials from './materials/materials';
import CareLabels from './careLabels/careLabels';
import FunctionalLayers from './functionalLayers/functionalLayers';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const ProductInfo = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const [renderAdditionalLayout, setRenderAdditionalLayout] = useState(false);
        const [productInfoContentHeight, setProductInfoContentHeight] = useState(0);
        const productDescriptionRef = React.useRef<ProductDescription>(null);
        const functionalLayersRef = React.useRef<FunctionalLayers>(null);
        const bulletsRef = React.useRef<Bullets>(null);
        const productContentRef = React.useRef<HTMLDivElement>(null);
        const productImageRef = React.useRef<HTMLImageElement>(null);
        const extrasRef = React.useRef<Extras>(null);
        const detailsRef = React.useRef<Details>(null);
        const shoeWeightsRef = React.useRef<ShoeWeights>(null);
        const disclaimersRef = React.useRef<Disclaimers>(null);
        const materialsRef = React.useRef<Materials>(null);
        const careLabelsRef = React.useRef<CareLabels>(null);
        const [showContent, setShowContent] = useState(false);

        const structuredText = store!.fetchedData.shutterModule.productInfo.description.bullets;
        const orderedDisclaimers = store!.fetchedData.shutterModule.productInfo.description.orderedDisclaimers;
        const commonRtfText = store!.fetchedData.shutterModule.productInfo.description.commonRtfTextFromWsd;
        const shoeWeights = store!.fetchedData.shutterModule.productInfo.description.shoeWeights;
        const materials = store!.fetchedData.shutterModule.productInfo.description.materials;
        const careLabels = store!.fetchedData.shutterModule.productInfo.description.careInstructions;
        const functionalLayers = store!.fetchedData.shutterModule.productInfo.description.functionLayers;

        const productInfo: IProductInfo = {
            tabs: [
                {
                    title: helper.decodeHTML(store?.localization.generalL10n.briefDescription),
                    content: (
                        <div>
                            {
                                (structuredText && structuredText.id) ? 
                                    <Bullets 
                                    ref={bulletsRef}
                                    structuredTextId={structuredText.id} />
                                :
                                    <ProductDescription
                                        ref={productDescriptionRef}
                                        functionalLayersExists={functionalLayers && functionalLayers.length > 0}
                                        description={commonRtfText}
                                        disclaimersExists={orderedDisclaimers && orderedDisclaimers.length > 0}
                                    />
                            }
                            <ShoeWeights
                                ref={shoeWeightsRef}
                                shoeWeights={shoeWeights}
                                gramsInSizeLbl={store?.localization.generalL10n.gramsInSize}
                                approxWeightLbl={store?.localization.generalL10n.approxWeight}
                            />
                            <Materials
                                ref={materialsRef}
                                label={store?.localization.generalL10n.materialLabel}
                                materials={materials}
                                grammageLabel={store?.localization.generalL10n.grammage}
                                portalMandantToken={store?.dataStore.contextData.portalMandantToken}
                            />
                            <CareLabels
                                ref={careLabelsRef}
                                label={store?.localization.generalL10n.careLabel}
                                careLabels={careLabels}
                                isMobileShop={store?.isMobileShop}
                            />
                            <Disclaimers
                                ref={disclaimersRef}
                                disclaimers={orderedDisclaimers}
                                debuggerIsOn={store!.debuggerIsOn}
                                structuredTextExists={structuredText != null 
                                    && structuredText.id.length > 0}
                            />
                            <FunctionalLayers
                                ref={functionalLayersRef}
                                functionalLayers={functionalLayers}
                            /> 
                            <ProductDescriptionLinks />
                        </div>
                    ),
                },
                {
                    title: helper.decodeHTML(store?.localization.generalL10n.specs),
                    content: <Details ref={detailsRef} 
                        details={store!.fetchedData.shutterModule.productInfo.details} />,
                },
                {
                    title: helper.decodeHTML(store?.localization.generalL10n.extras),
                    content: (
                        <Extras
                            ref={extrasRef}
                            extras={store!.fetchedData.shutterModule.productInfo.extras}
                            debuggerIsOn={store!.debuggerIsOn}
                            updateProductInfoContentHeight={updateAdditionalLayout}
                        />
                    ),
                },
            ],
        };

        const [activeTab, setActiveTab] = React.useState(productInfo.tabs[0]);

        useEffect(() => {
            if (productDescriptionRef && productDescriptionRef.current && productImageRef) {
                productDescriptionRef.current.update(
                    commonRtfText, functionalLayers && functionalLayers.length > 0, 
                    orderedDisclaimers && orderedDisclaimers.length > 0
                    );
            }

            if (functionalLayersRef && functionalLayersRef.current) {
                functionalLayersRef.current.update(functionalLayers);
            }

            if (shoeWeightsRef && shoeWeightsRef.current) {
                shoeWeightsRef.current.update(shoeWeights);
            }

            if (disclaimersRef && disclaimersRef.current) {
                disclaimersRef.current.update(orderedDisclaimers);

                disclaimersRef.current.turnOnDebugger(store!.debuggerIsOn);
            }

            if (materialsRef && materialsRef.current) {
                materialsRef.current.update(materials);
            }

            if (careLabelsRef && careLabelsRef.current) {
                careLabelsRef.current.update(careLabels);
            }

            if (extrasRef && extrasRef.current) {
                if(store!.fetchedData.shutterModule.productInfo.extras.length === 0 &&
                    activeTab.title === helper.decodeHTML(store?.localization.generalL10n.extras)) {
                        setActiveTab(productInfo.tabs[0])
                    return;
                }

                extrasRef.current.updateExtras(store!.fetchedData.shutterModule.productInfo.extras);
                extrasRef.current.turnOnDebugger(store!.debuggerIsOn);
            }

            if (detailsRef && detailsRef.current) {
                if(store!.fetchedData.shutterModule.productInfo.details.length === 0 &&
                    activeTab.title === helper.decodeHTML(store?.localization.generalL10n.specs)) {
                        setActiveTab(productInfo.tabs[0])
                    return;
                }

                detailsRef.current.updateSpecs(store!.fetchedData.shutterModule.productInfo.details);
            }

            if (bulletsRef && bulletsRef.current && structuredText && structuredText.id)
                bulletsRef.current.updateBullets(structuredText.id);
        });

        useEffect(() => {
            updateAdditionalLayout();
        }, [activeTab]);

        function updateAdditionalLayout(recalculateClientHeight = false) {
            if (productContentRef.current) {
                const content = productContentRef.current.firstChild as HTMLElement;
                if (content) {
                    // reset old styles:
                    productContentRef.current.classList.remove('pdp-removed_overflow');

                    if(recalculateClientHeight)
                        content.removeAttribute('style');

                    const isBigContent: boolean = content.clientHeight >= 552;
                    setRenderAdditionalLayout(isBigContent);
                    setProductInfoContentHeight(content.clientHeight);
                    setShowContent(false);
                    if (isBigContent === true) 
                        content.style.maxHeight = '511px';
                    else 
                        productContentRef.current.classList.add('pdp-removed_overflow');
                }
            }
        }

        const emotionalTextRichtextId = store!.fetchedData.shutterModule.productInfo.emotionalText?.text?.id;
        const claimRichTextId = store!.fetchedData.shutterModule.productInfo.claim?.text?.id;

        return (
            <>
                <div className={styles.product_info_container}>
                    <div className={styles.product_image}>
                        <img alt={helper.decodeHTML(store!.mixedMediaStore.altTagForImages) ?? ''}
                            ref={productImageRef} src={store!.mixedMediaStore.mainImage.fullPath} />
                    </div>
                    <div className={styles.product_info_tabs}>
                        {
                            store!.fetchedData.shutterModule.productInfo.claim &&
                            <div
                                className={classNames(styles.claim)}
                                data-testid='product_info_claim'
                            >
                                <RichText richTextId={claimRichTextId} />
                            </div>
                        }
                        {
                            store!.fetchedData.shutterModule.productInfo.emotionalText &&
                            <div
                                className={classNames(styles.emotionalText)}
                                data-testid='product_info_emotionaltext'
                            >
                                <RichText richTextId={emotionalTextRichtextId} />
                            </div>
                        }
                        <div
                            className={classNames(
                                styles.product_info_tabs_header,
                                {[styles.no_extras]: store!.fetchedData.shutterModule.productInfo.extras.length === 0},
                                {[styles.no_specs]: store!.fetchedData.shutterModule.productInfo.details.length === 0}
                            )}
                        >
                            {productInfo.tabs.map(tab => {
                                return (
                                    <div
                                        key={tab.title}
                                        data-testid='info_tab'
                                        className={classNames(styles.product_info_tab, {
                                            [styles.active]: tab.title === activeTab.title,
                                        })}
                                        onClick={() => {
                                            if(tab.title !== activeTab.title)
                                                setActiveTab(tab)
                                            }
                                        }
                                    >
                                        {tab.title}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            id='product_info_content'
                            className={styles.product_info_content}
                            role='content'
                            ref={productContentRef}
                        >
                            {activeTab.content}
                        </div>
                    </div>
                </div>
                <ProductAdditionalLayout
                    renderAdditionalLayout={renderAdditionalLayout}
                    productInfoContentHeight={productInfoContentHeight}
                    productContentRef={productContentRef}
                    showContent={showContent}
                    setShowContent={setShowContent}
                />
            </>
        );
    })
);

interface IProductInfo {
    tabs: IProductInfoTab[];
}

interface IProductInfoTab {
    title?: string;
    content: React.ReactNode;
}
