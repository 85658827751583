import React from 'react';
import { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { IRichTextItemInternal, IRichTextItemsInternal,NodeType } from '../stores/types';
import classNames from 'classnames';
import { MainStore } from '../stores/MainStore';
import styles from './richText.scss';

interface IRichTextProps {
    store?: MainStore;
    richTextId: string;
    injectedRichTexts?: IRichTextItemsInternal[];
}
export const RichText = inject('store')(
    observer((props: IRichTextProps) => {
        const {
            store,
            richTextId,
            injectedRichTexts,
        } = props;
        const injectedRichTextExist = injectedRichTexts && injectedRichTexts.length;
        const allRichTexts = injectedRichTextExist ? props.injectedRichTexts : store?.fetchedData.allRichTexts;
        
        if(!allRichTexts) return <></>;
        
        const richTextItemsSelected: IRichTextItemInternal[] | undefined 
            = allRichTexts.find(rit=>rit.id === richTextId)?.items;

        if(!richTextItemsSelected || !richTextItemsSelected.length) return <></>;

        function MapLines(richTextItems: IRichTextItemInternal[]): ReactNode {
            return richTextItems.map((x, index)=> MapLine(x, index));
        }

        function MapLine(richTextItem: IRichTextItemInternal, index: number): ReactNode {
            if (richTextItem.nodeType == NodeType.LineBreak) return <br key={index} data-testid='br_type_elm' />;

            if (richTextItem.nodeType == NodeType.BlankLine)
                return <div key={index} className={classNames(getClasses(richTextItem.markups, true, false))}
                    data-testid='bl_type_elm'></div>;

            if (richTextItem.nodeType == NodeType.Paragraph) {
                return (
                    <div key={index} className={classNames(getClasses(richTextItem?.markups, false, false))}
                        data-testid='paragraph_type_elm'>
                        {MapLines(richTextItem.children)}
                    </div>);
            }
            if (richTextItem.nodeType == NodeType.List) {
                return (
                    <ul key={index} className={'pdp-richtext_list'} data-testid='ul_type_elm'>
                        {MapLines(richTextItem.children)}
                    </ul>
                )
            }
            if (richTextItem.nodeType == NodeType.Text) {
                if (richTextItem.link && richTextItem.link.href) {
                    if (richTextItem.link.openInNewTab)
                        return (
                            <a key={index}
                                className={classNames(getClasses(richTextItem?.markups, true, true))}
                                data-testid='a_type_elm' href={richTextItem.link.href} target='_blank' rel='noreferrer'>
                                {richTextItem.textValue}
                            </a>);
                    else
                        return (
                            <a key={index}
                                className={classNames(getClasses(richTextItem?.markups, true, true))}
                                data-testid='a_type_elm' href={richTextItem.link.href}>
                                {richTextItem.textValue}
                            </a>);
                }
                else
                    return (
                        <span key={index} className={classNames(getClasses(richTextItem.markups, true, false))}
                            data-testid='span_type_elm'>
                            {richTextItem.textValue}
                        </span>);
            }
            if (richTextItem.nodeType == NodeType.ListItem) {
                const children = richTextItem.children && richTextItem.children.length
                    ? MapLines(richTextItem.children)
                    : null;

                if (richTextItem.link && richTextItem.link.href) {
                    if (richTextItem.link.openInNewTab)
                        return (
                            <li key={index} className={classNames(getClasses(richTextItem.markups, true, false))}
                                data-testid='li_type_elm'>
                                <a key={index} className={styles.richtext_line_a}
                                    data-testid='a_type_elm' href={richTextItem.link.href} target='_blank' rel='noreferrer'>
                                    {richTextItem.textValue}
                                    {children}
                                </a>
                            </li>);
                    else
                        return (
                            <li key={index} className={classNames(getClasses(richTextItem.markups, true, false))}
                                data-testid='li_type_elm'>
                                <a key={index} className={styles.richtext_line_a}
                                    data-testid='a_type_elm' href={richTextItem.link.href}>
                                    {richTextItem.textValue}
                                    {children}
                                </a>
                            </li>);
                }
                else
                    return (
                        <li key={index} className={classNames(getClasses(richTextItem.markups, true, false))}
                            data-testid='li_type_elm'>
                            {richTextItem.textValue}
                            {children}
                        </li>);
            }
        }

        function getClasses(
            markups: string[],
            extendWithBulletStyle: boolean,
            extendWithAnchorStyle: boolean): string[] {

            let classes: string[] = [];
            if (extendWithBulletStyle)
                classes = classes.concat('pdp-richtext_bullet');

            if (markups && markups.length) {
                classes = classes.concat(markups.map(x=>'pdp-richtext_'+x))
            }

            if (extendWithAnchorStyle)
                classes = classes.concat(styles.richtext_line_a);

            return classes;
        }
        
        return <div className = {styles.richtext_root} data-testid='richtext_root'>
            {MapLines(richTextItemsSelected)}
        </div>
}));
