import React from 'react';
import styles from './additionalDescription.scss';
import {
    IAdditionalDescription,
    IAdditionalDescriptionElement,
    IAdditionalDescriptionTitle,
    IAdditionalDescriptionText,
    IAdditionalDescriptionImage,
    IAdditionalDescriptionLink,
    IAdditionalDescriptionShowcase,
} from '../../stores/types';
import classNames from 'classnames';
import AdditionalDescriptionTitle from './content/additionalDescription_title';
import AdditionalDescriptionText from './content/additionalDescription_text';
import AdditionalDescriptionImage from './content/additionalDescription_image';
import AdditionalDescriptionButtonLink from './content/additionalDescription_buttonLink';
import AdditionalDescriptionEmpty from './content/additionalDescription_empty';

import AdditionalDescriptionShowcase from './content/additionalDescription_showcase';
import AdditionalDescriptionSeparatorLine from './content/additionalDescription_separatorLine';
import DebugBarHelper from '../../helper/debugBarHelper';
import DebuggerDataSource from '../debugBar/debuggerDataSource';

function AdditionalDescription(props: IAdditionalDescription) {
    const { rows, l10n, fallBackImageUrl, threeDContainerId, has3DView, debuggerIsOn, 
        documentKey, isRelease } = props;
    const debugBarHelper = DebugBarHelper.instance;

    function detectContent(content: IAdditionalDescriptionElement) {
        switch (content.contentType) {
            case 'Title': {
                const titleContent = content as IAdditionalDescriptionTitle;
                return (
                    <AdditionalDescriptionTitle
                        text={titleContent.text}
                        types={titleContent.types}
                        contentType={titleContent.contentType}
                    />
                );
            }
            case 'Text': {
                const textContent = content as IAdditionalDescriptionText;
                return (
                    <AdditionalDescriptionText
                        value={textContent.value}
                        types={textContent.types}
                        contentType={textContent.contentType}
                    />
                );
            }
            case 'Image':
            case 'Logo': {
                const imageContent = content as IAdditionalDescriptionImage;
                return (
                    <AdditionalDescriptionImage
                        types={imageContent.types}
                        contentType={imageContent.contentType}
                        url={imageContent.url}
                        altText={imageContent.altText}
                        title={imageContent.title}
                        aspectRatio={imageContent.aspectRatio}
                        scale={imageContent.scale}
                        height={imageContent.height}
                        width={imageContent.width}
                    />
                );
            }
            case 'Link':
            case 'Pdf': {
                const linkContent = content as IAdditionalDescriptionLink;
                linkContent.isDownloadable = linkContent.contentType === 'Pdf';
                return (
                    <AdditionalDescriptionButtonLink
                        types={linkContent.types}
                        contentType={linkContent.contentType}
                        icon={linkContent.icon}
                        linkText={linkContent.linkText}
                        target={linkContent.target}
                        openInNewWindow={linkContent.openInNewWindow}
                        isDownloadable={linkContent.isDownloadable}
                        l10n={l10n}
                    />
                );
            }
            case 'Video': {
                return <div>{'This is a video, need more work'}</div>;
            }
            case 'Showcase': {
                const showcaseContent = content as IAdditionalDescriptionShowcase;
                return has3DView ? (
                    <AdditionalDescriptionShowcase
                        types={showcaseContent.types}
                        contentType={showcaseContent.contentType}
                        masterArticleNo={showcaseContent.masterArticleNo}
                        colorCode={showcaseContent.colorCode}
                        index={showcaseContent.index}
                        resumeInBackground={showcaseContent.resumeInBackground}
                        prefix={showcaseContent.prefix}
                        threeDContainerId={threeDContainerId + '_' + showcaseContent.index}
                        fallBackImageUrl={fallBackImageUrl}
                    />
                ) : (
                    <></>
                );
            }
            case 'SeparatorLine': {
                return <AdditionalDescriptionSeparatorLine />;
            }
            case 'Empty': {
                return <AdditionalDescriptionEmpty />
            }
            default: {
                return <></>;
            }
        }
    }
    
    return (
        <div data-testid='additional-description-container' className={
            classNames(styles.additional_description_column,
                { [debugBarHelper.getDebuggerClassName(
                    DebugBarHelper.AdditionalDescription_source)]: debuggerIsOn})}>
            {!isRelease && 
                <DebuggerDataSource value={'Additional description source Key: "' + documentKey + '"'}/>
            }
            {(!(rows instanceof Array) || rows.length === 0) ? null :
                rows.map((row, rowIndex) => {
                    return (
                        <div
                            key={rowIndex}
                            data-testid='additional-description-row'
                            className={classNames(
                                styles.additional_description_row,
                                { [styles.black_edition]: row.theme !== 'default' },
                                { [styles.vertical_separator]: row.types.includes('vertical-separator') }
                            )}
                        >
                            {row.cells instanceof Array &&
                                row.cells.length > 0 &&
                                row.cells.map((cell, cellIndex) => {
                                    return (
                                        <div
                                            key={cellIndex}
                                            data-testid='additional-description-cell'
                                            className={classNames(styles.additional_description_cell, {
                                                [styles.overlay_two_thirds]: cell.types.includes(
                                                    'additional-description-overlay-two-thirds'
                                                ),
                                            })}
                                        >
                                            {cell.contents instanceof Array &&
                                                cell.contents.map((content, contentIndex) => (
                                                    <div
                                                        data-testid='additional-description-content'
                                                        className={styles.additional_description_content}
                                                        key={contentIndex}
                                                    >
                                                        {detectContent(content)}
                                                    </div>
                                                ))}
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })
            }
        </div>
    );
}

export default AdditionalDescription;
