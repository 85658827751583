import React from 'react';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import styles from './threeDView.scss';
import classNames from 'classnames';
import * as ThreeDViewTypes from './threeDView.d';
import Icon_3D from '../../icons/Icon_3D';
import Icon_Play_3D from '../../icons/icon_play_3d';
import Icon_Pause_3D from '../../icons/icon_pause_3d';
import * as helper from '../../../helper/html-helper';

export default class ThreeDView extends React.Component<ThreeDViewTypes.IThreeDViewProps, 
    ThreeDViewTypes.IThreeDViewState> {
    constructor(props) {
        super(props);

        this.state = {
           play3d: true
        };

        this.handle3DAnimationStartStopClick = this.handle3DAnimationStartStopClick.bind(this);
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.variantColorCode !== this.props.variantColorCode)
            this.setState({ play3d: true });
    }

    private handle3DAnimationStartStopClick(): void {
        this.setState({ play3d: !this.state.play3d },
            () => {
                window.shell.publishTo('SCS.PlayPauseAnimation',
                    { containerId: 'ThreeSixtyView_mixedMedia', play3d: this.state.play3d });
            });
    }

    public render() {
        return (
            <div data-testid='threeD_view_main_image' className={classNames(styles.threeD_mi_container,
                {[styles.showThreeDOnly]: this.props.showThreeDOnly},
                {[styles.has3DView]: this.props.has3DView})}>
                <div data-testid='threed_view' className={styles.threed_view
                } onClick={this.props.handleShowGallery3d}>
                    <FragmentPlaceholder
                        src={
                            '/ESPP1-Showcase/threesixtyview?masterArticleNo=' +
                            this.props.masterArticleNo + '&colorCode=' + this.props.colorCode +
                            '&containerId=' + this.props.containerId +
                            '&fallBackImageUrl='+ this.props.fallBackImageUrl +
                            '&showThreeSixtyViewAfterPageLoad=true' +
                            '&showThreeSixtyViewAfterPageLoadOnMobile=false'
                        }
                        ignoreErrors={true}
                        timeout={2}
                    />
                </div>

                <div data-testid='icon_play_pause_3d' className={classNames(styles.icon_play_pause_3d,
                    {[styles.temporary_hide_scs]: this.props.temporaryHideSCS})} 
                    onClick={this.handle3DAnimationStartStopClick}>
                        {this.state.play3d ? <Icon_Pause_3D /> : <Icon_Play_3D />}
                </div>
                <div className={classNames(styles.icon_3d,
                    {[styles.temporary_hide_scs]: this.props.temporaryHideSCS})} 
                    onClick={this.props.handleShowGallery3d}><Icon_3D />
                </div>
                <img data-testid='second_main_image' className={
                    classNames(styles.second_mi, styles.prerender_slot)
                }
                    alt={helper.decodeHTML(this.props.altTag)}
                    src={this.props.fallBackImageUrl}
                    srcSet={this.props.srcSet} 
                />
            </div>
        );
    }
}
