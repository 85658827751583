import React, { useState } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import styles from './purchaseAdvice.scss';
import HeaderTab from './headerTab';
import ContentTab from './contentTab';
import ContentTabAAS from './contentTabAAS';
import * as helper from '../../../helper/html-helper';

export const PurchaseAdvice = inject('store')(
    observer(({ store }: IInjectedProps) => {
        if (!store)
            return null;
        const productFinderTabKey = 'PF';
        const aasTabKey = 'AAS';
        const aasTabKey_closed = 'AAS_closed';

        const [activeTab, setActiveTab] = useState(productFinderTabKey);
        const isMobile = store.isMobileShop;

        function handleContentChange(prop) {
            if (isMobile) {
                // for product finder, redirect to category and open finder:
                if (prop.currentTarget.dataset.tabkey === productFinderTabKey && store)
                    window.location.href = store.getProductFinderRedirectUrl;

                if (prop.currentTarget.dataset.tabkey === aasTabKey) {
                    store?.scrollLock.lock();
                    store?.aasFragmentHelper.showAASModal();
                    store?.hideEsAppButton();
                }

                if (prop.currentTarget.dataset.tabkey === aasTabKey_closed) {
                    store?.scrollLock.unlock();
                    store?.showEsAppButton();
                }
            }

            setActiveTab(prop.currentTarget.dataset.tabkey as string);
        }

        function activateAASShutter(showAssShutter: boolean) {
            store?.setHasAASShutter(showAssShutter);


            if (!store?.isMobileShop) {
                // on error in AAS side case:
                if(store?.hasProductFinderShutter && !showAssShutter) {
                    setActiveTab(productFinderTabKey);
                }
                else {
                    setActiveTab(store?.hasAASShutter ? aasTabKey : productFinderTabKey);
                }
            }
        }

        return (
            <>
                <div
                    data-testid='purchase_advice_shutter'
                    className={classNames(styles.purchase_advice_shutter, 'tf-dp_customs')}
                >
                    <div className={styles.pa_header}>
                        {store.hasAASShutter && (
                            <HeaderTab
                                title={store.localization.redOnlyL10n?.aasTitle}
                                description={store.localization.redOnlyL10n?.aasSubTitle}
                                tabKey={aasTabKey}
                                onTabChange={handleContentChange}
                                activeTab={activeTab}
                            />
                        )}
                        {store.hasProductFinderShutter && (
                            <HeaderTab
                                logoSrc={store.getProductFinderLogo}
                                title={store.getProductFinderTitle}
                                description={store.getProductFinderSubTitle}
                                tabKey={productFinderTabKey}
                                onTabChange={handleContentChange}
                                activeTab={activeTab}
                                cdnAssetsUrl={store.cdnAssetsUrl}
                            />
                        )}
                    </div>
                    <div className={styles.pa_content_container} data-testid='pa_content_container'>
                        <div
                            className={classNames(
                                styles.pa_content,
                                { [styles.isActive]: activeTab === aasTabKey },
                                { [styles.pa_aas_overlay_modal]: isMobile }
                            )}
                        >
                            <ContentTabAAS
                                savKey={store.dataStore.orderModule.articleInfo.salesArticleVariantKey}
                                activateAASShutter={activateAASShutter}
                                handleContentClose={handleContentChange}
                                tabKey={aasTabKey_closed}
                                closeOverlayBtnTitle={helper.decodeHTML(
                                    store.localization.redOnlyL10n?.aasBackToProduct
                                )}
                                aasFragmentHelper={store.aasFragmentHelper}
                                isMobile={store.isMobileShop}
                                localization={store.localization}
                                navKeyPath={store.dataStore.breadcrumb.category.navigationKeyPath}
                            />
                        </div>
                        {store.hasProductFinderShutter && !isMobile && (
                            <div
                                className={classNames(styles.pa_content, styles.pa_tf_content, {
                                    [styles.isActive]: activeTab === productFinderTabKey,
                                })}
                                data-testid='pa_content'
                            >
                                <ContentTab
                                    title={store.getProductFinderContentTitle}
                                    altTextTitle={store.getProductFinderTitle}
                                    description={store.getProductFinderContentDescription}
                                    logoSrc={store.getProductFinderLogo}
                                    cdnAssetsUrl={store.cdnAssetsUrl}
                                    pfName={store.getProductFinderName}
                                    redirectUrl={store.getProductFinderRedirectUrl}
                                    redirectBtnTitle={store.getProductFinderRedirectBtnText}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    })
);
