import React from 'react';
import styles from './additionalDescription_image.scss';
import { IAdditionalDescriptionImage } from '../../../stores/types';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

function AdditionalDescriptionImage(props: IAdditionalDescriptionImage) {
    return (
        <div
            data-testid='additional-description-image'
            className={classNames(
                styles.image,
                { [styles.scaling]: props.types.includes('scaling') },
                { [styles.no_margin_both]: props.types.includes('no-margin-both') },
                { [styles.logo]: props.types.includes('additional-description-logo') }
            )}
        >
            <LazyLoad offset={200}>
                <img src={props.url} alt={props.altText} title={props.title} />
            </LazyLoad>
        </div>
    );
}

export default AdditionalDescriptionImage;
