import React, { useRef, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { IVariantStore } from '../../../types';
import { SizeValue } from './sizeValue/sizeValue';
import { useViewportWidth } from '../../../helper/viewPortWidth';
import styles from './sizeValues.scss';
import { ISalesArticleVariantSize } from '../../../stores/types';

export const SizeValues = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const wrapperRef = useRef<HTMLDivElement>(null);
        const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };

        useEffect(() => {
            if (wrapperRef.current) {
                const wrapperChildren = Array.from(wrapperRef.current.children as HTMLCollectionOf<HTMLElement>);

                // reset width just incase
                wrapperChildren.forEach(element => {
                    element.style.width = 'auto';
                });

                let maxChildWidth = 0;
                wrapperChildren.forEach(element => {
                    if (element.clientWidth > maxChildWidth) maxChildWidth = element.offsetWidth;
                });

                if (maxChildWidth <= 50) maxChildWidth = 42;

                let column = 1;
                if (maxChildWidth > 100 && maxChildWidth <= 160) column = 2;
                else if (maxChildWidth <= 100 && maxChildWidth > 80) column = 3;
                else if (maxChildWidth <= 80 && maxChildWidth > 42) column = 4;
                else if (maxChildWidth <= 42) {
                    column = 5;
                    maxChildWidth = 42;
                    // ESPP-4849, for small view, based on view width to adjust column number
                    if (width >= 620 && width < 769) column = 7;
                    else if (width >= 470 && width < 620) column = 6;
                }

                while (column * maxChildWidth >= wrapperRef.current.clientWidth - 5) {
                    if (column > 1) column -= 1;
                    else break;
                }

                wrapperChildren.forEach(element => {
                    element.style.width = maxChildWidth + 'px';
                });
                wrapperRef.current.setAttribute('style', `grid-template-columns: repeat(${column}, 1fr);`);
            }
        }, [variantStore?.selectedSizeType]);

        function renderArticleVariantSize(articleVariantSize: ISalesArticleVariantSize) {
            return (
                <SizeValue
                    size={articleVariantSize.size}
                    variantKey={articleVariantSize.salesArticleVariantKey}
                    key={articleVariantSize.size.name}
                />
            );
        }

        function renderKioskSizes(): JSX.Element {
            return (
                <>{variantStore?.mainStore.workWearStoreStore.selectedSizeType?.sizes.map(renderArticleVariantSize)}</>
            );
        }

        function renderVariantStoreSizes(): JSX.Element {
            return <>{variantStore?.selectedSizeType?.sizes.map(renderArticleVariantSize)}</>;
        }

        return (
            <div ref={wrapperRef} data-testid='size_values' className={styles.size_values}>
                {variantStore?.mainStore.showKioskLayout ? renderKioskSizes() : renderVariantStoreSizes()}
            </div>
        );
    })
);
