import React from 'react';

/* eslint-disable */
const IconArrowSmallLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.79991 19.80091">
        <path d="M8.90039,19.80091a.90016.90016,0,0,0,.67236-1.498L2.104,9.90052,9.57275,1.49818A.9.9,0,1,0,8.22705.30286l-8,9a.90014.90014,0,0,0,0,1.19532l8,9A.89946.89946,0,0,0,8.90039,19.80091Z" />
    </svg>
);

export default IconArrowSmallLeft;
