import React from 'react';
import { inject, observer } from 'mobx-react';
import { IDocumentLink } from '../../../stores/types';
import styles from './productDescriptionLinks.scss';
import LogoServiceIcon from '../../icons/LogoServiceIcon';
import DatasheetIcon from '../../icons/DatasheetIcon';
import classNames from 'classnames';
import { IInjectedProps } from '../../../types';
import ModalWrapper from '../../modalwrapper/modalWrapper';
import * as helper from '../../../helper/html-helper';
import ToolTip from '../../personalizeOverlay/toolTip';
import InfoIcon from '../../icons/infoIcon';
import SelfDesignIcon from '../../icons/selfDesign';
import DesignWithAdviceIcon from '../../icons/designWithAdvice';

/* eslint-disable @typescript-eslint/no-non-null-assertion */

interface IDataSheetButtonProps {
    datasheet: IDocumentLink;
    datasheetLabel: string;
}

const ProductDescriptionLinks = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const [toolMobileTipContent, setMobileToolTipContent] = React.useState('');
        const [activeToolTip, setActiveToolTip] = React.useState(0);
        const logoServiceInfoUrl = store?.fetchedData.shutterModule.productInfo.description.links.logoServiceInfoUrl;

        const onIconTooltipClick = (event, newToolTipContent) => {
            event.stopPropagation();
            event.preventDefault();
            store?.scrollLock.lock();
            setMobileToolTipContent(newToolTipContent);
            setActiveToolTip(3);
        };

        const closeToolTip = () => {
            setActiveToolTip(0);
            setMobileToolTipContent('');
            store?.scrollLock.unlock();
        }

        const showToolTipOnHover = (toolTipKey: number) => {
            if (!store?.isMobileShop)
                setActiveToolTip(toolTipKey);
        }

        const [datasheetsModalOpen, setDatasheetsModalOpen] = React.useState(false);
        const onModalOpenStateChange = newState => {
            if (newState) store?.scrollLock.lock();
            else store?.scrollLock.unlock();
            setDatasheetsModalOpen(newState);
        };
        function handleDesignYourselfClick() {
            store?.onPersonalizeIntent(false);
        }
        const datasheets = store?.fetchedData.shutterModule.productInfo.description.links.dataSheets;
        const localization = store?.localization;

        return (
            <>
                {datasheets && datasheets.length > 0 && (
                    <div className={styles.datasheet_hint}>{
                        store?.localization.generalL10n.datasheetHint}
                    </div>
                )}
                <div className={styles.description_link_list}>
                    {(logoServiceInfoUrl && !store?.dataStore.contextData.featureToggles.enableLogoCreator) && (
                        <a
                            className={classNames(styles.link_button, styles.logo_service_info_button)}
                            href={logoServiceInfoUrl}
                            data-testid='logo_service_info_button'
                            rel='noreferrer'
                        >
                            <div className={styles.link_title}>
                                <span className={styles.link_icon}>
                                    <LogoServiceIcon />
                                </span>
                                <span className={styles.link_label}>
                                    {helper.decodeHTML(localization?.generalL10n.logoService)}
                                </span>
                            </div>
                        </a>
                    )}
                    {datasheets && datasheets.length == 1 && (
                        <DataSheetButton
                            datasheet={datasheets[0]}
                            datasheetLabel={localization!.generalL10n.datasheet}
                        />
                    )}
                    {datasheets && datasheets.length > 1 && (
                        <>
                            <div
                                data-testid={'datasheet_link'}
                                onClick={() => onModalOpenStateChange(true)}
                                className={classNames(styles.link_button, styles.multiple_datasheets_modal_button)}
                            >
                                <div className={styles.link_title}>
                                    <span className={styles.link_icon}>
                                        <DatasheetIcon />
                                    </span>
                                    <span className={styles.link_label}>{localization?.generalL10n.datasheets}</span>
                                </div>
                            </div>
                            <ModalWrapper
                                isOpen={datasheetsModalOpen}
                                onClose={() => onModalOpenStateChange(false)}
                                title={localization!.generalL10n.datasheets}
                                subtitle={store?.fetchedData.orderModule.articleInfo.title}
                                content={
                                    <div>
                                        {datasheets.map((dataSheet, i) => {
                                            return (
                                                <DataSheetButton
                                                    datasheet={dataSheet}
                                                    datasheetLabel={dataSheet.title}
                                                    key={i}
                                                />
                                            );
                                        })}
                                    </div>
                                }
                            />
                        </>
                    )}
                </div>
                {store?.dataStore.contextData.featureToggles.enableLogoCreator &&
                    <div className={styles.logo_creator_service}>
                        {(activeToolTip === 3) &&
                            <ToolTip key={3} text={toolMobileTipContent}
                                isMobileShop={store.isMobileShop}
                                closeToolTip={closeToolTip}
                            />
                        }
                        {(store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ||
                        store.dataStore.bannerModule.logoServiceRedirectUrl) &&
                            <div className={styles.lcs_title}>{localization?.generalL10n.personalization}</div>
                        }
                        {store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable &&
                            <div onClick={handleDesignYourselfClick} data-testid='logoCreatorBtn'
                                onMouseEnter={() => {
                                    showToolTipOnHover(1);
                                }}
                                onMouseLeave={() => {
                                    showToolTipOnHover(0);
                                }}
                                className={classNames(styles.link_button, styles.multiple_datasheets_modal_button)}
                            >
                                {store.isMobileShop &&
                                    <div className={styles.icon_tooltip}
                                        data-testid='design_yourself_info'
                                        onClick={(event: React.MouseEvent) => {
                                            onIconTooltipClick(event, helper.decodeHTML(
                                                localization?.generalL10n.designYourselfInfo));
                                        }}>
                                        <InfoIcon />
                                    </div>}
                                <div className={styles.link_title}>
                                    <span className={styles.link_icon}>
                                        <SelfDesignIcon />
                                    </span>
                                    <span className={styles.link_label}>{helper.decodeHTML(
                                        localization?.generalL10n.designYourself
                                    )}</span>
                                </div>
                                {(!store.isMobileShop && activeToolTip === 1) && <ToolTip key={1} text={
                                    helper.decodeHTML(
                                        localization?.generalL10n.designYourselfInfo
                                    )}
                                    isMobileShop={store.isMobileShop}
                                    closeToolTip={closeToolTip}
                                />}
                            </div>
                        }
                        {store.dataStore.bannerModule.logoServiceRedirectUrl &&
                            <a href={logoServiceInfoUrl}
                                onMouseEnter={() => {
                                    showToolTipOnHover(2);
                                }}
                                onMouseLeave={() => {
                                    showToolTipOnHover(0);
                                }}
                                data-testid='logo_service_redirect_url'
                                className={classNames(styles.link_button, styles.multiple_datasheets_modal_button)}
                                rel='noreferrer'
                            >
                                {store.isMobileShop &&
                                    <div className={styles.icon_tooltip}
                                        data-testid='with_consulting_info'
                                        onClick={(event: React.MouseEvent) => {
                                            onIconTooltipClick(event, helper.decodeHTML(
                                                localization?.generalL10n.withConsultingInfo));
                                        }}>
                                        <InfoIcon />
                                    </div>}
                                <div className={styles.link_title}>
                                    <span className={styles.link_icon}>
                                        <DesignWithAdviceIcon />
                                    </span>
                                    <span className={styles.link_label}>{helper.decodeHTML(
                                        localization?.generalL10n.withConsulting
                                    )}</span>
                                </div>

                                {(!store.isMobileShop && activeToolTip === 2) && <ToolTip key={2} text={
                                    helper.decodeHTML(
                                        localization?.generalL10n.withConsultingInfo
                                    )}
                                    isMobileShop={store.isMobileShop}
                                    closeToolTip={closeToolTip}
                                />}
                            </a>
                        }
                    </div>
                }
            </>
        );
    })
);

function DataSheetButton(props: IDataSheetButtonProps) {
    return (
        <a
            className={classNames(styles.link_button, styles.datsheet_button)}
            href={props.datasheet.url}
            data-testid='datasheet_button'
            target='_blank'
            rel='noreferrer'
        >
            <div className={styles.link_title}>
                <span className={styles.link_icon}>
                    <DatasheetIcon />
                </span>
                <span className={styles.link_label}>{props.datasheetLabel}</span>
            </div>
        </a>
    );
}

export default ProductDescriptionLinks;
