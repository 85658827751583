import * as React from 'react';
import Cross from '../icons/cross';
import styles from './modalWrapper.scss'

function ModalWrapper(props: IModalWrapper) {

    if (!props.isOpen) {
        return null;
    }

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.modal_container}>
                    <div className={styles.modal_content}>
                        <div className={styles.modal_header}>
                            <div className={styles.modal_title}>
                                <div className={styles.title} dangerouslySetInnerHTML={{ __html: props.title }} />
                                {
                                    props.subtitle &&
                                    <div
                                        className={styles.subtitle}
                                        dangerouslySetInnerHTML={{ __html: props.subtitle }}
                                    />
                                }
                            </div>

                            <div className={styles.close_button} data-testid="close_button_header"
                                onClick={props.onClose} >
                                <Cross />
                            </div>
                        </div>
                        <div className={styles.modal_body}>
                            {props.content}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalWrapper;

interface IModalWrapper {
    isOpen: boolean;
    title: string;
    subtitle?: string;
    content: React.ReactNode;
    onClose();
}
