import React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import * as helper from '../../../helper/html-helper';
import styles from './glovesRecommendation.scss';

export const GlovesRecommendation = inject('store')(
    observer(({ store }: IInjectedProps) => {
        if (!store)
            return null;

        return (
            <>
                <div data-testid='glove_recommendation_shutter' className={styles.glove_recommendation_shutter}>
                    <div data-testid='gr_description' className={styles.gr_description}>
                        <div className={styles.short_description}
                            dangerouslySetInnerHTML=
                            {{ __html: store.localization.generalL10n.glovesRecommendationDescription }} />
                    </div>
                    <div data-testid='recommendation_container' className={styles.recommendation_container}>
                        {
                            store.glovesRecommendation.map((gloveRate, index) => {
                                return (<div data-testid='rate_container' 
                              className={styles.rate_container} key={index}>
                                    <div className={styles.r_info}>
                                        <div className={styles.r_title}>{
                                            helper.decodeHTML(gloveRate.title)
                                        }</div>
                                        <div className={styles.rate}>{gloveRate.rate}</div>
                                    </div>
                                    <div className={styles.r_bar}>
                                        <div className={styles.r_value} 
                                            style={{'width': ''+ gloveRate.rate * 10 +'' + '%'}}></div>
                                    </div>
                                </div>);
                            })
                        }
                    </div>
                </div>
            </>
        );
    }));
