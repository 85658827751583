import React from 'react';
import { IMaterial, IMaterialFiber, IMaterialLayer } from '../../../stores/types';
import styles from './materials.scss';

export default class Materials extends React.Component<
    { materials: IMaterial[], label?: string, grammageLabel?: string,
        portalMandantToken?: string },
    { materials: IMaterial[]}> {

    constructor(props) {
        super(props);

        this.state = {
            materials: this.props.materials
        };
    }

    public update(materials: IMaterial[]): void {
        this.setState({ materials: materials });
    }

    private formatPercentageValue(value: number): string {
        const stringValue = value.toString();

        if(!this.props.portalMandantToken)
            return stringValue;

        if(this.props.portalMandantToken.toLowerCase() !== 'uk') {
            return stringValue.replace('.', ',')
        }

        return stringValue;
    }

    public render() {
        return (
            this.state.materials && this.state.materials.length > 0 
                && this.state.materials[0].layers &&
            <div className={styles.materials} data-testid='materials'>
                <div className={styles.m_title}>{this.props.label}</div>
                {
                    this.state.materials[0].layers.map(
                        (material: IMaterialLayer, index: number) => {
                            return (
                                <div className={styles.material} key={index} data-testid='material'>
                                    <div className={styles.m_name}>{material.name}</div>
                                    {
                                        material.fibers.map((fiber: IMaterialFiber, index: number) => {
                                            return (
                                                <div className={styles.m_fiber} key={index}>
                                                    <span className={styles.f_percentage}>{
                                                            this.formatPercentageValue(fiber.percentage)
                                                        } %</span>
                                                    <span className={styles.f_name}>{fiber.name}</span>
                                                    {material.fibers.length > (index + 1) && 
                                                        <span className={styles.f_divider}>{'/'}</span>}
                                                </div>
                                            );
                                        })
                                    }
                                    {material.grammage && material.grammage > 0 ?
                                        <div className={styles.m_grammage}>{
                                            this.props.grammageLabel?.replace('{0}', material.grammage.toString())
                                        }</div> : null
                                    }
                                </div>
                            );
                        })
                }
                {this.state.materials[0].additionalInformation 
                    && this.state.materials[0].additionalInformation.length > 0 ? 
                    <div className={styles.additionalInformation}>
                        {
                            this.state.materials[0].additionalInformation.map((add: string, index: number)=>{
                                return (
                                    <span className={styles.add_text} key={index}>{add}</span>
                                );
                            })
                        }
                    </div> : null
                }
            </div>
        );
    }
}
