import React from 'react';
import styles from './additionalDescription_empty.scss';

function AdditionalDescriptionEmpty() {
    return (<div data-testid='additional-description-empty'
        className={styles.empty}>
    </div>);
}

export default AdditionalDescriptionEmpty;
