/* eslint-disable react/no-unknown-property */
import { inject, observer } from 'mobx-react';
import React from 'react';
import { IMixedMediaStore } from '../../../types';
import styles from './additionalInfoImage.scss';

export const AdditionalInfoImage = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore)
            return null;

        return (
            <div data-testid='additional_info_image' className={styles.add_info_image_container}>
                <img data-testid='add_info_image' className={styles.add_info_image} 
                    alt={mixedMediaStore.getAltValue(2)}
                    src={mixedMediaStore.getImageForElement3.fullPath} 
                    srcSet={mixedMediaStore.getImageForElement3.srcSet?.urls} fetchpriority="high"/>
            </div>
        );
    })
);
