import React from 'react';
import { IWorkWearStoreStore } from '../../../types/index';
import * as helper from '../../../helper/html-helper';
import styles from './wwsKioskInfo.scss';
import { IVariantsStoreInformation } from '../../../stores/types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

const WWSKioskInfo = inject('workWearStoreStore')(
    observer(({ workWearStoreStore }: IWorkWearStoreStore) => {
        if (!workWearStoreStore)
            return null;

        const retailStore: IVariantsStoreInformation | undefined = workWearStoreStore.wwsData?.variantsStoreInformation.find(
            store => store.retailStoreNo.toString() === workWearStoreStore.mainStore.wwsId
        );
        const showSizes = retailStore?.variants ? retailStore.variants.length > 0 : false;
        /* eslint-disable max-len */
        return (
            <>
                {
                    workWearStoreStore.mainStore.showKioskLayout && (
                    <div className={styles.wws_info_container}>
                        <div className={styles.wws_info}>
                            <span className={styles.wws_circle_red} />
                                <strong>
                                    {helper.decodeHTML(workWearStoreStore.mainStore.localization.wwsOnlyL10n?.notAvailable)}
                            </strong>
                        </div>
                        {retailStore && showSizes && (
                            <div className={styles.wws_info}>
                                <span className={classNames(styles.wws_circle_red, styles.green)} />
                                <strong>
                                        {helper.decodeHTML(
                                            workWearStoreStore.mainStore.localization.wwsOnlyL10n?.wwsLocallyAvailable.replace(
                                            '{0}',
                                            retailStore.retailStoreName
                                        )
                                    )}
                                </strong>
                            </div>
                        )}
                    </div>
                )
            }
        </>
        );
    })
);

export default WWSKioskInfo;
