import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './sizeValue.scss';
import { IVariantStore } from '../../../../types';
import { AvailabilityStatusEnum, ISize, VariantType } from '../../../../stores/types';

export const SizeValue = inject('variantStore')(
    observer(({ variantStore, size, variantKey }: IVariantStore & ISizeValueProps) => {
        if (!variantStore)
            return null;

        const enabledKiosk = variantStore.mainStore.showKioskLayout;

        function handleSelectedSizeChangeClick() {
            // We need this in order to have kiosk selected size update in variant panel
            if (enabledKiosk)
                variantStore?.mainStore.workWearStoreStore.changeSelectedSize(size);
            variantStore?.changeSelectedSize(size);
        }

        function variantAvailabilityState(sizeCode: number): string {
            if (!variantStore)
                return styles.variant_status_red;
           
            const wwsStore = variantStore.mainStore.workWearStoreStore;
            const status = wwsStore.
                variantAvailabilityState(sizeCode, VariantType.Size, variantKey, wwsStore.variantInfoInKiosk);

            let result = '';
            if (!variantKey)
                result = styles.inactive + ' ';
            if (status === AvailabilityStatusEnum.InStock)
                result += styles.variant_status_green;
            else
                result += styles.variant_status_red;

            return result;
        }

        function sizeValueClassNames(): string {
            const classNames:string[] = [styles.size_value];
            if (enabledKiosk) {
                classNames.push(styles.kiosk);
                const stateClass = variantAvailabilityState(size.code);
                classNames.push(stateClass);
                if (variantStore?.mainStore.workWearStoreStore.selectedSize?.size.code == size.code)
                    classNames.push(styles.active);
            }
            else {
                if (variantStore?.selectedSize?.size.code == size.code)
                    classNames.push(styles.active);
                if (!variantKey)
                    classNames.push(styles.sold_out_size);
            }
            return classNames.join(' ');
        }

        return (
            <div data-testid='size_value' data-savkey={variantKey}
                className={sizeValueClassNames()}
                onClick={handleSelectedSizeChangeClick}>
                {size.name}
            </div>
        );
    })
);

export interface ISizeValueProps {
    size: ISize;
    variantKey: string;
}
