import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { IInjectedProps } from '../../types';
import FragmentPlaceholder from '../fragmentPlaceholder/fragmentPlaceholder';

//this component exists because the FragmentPlaceholder was re-rendered on variant change and we want to prevent that

@inject('store')
class MixAndMatchFragmentWrapper extends Component<IInjectedProps> {
    render() {
        // this URL would change with variant change
        const mixAndMatchFragmentUrl =
            '/ESPP2.MixAndMatch.Service/Fragment' +
            '?masterArticleNo=' +
            this.props.store?.fetchedData.orderModule.articleInfo.masterArticleNo +
            '&colorCode=' +
            this.props.store?.mixedMediaStore.colorCode;

        return <FragmentPlaceholder src={mixAndMatchFragmentUrl} ignoreErrors={true} timeout={2} />;

        // if you wonder how we still see a changing MnM shutter at variant change:
        // the MnM fragment itself registers to an AppShell event from us and updates itself
        // (we send it in MainStore->variantChange)
    }

    //client side we can not rerender a FragmentPlaceholder - so we return false here
    shouldComponentUpdate(): boolean {
        return !this.isClientSide();
    }

    isClientSide(): boolean {
        return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    }
}

export default MixAndMatchFragmentWrapper;
