import React from 'react';
import classNames from 'classnames';
import styles from './articlePrice.scss';
import VatSwitcher from '../vatSwitcher/vatSwitcher';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';

function Price({ priceString, isGlobal }: IPrice) {
    return (
        <div role='heading' data-testid='price-string' className={styles.articlePrice_priceString}>
            {
                isGlobal ?
                    <div className={styles.articlePrice_value} data-ge-price>{priceString}</div> :
                    <div className={styles.articlePrice_value}>{priceString}</div>
            }
        </div>
    );
}

interface IPrice {
    priceString: string;
    isGlobal?: boolean;
}

const ArticlePrice = inject('store')(
    observer(({ store, isStickyHeader }: IInjectedProps) => {
        const articlePrice = store?.dataStore.orderModule.articlePrice;
        const variantButtonCount = store?.variantStore.variantButtonCount;
        const basePriceLocale = store?.localization.generalL10n.basePriceLocale;
        const isSale = store?.orderModuleStore.isSale;
        const isGlobal = store?.orderModuleStore.mainStore.isGlobal;
        const showShippingInfo =
            !store?.dataStore.contextData.customerData || store.dataStore.contextData.customerData.postageFree !== 'Yes';

        const priceClassNames = classNames(styles.articlePrice_price, { [styles.articlePrice_price_global]: isGlobal });

        const basicPriceClassNames =
            isSale && !articlePrice?.flagAdvertisedAsCostFreeInSet
                ? [styles.articlePrice_main_price, styles.articlePrice_main_price_sale].join(' ')
                : styles.articlePrice_main_price;

        const containerClasses = classNames({
            [styles.articlePrice_container_sticky]: isStickyHeader,
            [styles.articlePrice_container]: !isStickyHeader,
            [styles.no_variant_buttons]: variantButtonCount === 0,
            [styles.one_variant_button]: variantButtonCount === 1,
            [styles.two_variant_buttons]: variantButtonCount === 2,
        });

        return (
            <div className={containerClasses}>
                <div className={styles.articlePrice_price_container}>
                    <div className={priceClassNames}>
                        {isSale && !articlePrice?.flagAdvertisedAsCostFreeInSet && (
                            <div data-testid='minimal-original-price'>
                                <s>
                                    <Price
                                        priceString={
                                        store?.orderModuleStore.getPriceString(articlePrice?.minimalOriginalPrice)
                                        ?? ''}
                                        isGlobal={isGlobal}
                                    />
                                </s>
                            </div>
                        )}
                        <span className={basicPriceClassNames}>
                            <Price
                                priceString={
                                store?.orderModuleStore.getPriceString(articlePrice?.basicPrice) ?? ''}
                                isGlobal={isGlobal}
                            />
                        </span>
                        {!isGlobal && showShippingInfo && !isStickyHeader && (
                            <div role='shipping-info' className={styles.articlePrice_shipping_info_container}>
                                <div className={styles.articlePrice_shipping_info} data-testid='articlePrice_shipping_info'>
                                    <FragmentPlaceholder
                                        src={'/shippingtext/details-page'}
                                        ignoreErrors={true}
                                        timeout={2}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {!isGlobal ?
                        <div className={styles.articlePrice_vat_container}>
                            <VatSwitcher isStickyHeader={isStickyHeader} showShippingInfo={showShippingInfo} />
                        </div> :
                        <div data-manipulate={store?.fetchedData
                            .orderModule.countryDependentContents.countryDependentContentsAttributeValue}
                            className={styles.articlePrice_total}
                            data-testid='pdp-countryDependentContents'
                        >
                            {store?.fetchedData.orderModule.countryDependentContents.defaultCountryDependentContentsText 
                            && store?.fetchedData.orderModule.
                                countryDependentContents.defaultCountryDependentContentsText.length > 0 
                            ? store?.fetchedData.orderModule.countryDependentContents.defaultCountryDependentContentsText
                            : store?.localization.generalL10n.totalProductPrice }
                        </div>
                    }
                </div>
                {!isStickyHeader && (
                    <>
                        {store?.orderModuleStore.shouldRenderBasePrice && (
                            <div data-testid='base-price' className={styles.articlePrice_base_price}>
                                <div>{`${basePriceLocale}: `}</div>
                                {
                                    isGlobal ?
                                        <div className={styles.articlePrice_base_price_price}
                                             data-ge-price>{store?.orderModuleStore.basePriceString[0]}</div> :
                                        <div className={styles.articlePrice_base_price_price}>
                                            {store?.orderModuleStore.basePriceString[0]}</div>
                                }
                                <div>{`/${store?.orderModuleStore.basePriceString[1]}`}</div>
                            </div>
                        )}
                        {store?.orderModuleStore.shouldRenderInfoTexts && (
                            <>
                                {store?.orderModuleStore.infoTexts.map((info, index) => (
                                    <div className={styles.articlePrice_info_text} key={info[1] + index}>
                                        <div>{info[0]}</div>
                                        {
                                            isGlobal ?
                                                <div className={styles.articlePrice_info_text_price} data-ge-price>
                                                    {info[1]}</div> :
                                                <div className={styles.articlePrice_info_text_price}>{info[1]}</div>
                                        }
                                        {
                                            info[2].length > 0 &&
                                            <>
                                                <div>{info[2]}</div>
                                                {
                                                    isGlobal ?
                                                        <div className={styles.articlePrice_info_text_base_price}
                                                             data-ge-price>{info[3]}</div> :
                                                        <div className={styles.articlePrice_info_text_base_price}>
                                                            {info[3]}</div>
                                                }
                                                <div>{info[4]}</div>
                                            </>
                                        }
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }),
);

export default ArticlePrice;
