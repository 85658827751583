import { observable, makeObservable, computed, action } from 'mobx';
import { UrlHelper } from '../helper/urlHelper';
import { MainStore } from './MainStore';
import { IImage, ImageTypesEnum, IMixedMediaData, LayoutTypesEnum, SalesArticleType } from './types';
import { decodeHTML } from '../helper/html-helper';
import { ICarouselSlide } from '../types';

export class MixedMediaStore {
    public mainStore: MainStore;
    private urlHelper = new UrlHelper();
    // general data:
    public masterArticleNo: number;
    public colorCode: number;
    public masterArticleNoDefault: number;
    public colorCodeDefault: number;
    public altTagForImages: string;
    public salesArticleType: SalesArticleType;
    public showMainImageInFirstPlaceByEsClass: boolean;
    //---

    // main image element (Element 1):
    public isNew: boolean;
    public mainImage: IImage;
    public mainActionImage?: IImage;
    public secondaryImage?: IImage;
    public loadedImagesArray: number[];
    public loadedImagesMobileArray: number[];
    //--

    // 3d view element (Element 2):
    public has3DView: boolean;
    public displayG3dOverlay: boolean;
    public temporaryHideSCS: boolean;
    public fallBackImageUrl: string;
    //--

    // additionalInfoImage element (Element 3):
    public additionalInfoImages?: IImage[];
    //--

    // mediaOverlay element
    public displayMediaOverlay: boolean;
    //--

    public detailedImage?: IImage;
    public shoeSoleImage?: IImage;

    public currentSlideIndex: number;

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        this.currentSlideIndex = 1;
        this.isNew = mainStore.dataStore.mixedMedia?.isNew;
        this.mainImage = mainStore.dataStore.mixedMedia?.image;
        this.mainActionImage = mainStore.dataStore.mixedMedia?.mainActionImage;
        this.secondaryImage = mainStore.dataStore.mixedMedia?.secondaryImage;
        this.altTagForImages = mainStore.dataStore.mixedMedia?.altTagForImages;
        this.salesArticleType = mainStore.dataStore.mixedMedia?.salesArticleType;
        this.showMainImageInFirstPlaceByEsClass = mainStore.dataStore.mixedMedia.showMainImageInFirstPlaceByEsClass;

        this.masterArticleNoDefault = this.masterArticleNo = mainStore.dataStore.mixedMedia?.masterArticleNo;
        this.colorCodeDefault = this.colorCode = mainStore.dataStore.mixedMedia?.colorCode;
        this.has3DView = mainStore.dataStore.mixedMedia?.has3DView;
        this.fallBackImageUrl = this.mainImage.fullPath;
        this.displayG3dOverlay = false;
        this.displayMediaOverlay = false;
        this.temporaryHideSCS = false;

        this.additionalInfoImages = mainStore.dataStore.mixedMedia?.additionalInfoImages;
        this.detailedImage = mainStore.dataStore.mixedMedia?.detailedImage;
        this.shoeSoleImage = mainStore.dataStore.mixedMedia?.shoeSoleImage;
        this.secondaryImage = mainStore.dataStore.mixedMedia.secondaryImage;

        this.generateSourceSets();
        this.loadedImagesArray = [0];
        this.loadedImagesMobileArray = [0];

        makeObservable(this, {
            masterArticleNo: observable,
            colorCode: observable,
            altTagForImages: observable,
            isNew: observable,
            mainImage: observable,
            mainActionImage: observable,
            has3DView: observable,
            displayG3dOverlay: observable,
            displayMediaOverlay: observable,
            temporaryHideSCS: observable,
            additionalInfoImages: observable,
            currentSlideIndex: observable,
            shoeSoleImage: observable,
            detailedImage: observable,
            loadedImagesArray: observable,
            loadedImagesMobileArray: observable,
            shouldRenderAdditionalInfoImage: computed,
            shouldRenderMainImageCarousel: computed,
            getSingleAdditionalInfoImage: computed,
            shouldRenderMainImageInElement2: computed,
            getImageForElement3: computed,
            get1ElementImage: computed,
            getSlidesForCarousel: computed,
            getSlidesForMobileCarousel: computed,
            setMainImageCarouselSlide: action,
            dataUpdate: action,
            handleShowGallery3d: action,
            updateActiveCarouselImageIndex: action,
            updateActiveCarouselImageMobileIndex: action,
            updateMediaOverlayVisibility: action,
            manageSCSFragment: action,
        });
    }

    get shouldRenderAdditionalInfoImage(): boolean {
        return !!this.shoeSoleImage || !!this.detailedImage;
    }

    get getImageForElement3(): IImage {
        if (this.detailedImage) {
            return this.detailedImage;
        }
        if (this.shoeSoleImage) {
            return this.shoeSoleImage;
        }
        return this.mainImage;
    }

    get shouldRenderMainImageCarousel(): boolean {
        if (this.mainStore.isMobileShop) {
            if (
                !!this.mainActionImage ||
                (this.additionalInfoImages && this.additionalInfoImages.length > 0) ||
                !!this.secondaryImage ||
                !!this.detailedImage ||
                !!this.shoeSoleImage
            )
                return true;
        }
        if (this.displayMediaOverlay) {
            if (
                !!this.mainActionImage ||
                !!this.secondaryImage ||
                !!this.detailedImage ||
                !!this.shoeSoleImage ||
                (this.additionalInfoImages && this.additionalInfoImages.length > 0)
            )
                return true;
        }
        if (this.shouldRenderMainImageInElement2) {
            let count = this.additionalInfoImages ? this.additionalInfoImages.length : 0;
            if (this.secondaryImage) {
                count = count + 1;
            }
            if (this.mainActionImage) {
                count = count + 1;
            }
            if (this.detailedImage && this.shoeSoleImage) {
                count = count + 1;
            }
            return count > 1;
        } else {
            return (this.additionalInfoImages && this.additionalInfoImages.length > 0) || !!this.secondaryImage;
        }
    }

    get shouldRenderMainImageInElement2(): boolean {
        if (this.has3DView) {
            return false;
        }
        if (this.mainActionImage) {
            return true;
        }
        if (this.additionalInfoImages && this.additionalInfoImages.length > 0) {
            return true;
        }
        if (this.secondaryImage) {
            return true;
        }
        return false;
    }

    get get1ElementImage() {
        if (this.mainActionImage) {
            return this.mainActionImage;
        }
        if (this.additionalInfoImages && this.additionalInfoImages.length === 1) {
            return this.additionalInfoImages[0];
        }
        if (this.secondaryImage) {
            return this.secondaryImage;
        } else return this.mainImage;
    }

    get shouldRenderMainImageInFirstCarouselPosition() {
        return (
            this.mainStore.isMobileShop &&
            (this.showMainImageInFirstPlaceByEsClass || this.salesArticleType === SalesArticleType.ArticleSet)
        );
    }

    setMainImageCarouselSlide(newSlideIndex: number): void {
        const tmpIndex = newSlideIndex + 1;

        if (this.currentSlideIndex !== tmpIndex) {
            this.currentSlideIndex = tmpIndex;
        }
    }

    dataUpdate(mixedMediaData: IMixedMediaData): void {
        this.isNew = mixedMediaData?.isNew;
        this.mainImage = mixedMediaData?.image;
        this.mainActionImage = mixedMediaData?.mainActionImage;
        this.altTagForImages = mixedMediaData?.altTagForImages;

        this.masterArticleNo = mixedMediaData?.masterArticleNo;
        this.colorCode = mixedMediaData?.colorCode;
        this.has3DView = mixedMediaData?.has3DView;

        this.additionalInfoImages = mixedMediaData?.additionalInfoImages;
        this.shoeSoleImage = mixedMediaData?.shoeSoleImage;
        this.detailedImage = mixedMediaData?.detailedImage;
        this.secondaryImage = mixedMediaData?.secondaryImage;
        this.currentSlideIndex = 1;
        this.generateSourceSets();
    }

    get getSingleAdditionalInfoImage(): IImage | null {
        return this.additionalInfoImages ? this.additionalInfoImages[0] : null;
    }

    getAltValue(index = 0): string {
        if (index > 0) return decodeHTML(this.altTagForImages) + ' ' + index;

        return decodeHTML(this.altTagForImages) ?? '';
    }

    get getSlidesForCarousel() {
        const carouselSlides: ICarouselSlide[] = [];
        let index = carouselSlides.length;
        if (this.shouldRenderMainImageInFirstCarouselPosition) {
            const newIndex = index++;
            carouselSlides.push(this.getSlideObject('contain', this.mainImage, newIndex));
        }
        if (this.mainActionImage) {
            const newIndex = index++;
            carouselSlides.push(this.getSlideObject('cover', this.mainActionImage, newIndex));
        }
        if (this.additionalInfoImages && this.additionalInfoImages.length > 0) {
            this.additionalInfoImages.forEach(addImage => {
                const newIndex = index++;
                carouselSlides.push(this.getSlideObject('cover', addImage, newIndex));
            });
        }
        if (!this.shouldRenderMainImageInFirstCarouselPosition) {
            if (!this.shouldRenderMainImageInElement2 || this.displayMediaOverlay || this.mainStore.isMobileShop) {
                const newIndex = index++;
                carouselSlides.push(this.getSlideObject('contain', this.mainImage, newIndex));
            }
        }
        if (this.secondaryImage) {
            const newIndex = index++;
            carouselSlides.push(this.getSlideObject('contain', this.secondaryImage, newIndex));
        }
        if (!this.displayMediaOverlay && !!this.detailedImage && !!this.shoeSoleImage) {
            const newIndex = index++;
            carouselSlides.push(this.getSlideObject('cover', this.shoeSoleImage, newIndex));
        }
        if (this.displayMediaOverlay && !this.mainStore.isMobileShop) {
            if (this.shoeSoleImage) {
                const newIndex = index++;
                carouselSlides.push(this.getSlideObject('cover', this.shoeSoleImage, newIndex));
            }
            if (this.detailedImage) {
                const newIndex = index++;
                carouselSlides.push(this.getSlideObject('cover', this.detailedImage, newIndex));
            }
        }
        return carouselSlides;
    }

    get getSlidesForMobileCarousel(): ICarouselSlide[] {
        const mobileCarouselSlides = [...this.getSlidesForCarousel];
        let index = mobileCarouselSlides.length;
        if (this.shoeSoleImage && !this.detailedImage) {
                const newIndex = index++;
                mobileCarouselSlides.push(this.getSlideObject('cover', this.shoeSoleImage, newIndex));
        }
        if (this.detailedImage) {
            const newIndex = index++;
            mobileCarouselSlides.push(this.getSlideObject('cover', this.detailedImage, newIndex));
        }
        return mobileCarouselSlides;
    }

    private getSlideObject(className: string, img: IImage, key: number): ICarouselSlide {
        const slide: ICarouselSlide = {
            className: `slide_image ${className}`,
            alt: this.getAltValue(key),
            src: img.fullPath,
            srcSet: img.srcSet.urls,
            slideIndex: key,
        }
        return slide;
    }

    get getLayoutClass(): string {
        let result;
        if (this.isSingleSlotLayout()) result = 'pdp-single_slot_view';
        else if (this.isThreeSlotsLayout()) result = 'pdp-three_slots_view';
        else result = 'pdp-two_slots_view';
        return result;
    }

    shouldEnablePinchZoom() {
        return this.mainStore.isMobileShop && this.displayMediaOverlay;
    }

    isSingleSlotLayout() {
        if (!this.has3DView && !this.shoeSoleImage && !this.detailedImage && !this.shouldRenderMainImageInElement2)
            return true;
        return false;
    }

    isThreeSlotsLayout() {
        if ((this.has3DView || this.shouldRenderMainImageInElement2) && (!!this.shoeSoleImage || !!this.detailedImage))
            return true;
        return false;
    }

    handleShowGallery3d(): void {
        this.displayG3dOverlay = !this.displayG3dOverlay;
    }

    updateActiveCarouselImageIndex(newSlideIndex: number): void {
        if (!this.loadedImagesArray.includes(newSlideIndex)) this.loadedImagesArray.push(newSlideIndex);
    }

    updateActiveCarouselImageMobileIndex(newSlideIndex: number): void {
        if (!this.loadedImagesMobileArray.includes(newSlideIndex)) this.loadedImagesMobileArray.push(newSlideIndex);
    }

    updateMediaOverlayVisibility(isVisible: boolean): void {
        this.displayMediaOverlay = isVisible;
    }

    manageSCSFragment(hidden: boolean): void {
        this.temporaryHideSCS = hidden;
    }

    generateSourceSets() {
        if (this.mainImage)
            this.mainImage = this.generateSourceSetForImage(ImageTypesEnum.MainImage, this.mainImage.fullPath);
        if (this.mainActionImage)
            this.mainActionImage = this.generateSourceSetForImage(
                ImageTypesEnum.MainActionImage,
                this.mainActionImage.fullPath
            );
        if (this.secondaryImage)
            this.secondaryImage = this.generateSourceSetForImage(
                ImageTypesEnum.SecondaryImage,
                this.secondaryImage.fullPath
            );
        if (this.additionalInfoImages && this.additionalInfoImages.length > 0) {
            const addImages: IImage[] = [];
            this.additionalInfoImages.forEach(image => {
                addImages.push(this.generateSourceSetForImage(ImageTypesEnum.AdditionalImages, image.fullPath));
            });
            this.additionalInfoImages = addImages;
        }
        if (this.shoeSoleImage)
            this.shoeSoleImage = this.generateSourceSetForImage(
                ImageTypesEnum.ShoeSoleImage,
                this.shoeSoleImage?.fullPath
            );
        if (this.detailedImage)
            this.detailedImage = this.generateSourceSetForImage(
                ImageTypesEnum.DetailedImage,
                this.detailedImage?.fullPath
            );
    }

    generateSourceSetForImage(imageType: ImageTypesEnum, imageUrl?: string): IImage {
        let layout: LayoutTypesEnum;
        if (this.isSingleSlotLayout()) layout = LayoutTypesEnum.Single_Element;
        else if (this.isThreeSlotsLayout()) layout = LayoutTypesEnum.Three_Elements;
        else layout = LayoutTypesEnum.Two_Elements;

        return this.urlHelper.getSrcSet(imageUrl, layout, imageType);
    }
}
