import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './variantButton.scss';
import classNames from 'classnames';
import { VariantStore } from '../../../stores/VariantStore';
import Cross from '../../icons/cross';
import Diameter from '../../icons/diameter';
import Length from '../../icons/length';
import ArrowSmallIcon from '../../icons/arrowSmallIcon';
import { useViewportWidth } from '../../../helper/viewPortWidth';
import { VariantType } from '../../../stores/types';
export interface IVariantButton {
    variantStore?: VariantStore;
    variantType: VariantType;
    isMobileHeader?: boolean;
    isStickyHeader?: boolean;
    isDeactive?: boolean;
}

export const VariantButton = inject('variantStore')(
    observer(({ variantStore, variantType, isMobileHeader, isStickyHeader, isDeactive }: IVariantButton) => {
        const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };
        const colorButton = () => {
            const imgSrc =
                variantStore?.mainStore.cdnAssetsUrl +
                'colors64px/ColorIcon40/' +
                variantStore?.selectedColor.code.toString() +
                '.png';
            return (
                <>
                    <img
                        role='color-icn'
                        className={styles.colorIcon}
                        src={imgSrc}
                        title={variantStore?.selectedColor.name}
                    />
                    {!isMobileHeader && (
                        <div role='btn-title' className={styles.button_label}>
                            {variantStore?.selectedColor.name}
                        </div>
                    )}
                </>
            );
        };

        const sizeButton = () => {
            return (
                <>
                    <div role='value' className={styles.selected_value}>
                        {variantStore?.selectedSize?.size.name}
                    </div>
                </>
            );
        };

        const specialButton = () => {
            const sizeTypeContent = () => {
                return (
                    <div className={styles.special}>
                        <Diameter />
                        <div className={styles.text}>
                            <div className={styles.value}>{variantStore?.variantSelectionCopy.selectedSizeType}</div>
                        </div>
                    </div>
                );
            };
            const valueContent = () => {
                return (
                    <div className={styles.special}>
                        <Length />
                        <div className={styles.text}>
                            <div className={styles.value}>{variantStore?.variantSelectionCopy.selectedSize?.name}</div>
                        </div>
                    </div>
                );
            };

            const valueClassNames = [styles.selected_value, styles.special_value].join(' ');
            return (
                <>
                    <div role='value' className={valueClassNames}>
                        {sizeTypeContent()}
                        {valueContent()}
                    </div>
                </>
            );
        };

        const modelButton = () => {
            const valueClassNames = [styles.selected_value, styles.model_value].join(' ');
            return (
                <div role='value' className={valueClassNames}>
                    {variantStore?.selectedModel?.label}
                </div>
            );
        };

        const label = (labelText: string, insideButton = false) => {
            if (isStickyHeader) {
                return null;
            }
            const labelClassNames = classNames([styles.variant_title], {
                [styles.variantLabelInsideButton]: insideButton,
            });

            return (
                <div role='var-title' className={labelClassNames}>
                    {labelText}
                </div>
            );
        };

        const onClick = () => {
            if (variantStore?.isVariantPanelOpen && variantStore.variantType === variantType
                && !(variantStore?.mainStore.isMobileShop && isMobileHeader))
                variantStore.changeVariantPanelDisplay(false);
            else variantStore?.changeVariantPanelDisplay(true, variantType, width);
        };

        const content = () => {
            switch (variantType) {
                case VariantType.Color: {
                    const testId = 'variant-color-button';
                    const labelText = `${variantStore?.mainStore.localization.generalL10n.textColor}`;
                    return (
                        <>
                            {label(labelText)}
                            {button(testId)}
                        </>
                    );
                }
                case VariantType.Size: {
                    const testId = 'variant-size-button';
                    const labelText = `${variantStore?.mainStore.localization.generalL10n.textSize}`;
                    return (
                        <>
                            {label(labelText)}
                            {button(testId)}
                        </>
                    );
                }
                case VariantType.Special: {
                    const specialVariantTitle = `${variantStore?.mainStore.localization.generalL10n.textSize}`;
                    const testId = 'variant-special-button';
                    return (
                        <>
                            {label(specialVariantTitle)}
                            {button(testId)}
                        </>
                    );
                }
                case VariantType.Model: {
                    const testId = 'variant-model-button';
                    const labelText = `${variantStore?.mainStore.localization.generalL10n.textModel}`;
                    return (
                        <>
                            {label(labelText)}
                            {button(testId)}
                        </>
                    );
                }
                default:
            }
        };

        const button = (testId: string) => {
            return (
                <div
                    role='button'
                    data-testid={testId}
                    className={classNames(styles.button, {
                        [styles.variant_special_button]: variantType === VariantType.Special,
                    })}
                    onClick={onClick}
                >
                    {buttonContent()}
                    {buttonIcon()}
                </div>
            );
        };

        const buttonContent = () => {
            switch (variantType) {
                case VariantType.Color: {
                    return <>{colorButton()}</>;
                }
                case VariantType.Size: {
                    return <>{sizeButton()}</>;
                }
                case VariantType.Special: {
                    return <>{specialButton()}</>;
                }
                case VariantType.Model: {
                    return <>{modelButton()}</>;
                }
                default:
            }
        };

        const buttonIcon = () => {
            const showCross = variantStore?.variantType === variantType && variantStore.isVariantPanelOpen;
            if (!showCross && isDeactive) {
                return <></>;
            }

            if (showCross) {
                return <Cross />;
            } else {
                return <ArrowSmallIcon />;
            }
        };

        const containerClassNames = (isStickyHeader
            ? [styles.variant_button, styles.isStickyHeader]
            : [styles.variant_button]
        ).join(' ');

        const isOnlyVariantButton = {
            [styles.only_variant_button]: isStickyHeader && variantStore?.variantButtonCount === 1,
        };

        const isColorVariantButton = { [styles.color_variant_button]: variantType === VariantType.Color };

        return (
            <div
                data-testid='variant-button'
                className={classNames(containerClassNames, isOnlyVariantButton, isColorVariantButton, {
                    [styles.deactive]: isDeactive,
                })}
            >
                {content()}
            </div>
        );
    })
);
