import React from 'react';

/* eslint-disable */
const SizeTable = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 23.97266 23.9707">
        <g data-name="Ebene 2">
            <g>
                <path // eslint-disable-next-line max-len
                    d="M23.416,5.166,18.80664.55664A1.89221,1.89221,0,0,0,17.46289,0h-.002a1.88842,1.88842,0,0,0-1.3418.55664L.55664,16.11816a1.90025,1.90025,0,0,0,0,2.6875L5.166,23.416h.001a1.90383,1.90383,0,0,0,2.68653,0L23.416,7.85352a1.90025,1.90025,0,0,0,0-2.6875ZM22.14258,6.58008,6.58008,22.14258a.104.104,0,0,1-.1416,0l-4.6084-4.61035a.099.099,0,0,1,0-.14063l5.39477-5.39429,1.491,1.491a.7002.7002,0,0,0,.99023-.99023l-1.491-1.491L9.71655,9.50562l2.90552,2.90551a.7002.7002,0,0,0,.99023-.99023L10.70679,8.51538l1.502-1.502L13.7002,8.50488a.7002.7002,0,1,0,.99023-.99023L13.1991,6.02332l1.50158-1.50159,2.90577,2.905a.7002.7002,0,1,0,.99023-.99024l-2.90576-2.9049,1.70166-1.70154h.14062l4.60938,4.60937A.099.099,0,0,1,22.14258,6.58008Z" />
                <circle cx="6.65051" cy="17.32178" r="1.50001" />
            </g>
        </g>
    </svg>
);

export default SizeTable;
