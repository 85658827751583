import * as React from 'react';
import { IFragmentPlaceholderProps } from './fragmentPlaceholder.d';
import styles from './fragmentPlaceholder.scss';

function FragmentPlaceholder(props: IFragmentPlaceholderProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function createFragmentPlaceholder(): any {
        const ignoreErrors: string = props.ignoreErrors ? 'true' : 'false';
        const fallback: string = props.fallbackSrc != null ? ` fallback-src="${props.fallbackSrc}"` : '';
        const timeout: string = props.timeout != null && props.timeout > 0 ? ` timeout="${props.timeout}"` : '';

        return { __html: `<!--#fragment src="${props.src}" ignore-errors="${ignoreErrors}"${fallback}${timeout}-->` };
    }
    return (
        <div
            className={styles.fragment_place_holder}
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={createFragmentPlaceholder()}
        />
    );
}

export default FragmentPlaceholder;
