import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Carousel } from 'react-responsive-carousel';

import { IMixedMediaStore } from '../../../../types';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import IconArrowCircleGreyLeft from '../../../icons/icon_arrow_circle_grey_left';
import IconArrowCircleGreyRight from '../../../icons/icon_arrow_circle_grey_right';
import CarouselSlide from './carouselSlide/carouselSlide';

export const MainImageCarousel = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore) return null;

        const [mounted, setMountedState] = useState(false);
        const resetFunction: Function[] = [];
        const mobileSwipeTolerance: number = mixedMediaStore.displayMediaOverlay ? 80 : 30;

        // The useEffect hook is called with an empty dependency array,
        // which means it will only run once when the component is mounted
        useEffect(() => {
            // Do something here
            setMountedState(true);
        }, []);

        if (!mixedMediaStore.shouldRenderMainImageCarousel) {
            return null;
        }

        function handleSetMainImageCarouselSlide(newSlideIndex: number): void {
            if (!mixedMediaStore?.displayMediaOverlay) mixedMediaStore?.setMainImageCarouselSlide(newSlideIndex);

            mixedMediaStore?.updateActiveCarouselImageIndex(newSlideIndex);
        }

        function handleSetMainImageCarouselSlideMobile(newSlideIndex: number): void {
            if (!mixedMediaStore?.displayMediaOverlay) mixedMediaStore?.setMainImageCarouselSlide(newSlideIndex);
            else if (resetFunction.length)
                resetFunction.forEach((reset: Function) => {
                    reset();
                });

            mixedMediaStore?.updateActiveCarouselImageMobileIndex(newSlideIndex);
        }

        function initZoomReset(item: ReactZoomPanPinchRef): void {
            item.instance.setup.panning.disabled = true;
            if (item.resetTransform) resetFunction.push(item.resetTransform);
        }

        const renderArrowPrev = (onClickHandler, hasPrev) =>
            hasPrev && (
                <div data-testid='carousel-arrow-prev' className={'control_arrow a_previous'} onClick={onClickHandler}>
                    <IconArrowCircleGreyLeft />
                </div>
            );

        const renderArrowNext = (onClickHandler, hasPrev) =>
            hasPrev && (
                <div data-testid='carousel-arrow-next' className={'control_arrow a_next'} onClick={onClickHandler}>
                    <IconArrowCircleGreyRight />
                </div>
            );
        
        return (
            <div data-testid='pdp-carousel' className={'pdp_carousel'}>
                <Carousel
                    showIndicators={mounted}
                    key={mixedMediaStore.altTagForImages}
                    showArrows={true}
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={mixedMediaStore.currentSlideIndex - 1}
                    infiniteLoop={true}
                    className='desktop_carousel'
                    renderArrowPrev={renderArrowPrev}
                    renderArrowNext={renderArrowNext}
                    onChange={handleSetMainImageCarouselSlide}
                >
                    {mixedMediaStore.getSlidesForCarousel.map(slide => {
                        return (
                            <React.Fragment key={slide.slideIndex}>
                                <CarouselSlide
                                    className={slide.className}
                                    alt={slide.alt}
                                    src={slide.src}
                                    srcSet={slide.srcSet}
                                    slideIndex={slide.slideIndex}
                                />
                            </React.Fragment>
                        );
                    })}
                </Carousel>
                <Carousel
                    showIndicators={mounted}
                    showArrows={true}
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={0}
                    infiniteLoop={true}
                    swipeScrollTolerance={mobileSwipeTolerance}
                    preventMovementUntilSwipeScrollTolerance={true}
                    className='mobile_carousel'
                    renderArrowPrev={renderArrowPrev}
                    renderArrowNext={renderArrowNext}
                    onChange={handleSetMainImageCarouselSlideMobile}
                >
                    {mixedMediaStore.getSlidesForMobileCarousel.map((slide, index) => {
                        if (!mixedMediaStore.displayMediaOverlay) {
                            if (
                                mixedMediaStore.loadedImagesMobileArray.includes(
                                    parseInt(slide.slideIndex?.toString() ?? '')
                                )
                            )
                                return (
                                    <React.Fragment key={slide.slideIndex}>
                                        <CarouselSlide
                                            className={slide.className}
                                            alt={slide.alt}
                                            src={slide.src}
                                            srcSet={slide.srcSet}
                                            slideIndex={slide.slideIndex}
                                        />
                                    </React.Fragment>
                                );
                            else return <React.Fragment key={slide.slideIndex} />;
                        } else {
                            return (
                                <TransformWrapper centerZoomedOut={true} onInit={initZoomReset.bind(this)} key={index}>
                                    <TransformComponent>
                                        <CarouselSlide
                                            className={slide.className}
                                            alt={slide.alt}
                                            src={slide.src}
                                            srcSet={slide.srcSet}
                                            slideIndex={slide.slideIndex}
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            );
                        }
                    })}
                </Carousel>
                <div data-testid='current_slide_status' className={'current_slide_status'}>
                    <span data-testid='mi_current_slide' className={'current_slide'}>
                        {String('0' + mixedMediaStore.currentSlideIndex).slice(-2)}
                    </span>
                    <span className={'status_divider'}>/</span>
                    <span data-testid='mi_total_slides' className={'total_slides'}>
                        {mixedMediaStore.getSlidesForCarousel.length.toLocaleString(
                            mixedMediaStore.mainStore.dataStore.contextData.culture,
                            { minimumIntegerDigits: 2, useGrouping: false }
                        )}
                    </span>
                </div>
            </div>
        );
    })
);

export default MainImageCarousel;
