import React, { useEffect, useState, FunctionComponent, Children } from 'react';
import styles from '../../../styles/customSwipeable.scss';
import IconArrowSmallLeft from '../../icons/icon_arrow_small_left';
import IconArrowSmallRight from '../../icons/icon_arrow_small_right';
import { VariantStore } from '../../../stores/VariantStore';
import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper/core';

export interface ICustomSwiper {
    variantStore?: VariantStore;
    type: string;
    selectedElementIndex: number;
    // If sliderPreColumn are more than 1(default), columnPreView have to have some value but not 'auto'(default)
    direction?: 'horizontal' | 'vertical';
    columnsPerView?: number;
    slidesPerColumn?: number;
    spaceBetween?: number;
    mousewheel?: boolean;
    enabled: boolean;
    isWWS?: boolean;
}

SwiperCore.use([Mousewheel, Navigation]);
export const CustomSwiper: FunctionComponent<ICustomSwiper> = (props: React.PropsWithChildren<ICustomSwiper>) => {
    const useSwiperRef = (): [HTMLDivElement | null, React.LegacyRef<HTMLDivElement> | undefined] => {
        const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);
        const ref = React.createRef<HTMLDivElement>();

        useEffect(() => {
            if (ref.current)
                setWrapper(ref.current);
        }, []);

        return [wrapper, ref];
    };
    const [prevEl, leftArrowRef] = useSwiperRef();
    const [nextEl, rightArrowRef] = useSwiperRef();
    const direction = props.direction ?? 'horizontal';
    const slidersPerView = props.columnsPerView ?? 'auto';
    const slidersPerColumn = props.slidesPerColumn ?? 1;
    const spaceBetween = props.spaceBetween ?? 0;
    const childrenArray = Children.toArray(props.children);
    return (
        <>
            <div
                className={classNames(styles.left_arrow, {
                    [styles.hidden]: !props.enabled,
                    [styles.vertical]: direction === 'vertical',
                    [styles.wws]: props.isWWS,
                })}
                ref={leftArrowRef}
            >
                <IconArrowSmallLeft />
            </div>
            {props.enabled ? (
                <Swiper
                    className={classNames(styles.swiper, {
                        [styles.one_row]: slidersPerColumn === 1,
                        [styles.wws]: props.isWWS,
                    })}
                    direction={direction}
                    slidesPerView={slidersPerView}
                    slidesPerColumn={slidersPerColumn}
                    spaceBetween={spaceBetween}
                    mousewheel={props.mousewheel}
                    navigation={{
                        prevEl,
                        nextEl,
                    }}
                    initialSlide={props.selectedElementIndex}
                >
                    {childrenArray.map((child, index) => {
                        return <SwiperSlide key={index}>{child}</SwiperSlide>;
                    })}
                </Swiper>
            ) : (
                <>{props.children}</>
            )}

            <div
                className={classNames(styles.right_arrow, {
                    [styles.hidden]: !props.enabled,
                    [styles.vertical]: direction === 'vertical',
                    [styles.wws]: props.isWWS,
                })}
                ref={rightArrowRef}
            >
                <IconArrowSmallRight />
            </div>
        </>
    );
};
