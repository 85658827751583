export function decodeHTML(value?: string): string | undefined {
    if (value)
        return value.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    else
        return value;
}

export function isIOS(): boolean {
    if (typeof document !== 'undefined') return document.getElementsByTagName('html')[0].classList.contains('isIOS');
    else return false;
}

export function formCdnUrl(assetsUrl: string, cdnAssetsUrl: string): string {
    const startIndex = assetsUrl.indexOf('/documents/');
    let assetLocation = 'wf/images_preview/TwoColsRightFullscreen';

    if (cdnAssetsUrl.indexOf('preview') < 0) {
        assetLocation = 'wf/images/TwoColsRightFullscreen';
    }

    const tmpCdnAssetsUrl = cdnAssetsUrl.replace('/pdp', '');

    return `${tmpCdnAssetsUrl}${assetLocation}${assetsUrl.substring(startIndex)}`;
}

export function decodeRtfText(text?: string) {
    if (text) {
        const txt = document.createElement('textarea');
        txt.innerHTML = text;
        return txt.value;
    } else
        return text;
}

export function getTranslateUrl(): string | null {
    const translatePageUrls = document.getElementById('translated-page-urls');
    if (translatePageUrls && translatePageUrls.textContent)
        return translatePageUrls.textContent.trim();
    else
        return null;
}

export function setTranslateUrl(translatedPageUrls: string) {
    const translatePageUrls = document.getElementById('translated-page-urls');
    if (translatePageUrls)
        translatePageUrls.textContent = translatedPageUrls;
}

/* eslint-disable */
export const throttle = (fn: Function, wait = 500, extraArgs?: Array<any>) => {
    let inThrottle: boolean,
        lastFn: ReturnType<typeof setTimeout>,
        lastTime: number;

    return function (this: any) {
        const context = this;
        const args = Array.from(arguments);
        args.push(extraArgs);

        if (!inThrottle) {
            fn.apply(context, args);
            lastTime = Date.now();
            inThrottle = true;
        } else {
            clearTimeout(lastFn);
            lastFn = setTimeout(() => {
                if (Date.now() - lastTime >= wait) {
                    fn.apply(context, args);
                    lastTime = Date.now();
                }
            }, Math.max(wait - (Date.now() - lastTime), 0));
        }
    };
};
