import React from 'react';
import classNames from 'classnames';
import styles from './articleNumber.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';

function ArticleInfo({ info }: { info: string }) {
    if (!info) {
        return null;
    }

    return <span className={styles.article_info}>{info}</span>;
}

const ArticleNumber = inject('store')(
    observer(({ store, isStickyHeader }: IInjectedProps) => {
        const titleAddon = store?.orderModuleStore.titleAddon ?? '';
        const variantButtonCount = store?.variantStore.variantButtonCount ?? 0;
        const title = store?.dataStore.orderModule.articleInfo.title;

        const containerClasses = classNames({
            [styles.article_number_container_sticky]: isStickyHeader,
            [styles.article_number_container]: !isStickyHeader,
            [styles.no_variant_buttons]: variantButtonCount === 0,
            [styles.no_variant_margin]: variantButtonCount === 0 && titleAddon,            
            [styles.has_variant_buttons]: variantButtonCount > 0,
        });

        return (
            <div className={containerClasses}>
                {
                    !isStickyHeader &&
                        <div className={styles.article_number}>
                            #{store?.dataStore.orderModule.articleInfo.salesArticleNo}
                        </div>
                }
                <div role='heading' className={styles.article_title}>
                    {!isStickyHeader && <h1 className={styles.article_designation}>{title}</h1> } 
                    {isStickyHeader && <span className={styles.article_designation}>{title}</span> }
                    <ArticleInfo info={titleAddon} />
                </div>
            </div>
        );
    })
);

export default ArticleNumber;
