import React from 'react';

/* eslint-disable */
const mixMatch = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.35393" height="28.59961" viewBox="0 0 23.35393 28.59961">
        <path d="M21.56149,28.59961H18.0156a1.7928,1.7928,0,0,1-1.78125-1.58887l-.80664-5.1123-.81153,5.14648a1.78312,1.78312,0,0,1-1.77637,1.55469h-3.54a1.79051,1.79051,0,0,1-1.7793-2.00977l1.09668-8.91308a.79993.79993,0,0,1,1.58789.19531L9.10837,26.78418A.19308.19308,0,0,0,9.29978,27h3.54a.1932.1932,0,0,0,.19141-.1709l1.60547-10.18945a.79988.79988,0,0,1,1.58008,0l1.60254,10.15527A.20616.20616,0,0,0,18.0156,27h3.54589a.18823.18823,0,0,0,.14356-.06445.18394.18394,0,0,0,.04687-.14942L19.83981,10.47949a.19138.19138,0,0,0-.19043-.16992h-2.8623a.79981.79981,0,0,1,0-1.59961h2.8623a1.79288,1.79288,0,0,1,1.78028,1.584l1.91211,16.30566a1.79071,1.79071,0,0,1-1.78028,2Z" /><path d="M12.0195,14.77832H5.33005a1.79547,1.79547,0,0,1-1.793-1.793V7.23242A1.78717,1.78717,0,0,1,1.33493,6.46L.23923,4.56348A1.79479,1.79479,0,0,1,.8945,2.11621L4.06052.28613A2.09815,2.09815,0,0,1,5.11813,0h.7295A1.48817,1.48817,0,0,1,7.08688.65234,1.91073,1.91073,0,0,0,8.67575,1.501,1.91375,1.91375,0,0,0,10.2656.65234,1.48817,1.48817,0,0,1,11.50485,0h.72754a2.09646,2.09646,0,0,1,1.10352.31445l3.14551,1.81739a1.78741,1.78741,0,0,1,.65429,2.44433L16.04392,6.46875a1.7939,1.7939,0,0,1-2.23145.76074c.00293.3877.00293.84473.00293,1.38379v4.3711A1.79692,1.79692,0,0,1,12.0195,14.77832ZM4.33688,5.06641a.79878.79878,0,0,1,.79981.7998v7.11914a.1938.1938,0,0,0,.19336.19336H12.0195a.19526.19526,0,0,0,.19629-.19433c0-2.10547,0-6.84376-.001-7.10157a.75656.75656,0,0,1,.02735-.22265.79841.79841,0,0,1,.77246-.59375c.32715,0,.52051,0,.63476.24218l.749.43067a.1922.1922,0,0,0,.25879-.06934L15.75,3.77637a.213.213,0,0,0-.06934-.25977l-3.165-1.8291a.52625.52625,0,0,0-.28321-.08789h-.67676a3.50984,3.50984,0,0,1-2.87988,1.501,3.50543,3.50543,0,0,1-2.8789-1.501H5.11813a.49289.49289,0,0,0-.25292.06934L1.69528,3.501a.193.193,0,0,0-.07031.26269l1.0957,1.89746A.18442.18442,0,0,0,2.834,5.749a.19261.19261,0,0,0,.14355-.01855l.958-.55664A.80559.80559,0,0,1,4.33688,5.06641Zm1.51075-3.4668h0Z" />
    </svg>
);

export default mixMatch;
