import React from 'react';

/* eslint-disable */
const IconArrowRight = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.8"
        height="19.801"
        viewBox="0 0 9.8 19.801"
    >
        <path
            fill="#1d1d1b"
            d="M.9 19.8a.9.9 0 01-.673-1.497L7.696 9.9.227 1.498A.9.9 0 111.573.303l8 9a.9.9 0 010 1.195l-8 9a.9.9 0 01-.673.303z"
        ></path>
    </svg>
);

export default IconArrowRight;
