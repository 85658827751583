import React from 'react';
import CrossRing from '../icons/crossRing';
import styles from './toolTip.scss';

export default class ToolTip extends React.Component<{text?: string, 
    isMobileShop: boolean, closeToolTip}, {}> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className={styles.tt_overlay_background}>
                <div className={styles.tt_container}>
                    {this.props.isMobileShop && <div className={styles.close_tt_btn}
                        onClick={() => { this.props.closeToolTip();
                        }}
                        > <CrossRing /> </div>}
                    <div className={styles.tt_inner_triangle}></div>
                    <div className={styles.tt_outer_triangle}></div>
                    <div className={styles.tt_content}>
                        <div className={styles.close_btn}></div>
                        <div className={styles.tt_text} 
                        dangerouslySetInnerHTML={{ __html: this.props.text ? this.props.text : '' }}/>
                    </div>
                </div>
            </div>
        );
    }
}
