import React from 'react';
import { ISpecificationEntry } from '../../../stores/types';
import styles from './details.scss';

export default class Details extends React.Component<IDetails, {details: ISpecificationEntry[]}> {
    constructor(props) {
        super(props);

        this.state = {
            details: this.props.details
        }
    }
    
    public updateSpecs(newDetails: ISpecificationEntry[]): void {
        this.setState({ details: newDetails });
    }

    public render() {
        return (
            <div>
                <table className={styles.spec_table}>
                    <tbody>
                        {this.state.details.map(spec => {
                            return (
                                <tr key={spec.name}>
                                    <td className={styles.spec_name}>{spec.name}</td>
                                    <td className={styles.spec_value}>
                                        {spec.orderedValues.map(value => {
                                            return <div key={value}>{value}</div>;
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

interface IDetails {
    details: ISpecificationEntry[];
}
