import React from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
import styles from './contentTabAAS.scss';
import '../../../styles/customFragmentsStyles.scss';
import ArrowIcon from '../../icons/arrow_down';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';
import { AASFragmentHelper } from '../../../helper/aasFragmentHelper';
import { ILocalization } from '../../../types';

export default class ContentTabAAS extends React.Component<
    {
        savKey: string;
        activateAASShutter;
        handleContentClose;
        tabKey: string;
        closeOverlayBtnTitle?: string;
        aasFragmentHelper: AASFragmentHelper;
        isMobile: boolean;
        localization: ILocalization;
        navKeyPath: string;
    },
    { savKey: string; aasInitialized: boolean }
> {
    constructor(props) {
        super(props);
        this.state = {
            savKey: this.props.savKey,
            aasInitialized: false,
        };
    }

    componentDidUpdate(prevProps) {
        window.shell.once('AAS.updateFinished', payload => {
            payload && this.props.isMobile && this.injectCloseButton();
        });

        if (prevProps.savKey !== this.props.savKey) {
            if(this.aasIsAvailable()) {
                this.props.aasFragmentHelper.updateAAS(prevProps.savKey, this.props.savKey, this.props.isMobile);
                
                this.props.activateAASShutter(true);
            }
        }
    }

    componentDidMount() {
        window.shell.once('AAS.updateFinished', payload => {
            if (payload)
                // ping parent state:
                this.props.activateAASShutter(this.aasIsAvailable());
        });

        if (this.aasIsAvailable()) {
            window.addEventListener('DOMContentLoaded', () => {
                // load full aas fragment:
                this.props.aasFragmentHelper.updateAAS(this.props.savKey, this.props.savKey, this.props.isMobile);
            });

            this.props.activateAASShutter(true);
        } else {
            // disable AAS if it does not have alternative for current article
            this.props.activateAASShutter(false);
        }

        if (typeof window !== 'undefined' && window.shell) {
            window.shell.subscribeTo('AAS.updateError', () => {
                this.props.activateAASShutter(false);
            }, 'AAS.updateError');
        }
    }

    private aasIsAvailable(): boolean {
        // check AAS availability:
        const aasScope = document.querySelector('.scope-aas');
        return aasScope ? true : false;
    }

    private injectCloseButton() {
        const assContent = document.querySelector('.aas-content');
        if (assContent) {
            const closeButtonContainer = document.createElement('div');
            closeButtonContainer.className = styles.alternative_articles_modal_footer;
            assContent.appendChild(closeButtonContainer);
            ReactDOM.render(
                <div
                    className={classNames(
                        styles.aas_modal_close_button, //our styling (position)
                        'aas-modal-close-button' //default aas styling
                    )}
                    onClick={this.props.handleContentClose}
                    data-tabkey={this.props.tabKey}
                >
                    {this.props.localization.generalL10n.buttonClose}
                </div>,
                closeButtonContainer
            );
        } else {
            // eslint-disable-next-line no-console
            console.log('could not inject AAS close button');
        }
    }

    public render() {
        return (
            <>
                {this.props.isMobile && (
                    <div
                        className={styles.aas_modal_header}
                        onClick={this.props.handleContentClose}
                        data-tabkey={this.props.tabKey}
                        data-testid='pa_content_aas_tab'
                    >
                        <button className={styles.aas_back_button}>
                            <ArrowIcon />
                        </button>
                        <span className={styles.aas_modal_title}>{this.props.closeOverlayBtnTitle}</span>
                    </div>
                )}
                <div className={styles.aas_content}>
                    <FragmentPlaceholder src='ESPP2.AlternativeArticles.Service/Assets' ignoreErrors={true} />
                    <FragmentPlaceholder
                        // eslint-disable-next-line max-len
                        src={`ESPP2.AlternativeArticles.Service/AlternativeArticles/articles/${this.state.savKey}/available-alternatives?content=full&detailsCanBeHidden=true&showHeader=false&itemorigin=${this.props.navKeyPath}`}
                        ignoreErrors={true}
                    />
                </div>
            </>
        );
    }
}
