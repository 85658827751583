import React from 'react';
/* eslint-disable max-len */
const AASLogo = () => (
    <svg
        data-name='aas-find-alternative'
        xmlns='http://www.w3.org/2000/svg'
        width='81.92657'
        height='92.94922'
        viewBox='0 0 81.92657 92.94922'
    >
        <path d='M79.96387,34.47363,65.17993,22.06836l.4187-1.15039a5.49734,5.49734,0,0,0-3.28711-7.04883L41.01367,6.11719V5.5a5.50622,5.50622,0,0,0-5.5-5.5H5.5A5.50622,5.50622,0,0,0,0,5.5V87.44922a5.50622,5.50622,0,0,0,5.5,5.5H35.51367a5.48573,5.48573,0,0,0,4.17163-1.92554c.01294-.0144,40.95728-48.802,40.95728-48.802A5.50577,5.50577,0,0,0,79.96387,34.47363ZM61.28613,16.6875a2.50191,2.50191,0,0,1,1.49414,3.20508L41.01367,79.69629V9.30884ZM5.5,89.94922a2.50232,2.50232,0,0,1-2.5-2.5V5.5A2.50231,2.50231,0,0,1,5.5,3H35.51367a2.50231,2.50231,0,0,1,2.5,2.5V87.44922a2.50232,2.50232,0,0,1-2.5,2.5ZM78.34375,40.294,43.38049,81.96228,64.088,25.06848l13.94812,11.704a2.50015,2.50015,0,0,1,.30762,3.52149Z' />
        <path d='M20.50684,65.50293a7.5,7.5,0,1,0,7.5,7.5A7.5082,7.5082,0,0,0,20.50684,65.50293Zm0,12a4.5,4.5,0,1,1,4.5-4.5A4.50491,4.50491,0,0,1,20.50684,77.50293Z' />
    </svg>
);

export default AASLogo;
