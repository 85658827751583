import React from 'react';

/* eslint-disable */
function WorkwearstoreIcon() {
    return (
        <svg
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            width='22.77148'
            height='17.79968'
            viewBox='0 0 22.77148 17.79968'
        >
            <g>
                <g>
                    <g>
                        <path d='M17.00977,11.5116H14.89844V7.52527a.70019.70019,0,0,0-.7002-.7002h-5.625a.70018.70018,0,0,0-.70019.7002V11.5116H5.76172a.70018.70018,0,0,0-.7002.70019v4.6875a.70019.70019,0,0,0,.7002.7002h11.248a.70018.70018,0,0,0,.70019-.7002v-4.6875A.70018.70018,0,0,0,17.00977,11.5116ZM9.27344,8.22546h4.22461V11.5116H9.27344ZM6.46191,12.912h4.22364V16.1991H6.46191Zm9.84766,3.28711H12.08594V12.912h4.22363Z' />
                        <path d='M21.68555,4.85925,12.249.20105a1.95183,1.95183,0,0,0-1.72558,0L1.085,4.86414A1.937,1.937,0,0,0,0,6.61023V16.89929a.90039.90039,0,0,0,1.80078,0V6.61023a.1503.1503,0,0,1,.082-.13281l9.43848-4.66309a.15346.15346,0,0,1,.13086,0l9.43555,4.6582a.147.147,0,0,1,.083.13379v10.293a.90039.90039,0,0,0,1.80078,0V6.60632A1.93652,1.93652,0,0,0,21.68555,4.85925Z' />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default WorkwearstoreIcon;
