import React, { useEffect, useState } from 'react';
import styles from './shutterModul.scss';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import ArrowDownDesktopShutters from '../icons/arrow_down_desktop_shutters';
import ArrowDownMobileShutters from '../icons/arrow_down_mobile_shutters';

function ShutterModul(props: IShutterModulProp) {
    const [showContent, setShowContent] = useState(props.isOpen);
    const idString = 'shutter-' + props.title;
    useEffect(() => {
        if (showContent && props.onIsOpenChange) {
            props.onIsOpenChange(!showContent)
        }
    });

    return (
        <div className={classNames(styles.shutter_modul_wrapper,
        {[styles.not_visible]: props.notVisible})}>
            {!props.showPersonalizationBanner &&
                <hr />
            }
            <div className={styles.shutter_modul} data-testid={idString}>
                <div role='heading' className={classNames(styles.shutter_header, { [styles.open]: showContent })}
                    onClick={() => {
                        setShowContent(!showContent);
                        if (props.onIsOpenChange)
                            props.onIsOpenChange(!showContent)
                    }}>
                    <div className={styles.shutter_title}>{props.title}</div>
                    {
                        props.icon &&
                        <div className={styles.shutter_icon} data-testid={'shutter-icon'}>
                            {props.icon}
                        </div>
                    }
                    {
                        props.isMobileShop ? (<ArrowDownMobileShutters />) : (<ArrowDownDesktopShutters />)
                    }
                </div>
                <Collapse theme={{ collapse: styles.collapse }} isOpened={showContent}>
                    <div role='content' className={styles.shutter_content}>
                        {props.children}
                    </div>
                </Collapse>
            </div>
        </div >
    );
}

interface IShutterModulProp {
    title?: string;
    isOpen: boolean;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    children?: any;
    icon?: React.ReactNode;
    onIsOpenChange?(open: boolean);
    isMobileShop?: boolean;
    notVisible?: boolean;
    showPersonalizationBanner?;
}

export default ShutterModul;
