import React, { useEffect, useRef } from 'react';
import { ILocalization } from '../../../types';
import styles from './workwearStore.scss';
import defaultStyles from '../../../styles/defaults.scss';
import classNames from 'classnames';
import { IVariantsStoreInformation, VariantType, AvailabilityStatusEnum } from '../../../stores/types';
import * as helper from '../../../helper/html-helper';
import ExpanderIconOpenSmall from '../../icons/expanderIconOpenSmall';

import { useViewportWidth } from '../../../helper/viewPortWidth';
import WorkWearStoreStore from '../../../stores/WorkWearStoreStore';
import WorkwearStoreColors from './workwearStoreColors/workwearStoreColors';
import WorkwearStoreSizes from './workwearStoreSizes/workwearStoreSizes';
import WorkwearStoreModels from './workwearStoreModels/workwearStoreModels';

const breakpointMobileMinWidth = parseInt(defaultStyles.breakpointMobileMinWidth) - 1;

function WorkwearStore(props: IWorkwearStore) {
    const { localization, variantStoreInformation, status, isCollapsed, expandCollapse, workWearStoreStore } = props;
    const collapseWrapperRef = useRef<HTMLDivElement>(null);
    const [collapseWrapperHeight, setCollapseWrapperHeight] = React.useState(0);

    const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };

    const swiperDirection = width < breakpointMobileMinWidth ? 'horizontal' : 'vertical';
    const showColors = workWearStoreStore.availableColors.length > 0;
    const haveColor = variantStoreInformation ?
        variantStoreInformation.variants.filter(item => item.sizeCode !== 0).length > 0 : false;
    const haveSize = workWearStoreStore.selectedSizeType?.sizes ?
        workWearStoreStore.selectedSizeType.sizes.length > 1 : false;
    const showSizes = haveColor || haveSize;
    const showModels = workWearStoreStore.availableModels.length > 1;
    const isAnotherStoreOpen =
        workWearStoreStore.openedStore && workWearStoreStore.openedStore !== variantStoreInformation?.retailStoreName;

    function variantAvailabilityStateClass(variantCode: number, variantType: VariantType, savKey: string): string[] {
        if (variantStoreInformation) {
            const status =
                workWearStoreStore.variantAvailabilityState(variantCode, variantType, savKey, variantStoreInformation);
            if (status === AvailabilityStatusEnum.InStock) return [styles.variant_status_green];
            if (status === AvailabilityStatusEnum.CurrentlyOutOfStock) return [styles.variant_status_orange];
        }
        return [styles.variant_status_red];
    }

    useEffect(() => {
        if (collapseWrapperRef.current) {
            setCollapseWrapperHeight(collapseWrapperRef.current.getBoundingClientRect().height);
        }
    });

    let availabilityLabel: string | undefined = undefined;
    if (status === 0) {
        availabilityLabel = localization.wwsOnlyL10n?.currentlyAvailable;
    } else if (status === 1) {
        availabilityLabel = localization.wwsOnlyL10n?.currentlyNotAvailable;
    } else if (status > 1) {
        availabilityLabel = localization.wwsOnlyL10n?.notAvailable;
    }

    return (
        <div
            data-testid='workwear_store'
            className={classNames(styles.workwear_store, {
                [styles.collapsed]: isCollapsed,
                [styles.another_store_open]: isAnotherStoreOpen,
            })}
        >
            <div data-testid='wws-header' className={styles.wws_header} onClick={expandCollapse}>
                <div className={styles.wws_title}>
                    <span
                        className={classNames(
                            styles.availability_circle_red,
                            {
                                [styles.green]: status === 0,
                            },
                            {
                                [styles.yellow]: status === 1,
                            }
                        )}
                    />
                    <p className={styles.wws_name}>
                        <strong>
                            {variantStoreInformation?.retailStoreName.substr(
                                variantStoreInformation.retailStoreName.indexOf(' ') + 1
                            )}
                        </strong>
                        <label className={styles.wws_name_label}>{helper.decodeHTML(availabilityLabel)} *</label>
                    </p>
                </div>

                {!workWearStoreStore.noVariants && (
                    <div
                        data-testid='arrow_button'
                        className={classNames(styles.arrow_button, {
                            [styles.collapsed]: isCollapsed,
                        })}
                    >
                        <div role='btn-arrow_button_label' className={styles.arrow_button_label}>
                            {localization.wwsOnlyL10n?.checkAllVariants}
                        </div>
                        <ExpanderIconOpenSmall />
                    </div>
                )}
            </div>
            <div className={styles.content_wrapper} style={{ height: isCollapsed ? 0 : collapseWrapperHeight }}>
                <div ref={collapseWrapperRef} className={styles.collapse_wrapper}>
                    <div className={styles.color_size_content_wrapper}>
                        <WorkwearStoreColors
                            workWearStoreStore={workWearStoreStore}
                            showSizes={showSizes}
                            showColors={showColors}
                            swiperDirection={swiperDirection}
                            width={width}
                        />
                        <WorkwearStoreSizes
                            workWearStoreStore={workWearStoreStore}
                            variantAvailabilityState={variantAvailabilityStateClass}
                            showSizes={showSizes}
                            width={width}
                        />
                    </div>
                    <WorkwearStoreModels
                        workWearStoreStore={workWearStoreStore}
                        variantAvailabilityState={variantAvailabilityStateClass}
                        showModels={showModels}
                        swiperDirection={swiperDirection}
                        width={width}
                    />
                </div>
            </div>
        </div>
    );
}

interface IWorkwearStore {
    localization: ILocalization;
    variantStoreInformation?: IVariantsStoreInformation;
    status: number;
    isCollapsed: boolean;

    expandCollapse?();

    workWearStoreStore: WorkWearStoreStore;
}

export default WorkwearStore;
