import React from 'react';
/* eslint-disable max-len */
const LogoServiceIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24.79929 21.03125'>
        <path d='M17.23715,21.03125h-9.71a2.38062,2.38062,0,0,1-2.377-2.37793V9.8457l-.02539.01465a2.37712,2.37712,0,0,1-3.24511-.87109L.31723,6.28418a2.38232,2.38232,0,0,1,.87011-3.248L5.78109.38184A2.78751,2.78751,0,0,1,7.1893,0H8.84066a.90041.90041,0,0,1,.80469.49805,3.042,3.042,0,0,0,2.73731,1.6914A3.04159,3.04159,0,0,0,15.119.49805.90042.90042,0,0,1,15.92367,0h1.64844a2.789,2.789,0,0,1,1.4707.41992l4.56739,2.6377a2.37591,2.37591,0,0,1,.86914,3.24707L22.91781,9.01074a2.379,2.379,0,0,1-3.248.86914l-.05567-.03222v8.80566A2.38061,2.38061,0,0,1,17.23715,21.03125ZM6.05063,7.3916A.90247.90247,0,0,1,6.951,8.292V18.65332a.57681.57681,0,0,0,.57617.57715h9.71a.57681.57681,0,0,0,.57617-.57715V8.292A.89977.89977,0,0,1,19.162,7.51172l1.40625.80859a.58.58,0,0,0,.791-.21L22.92074,5.4043a.57755.57755,0,0,0-.21-.78809L18.119,1.96387a1.01976,1.01976,0,0,0-.54687-.16309H16.4432a4.82821,4.82821,0,0,1-4.06054,2.18945A4.82993,4.82993,0,0,1,8.32113,1.80078H7.1893a.98558.98558,0,0,0-.502.13574L2.08773,4.59473a.57959.57959,0,0,0-.21093.79l1.56152,2.7041a.57557.57557,0,0,0,.35059.26953.5675.5675,0,0,0,.4375-.05762l1.376-.78906A.89714.89714,0,0,1,6.05063,7.3916Z' />
        <path d='M14.493,12.38965H10.27133a.70019.70019,0,0,1-.7002-.7002V7.46777a.70018.70018,0,0,1,.7002-.70019H14.493a.70017.70017,0,0,1,.70019.70019v4.22168A.70018.70018,0,0,1,14.493,12.38965Zm-3.52149-1.40039h2.82129V8.168H10.97152Z' />
        <path d='M14.493,15.65234H10.27133a.7002.7002,0,1,1,0-1.40039H14.493a.7002.7002,0,0,1,0,1.40039Z' />
    </svg>
);

export default LogoServiceIcon;
