import * as React from 'react';
import styles from './articleSetItem.scss';
import { IArticleSetItem } from '../../../stores/types';
import * as helper from '../../../helper/html-helper';
import { ILocalization } from '../../../types';

function ArticleSetItem(props: IInjectedProps) {
    const detailButtonTestId = `article_sets_details_button_${props.key}`;
    return (
        <div data-testid='article_set_item' key={props.key} className={styles.article_set_item}>
            <div>
                {
                    props.articleSetItem.flagAdvertisedAsCostFreeInSet &&
                    <div data-testid='gratis_article_set_item' className={styles.gratis}>
                        + {helper.decodeHTML(props.localization?.generalL10n.gratis)}
                    </div>
                }
                <div data-testid='article_set_item_quanity_and_salesDesignation'>
                    {props.articleSetItem.quantity}x {props.articleSetItem.salesDesignation}
                </div>
                {
                    !props.articleSetItem.flagAdvertisedAsCostFreeInSet &&
                    <div data-testid='article_set_item_color_size_info'
                        className={styles.color_size_info}>
                        {props.variantLabel}
                    </div>
                }
            </div>
            {
                props.showDetailsButton && props.articleSetItem.isSellableSeparately &&
                <button
                    className={styles.details_button}
                        data-testid={detailButtonTestId}
                    onClick={props.handleDetailsButtonClick}>
                    {helper.decodeHTML(props.localization?.generalL10n.details)}
                </button>
            }
        </div>
    );
}

export default ArticleSetItem;

export interface IInjectedProps {
    key: number;
    articleSetItem: IArticleSetItem;
    localization?: ILocalization;
    showDetailsButton: boolean;
    variantLabel: string;
    handleDetailsButtonClick?();
}
