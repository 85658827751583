import React from 'react';
import styles from './additionalDescription_buttonLink.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import IconDownload from '../../icons/icon_download';
import { IAdditionalDescriptionLink } from '../../../stores/types';
import * as helper from '../../../helper/html-helper';

function AdditionalDescriptionButtonLink(props: IAdditionalDescriptionLink) {
    function buttonText(text?: string) {
        return (
            <div className={styles.button_text}>
                <div className={styles.text}>{text}</div>
                {props.icon === 'additional-description-arrow-right' && <IconArrowExtraSmallRight />}
                {props.icon === 'additional-description-arrow-down' && <IconDownload />}
            </div>
        );
    }
    return props.isDownloadable ? (
        <a className={styles.button_link} download href={props.target}>
            {buttonText(helper.decodeHTML(props.l10n.generalL10n.pdfDownload))}
        </a>
    ) : (
            <a className={styles.button_link}
                href={props.target}
                target={'_blank'} rel={'noreferrer'}>
            {buttonText(props.linkText)}
        </a>
    );
}

export default AdditionalDescriptionButtonLink;
