import React from 'react';
import styles from './salesFlag.scss';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import { decodeHTML } from '../../../helper/html-helper';

export const SalesFlag = inject('store')(
    observer(({ store }: IInjectedProps) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = store!.localization;
        const price = store?.dataStore.orderModule.articlePrice;
        const isSetPrice = store?.mixedMediaStore.salesArticleType === 2;
        const { rrp, sale, setPrice } = localization.generalL10n;

        if (price?.hasRecommendedRetailPrice && price.priceDeduction) {
            return (
                <div data-testid='rrp-flag' className={styles.info_flag}>
                    <span className={styles.label}>{decodeHTML(rrp)}</span>
                    <span className={styles.percentage}>{price.priceDeduction.toFixed(0)}%</span>
                </div>
            );
        } else if (
            !price?.hasRecommendedRetailPrice &&
            !price?.flagAdvertisedAsCostFreeInSet &&
            price?.priceDeduction
        ) {
            return (
                <div data-testid='sales-flag' className={styles.info_flag}>
                    {!store?.dataStore.contextData.featureToggles.enableRedesignCategoryPageAndSearchPage ? (
                        <span className={styles.label}>{decodeHTML(sale)}</span>
                    ) : (
                        <span className={styles.label}>
                            {decodeHTML(!isSetPrice ? sale : setPrice)}
                        </span>
                    )}
                    <span className={styles.percentage}>{price.priceDeduction.toFixed(0)}%</span>
                </div>
            );
        } else if (price?.flagAdvertisedAsCostFreeInSet) {
            return (
                <div data-testid='sales-flag' className={styles.info_flag}>
                    <span className={styles.label}>{decodeHTML(sale)}</span>
                </div>
            );
        } else {
            return null;
        }
    })
);
