import React from 'react';

/* eslint-disable */
const FunctionalLayerWeather = () => (
    <svg id="Wetter_Icon" data-name="Wetter Icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <path fill="#1d1d1b"
            id="Pfad_17" data-name="Pfad 17" d="M401.948,328.427a13,13,0,1,1,13-13A13.014,13.014,0,0,1,401.948,328.427Zm0-24.347a11.347,11.347,0,1,0,11.341,11.348A11.357,11.357,0,0,0,401.948,304.08Z" transform="translate(-388.949 -302.427)" />
        <path fill="#00569d"
            id="Pfad_18" data-name="Pfad 18" d="M401.319,323.5h-.028a8.729,8.729,0,1,1,.027-17.458h.029a8.757,8.757,0,0,1,8.739,8.756,8.746,8.746,0,0,1-8.766,8.7Zm0-15.687a6.958,6.958,0,1,0-.024,13.916h0a6.958,6.958,0,1,0,.044-13.916Z" transform="translate(-388.293 -301.77)" />
        <path id="Pfad_19" data-name="Pfad 19" d="M400.621,318.565h-.015a4.439,4.439,0,0,1-4.43-4.445,4.456,4.456,0,0,1,4.446-4.445h.015a4.445,4.445,0,0,1-.016,8.89Zm0-7.235a2.794,2.794,0,0,0-1.98,4.762,2.762,2.762,0,0,0,1.965.819h0a2.79,2.79,0,0,0,.02-5.581Z" transform="translate(-387.607 -301.12)" />
    </svg>
);

export default FunctionalLayerWeather;
