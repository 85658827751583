import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import WorkWearStoreStore from '../../../../stores/WorkWearStoreStore';
import { ISize, VariantType } from '../../../../stores/types';
import styles from '../workwearStore.scss';
import { CustomSwiper } from '../../../variantPanel/customeSwiper/customeSwiper';

function WorkwearStoreSizes(props: IWorkwearStoreSizes) {
    const { workWearStoreStore, showSizes, width, variantAvailabilityState } = props;

    if (!showSizes) {
        return null;
    }

    const selectedSizeElementRef = useRef<HTMLInputElement>(null);
    const sizeWrapperRef = useRef<HTMLDivElement>(null);

    const handleSizeDimensionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.currentTarget.dataset.dimension) {
            workWearStoreStore.changeSelectedSizeType(event.currentTarget.dataset.dimension);
        }
    };

    function handleSelectedSizeChangeClick(size: ISize) {
        workWearStoreStore.changeSelectedSize(size);
    }

    function calcColumnsAndMaxChildWidth(maxChildWidth: number): { maxChildWidth: number; column: number } {
        const result = { maxChildWidth, column: 1 };
        if (result.maxChildWidth <= 50) result.maxChildWidth = 42;

        if (result.maxChildWidth > 100 && result.maxChildWidth <= 160) result.column = 2;
        else if (result.maxChildWidth <= 100 && result.maxChildWidth > 80) result.column = 3;
        else if (result.maxChildWidth <= 80 && result.maxChildWidth > 42) result.column = 4;
        else if (result.maxChildWidth <= 42) {
            result.column = 4;
            result.maxChildWidth = 42;
            // ESPP-4849, for small view, based on view width to adjust column number
            if (width >= 620 && width < 769) result.column = 5;
            else if (width >= 470 && width < 620) result.column = 6;
            else if (width >= 375 && width < 470) result.column = 5;
        }

        const sizeWrapperClientWidth = sizeWrapperRef.current ? sizeWrapperRef.current.clientWidth : 0;
        while (result.column * result.maxChildWidth >= sizeWrapperClientWidth - 5) {
            if (result.column > 1) result.column -= 1;
            else break;
        }

        return result;
    }

    useEffect(() => {
        if (sizeWrapperRef.current) {
            const wrapperChildren = Array.from(sizeWrapperRef.current.children as HTMLCollectionOf<HTMLElement>);

            // reset width just incase
            wrapperChildren.forEach(element => {
                element.style.width = 'auto';
            });

            let initialMaxChildWidth = 0;
            wrapperChildren.forEach(element => {
                if (element.clientWidth > initialMaxChildWidth) initialMaxChildWidth = element.offsetWidth;
            });

            const { maxChildWidth, column } = calcColumnsAndMaxChildWidth(initialMaxChildWidth);

            wrapperChildren.forEach(element => {
                element.style.width = maxChildWidth + 'px';
            });
            sizeWrapperRef.current.setAttribute('style', `grid-template-columns: repeat(${column}, 1fr);`);
        }
    });

    let selectedSizeIndex;
    if (workWearStoreStore.selectedSizeType?.sizes instanceof Array) {
        selectedSizeIndex = workWearStoreStore.selectedSizeType.sizes.findIndex(size => {
            return size.size.code === workWearStoreStore.selectedSize?.size.code;
        });
    }

    return (
        <div className={styles.wws_size_dimensions_wrapper}>
            {workWearStoreStore.availableSizeTypes.length > 1 && (
                <div data-testid='size_dimensions' className={styles.size_dimensions}>
                    {workWearStoreStore.availableSizeTypes.map(sizeType => (
                        <div
                            role='size_dimension'
                            key={sizeType.sizeTypeName}
                            className={classNames(styles.size_dimension, {
                                [styles.active]:
                                    sizeType.sizeTypeName == workWearStoreStore.selectedSizeType?.sizeTypeName,
                            })}
                            data-dimension={sizeType.sizeTypeName}
                            onClick={handleSizeDimensionClick}
                        >
                            <div className={styles.selected_size_dimension_label}>{sizeType.sizeTypeName}</div>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.size_icons}>
                <CustomSwiper type='wws-size' selectedElementIndex={selectedSizeIndex} enabled={false} isWWS={true}>
                    {
                        <div ref={sizeWrapperRef} data-testid='size_values' className={styles.size_values}>
                            {workWearStoreStore.selectedSizeType?.sizes.map(size => (
                                <div
                                    ref={selectedSizeElementRef}
                                    data-testid='size_value'
                                    key={size.size.name}
                                    className={classNames(
                                        variantAvailabilityState(size.size.code,
                                            VariantType.Size, size.salesArticleVariantKey),
                                        styles.size_value,
                                        { [styles.active]: workWearStoreStore.selectedSize?.size.code == size.size.code, }
                                    )}
                                    onClick={() => handleSelectedSizeChangeClick(size.size)}
                                >
                                    {size.size.name}
                                </div>
                            ))}
                        </div>
                    }
                </CustomSwiper>
            </div>
        </div>
    );
}

interface IWorkwearStoreSizes {
    workWearStoreStore: WorkWearStoreStore;
    variantAvailabilityState(variantNumber: number, variantType: VariantType, savKey: string): string[];
    showSizes: boolean;
    width: number;
}

export default WorkwearStoreSizes;
