import React from 'react';

/* eslint-disable */
const Apply = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='21.29395' height='14.20801' viewBox='0 0 21.29395 14.20801'>
        <g data-name='Ebene 2'>
            <g data-name='Header &amp; Footer'>
                <path d='M21.03027.26367a.90061.90061,0,0,0-1.27343,0L7.9873,12.03418,1.53711,5.584A.90046.90046,0,0,0,.26367,6.85742l7.08692,7.08692a.90061.90061,0,0,0,1.27343,0L21.03027,1.53711A.9006.9006,0,0,0,21.03027.26367Z' />
            </g>
        </g>
    </svg>
);

export default Apply;
