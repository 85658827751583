import React from 'react';
import { observer, inject } from 'mobx-react';
import { IInjectedProps } from '../../types';
import styles from './detailsPageLayout.scss';
import StickyHeader from '../stickyHeader/stickyHeader';
import { MediaOverlay } from '../mediaOverlay/mediaOverlay';
import { MixedMedia } from '../mixedMedia/mixedMedia';
import { OrderModule } from '../orderModule/orderModule';
import ShutterModul from '../shutterModul/shutterModul';
import { VariantPanel } from '../variantPanel/variantPanel';
import { ProductInfo } from '../productInfo/productInfo';
import AdditionalDescription from '../additionalDescription/additionalDescription';
import { useViewportWidth } from '../../helper/viewPortWidth';
import { GlovesRecommendation } from '../shutterModul/glovesRecommandation/glovesRecommendation';
import ArticleNumber from '../orderModule/articleNumber/articleNumber';
import ArticlePrice from '../orderModule/articlePrice/articlePrice';
import BasketPanel from '../orderModule/basketPanel/basketPanel';
import VariantButtonList from '../variantPanel/variantButtonList/variantButtonList';
import { PurchaseAdvice } from '../shutterModul/purchaseAdvice/purchaseAdvice';
import FragmentPlaceholder from '../fragmentPlaceholder/fragmentPlaceholder';
import BannerModule from '../bannerModule/bannerModule';
import MixAndMatchShutterWrapper from '../mixAndMatch/mixAndMatchShutterWrapper';
import * as helper from '../../helper/html-helper';
import { useMixedMediaContentWidth } from '../../helper/detailsPageContentWidth';
import classNames from 'classnames';
import ProductFeature from '../shutterModul/productFeature/productFeature';
import { PersonalizeOverlay } from '../personalizeOverlay/personalizeOverlay';
import { PersonalizeInfoOverlay } from '../personalizeOverlay/personalizeInfoOverlay';
import { IProductFeaturesGroup } from '../../stores/types';
import DebugBar from '../debugBar/debugBar';
import LayoutedContentGrid from '../shutterModul/layoutedContentGrid/layoutedContentGrid';
import { ProductInfoArticleSets } from '../productInfoArticleSets/productInfoArticleSets';
import PersonalizationBanner from '../personalizationBanner/personalizationBanner';

const DetailsPageLayout = inject('store')(
    observer(({ store }: IInjectedProps) => {
        if (!store)
            return null;

        const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };
        const { contentWidth } = typeof document !== 'undefined' ? useMixedMediaContentWidth() : { contentWidth: 1024 };
        const hasAdditionalDescription = store?.fetchedData.shutterModule.additionalDescriptions &&
            store.fetchedData.shutterModule.additionalDescriptions.length > 0;
        const initialLayoutedContentGridState = store?.fetchedData.shutterModule.layoutedContentGridKeys &&
            store.fetchedData.shutterModule.layoutedContentGridKeys.length > 0;
        const { variantButtonCount } = store?.variantStore;
        const logoCreatorUrl = store.showKioskLayout ? `/ESPP1-LogoCreator?wwsId=${store.wwsId}` : '/ESPP1-LogoCreator';
        const showPersonalizationBanner =
            store?.dataStore.contextData.featureToggles.enablePersonalizationBannerOnDetailsPage &&
            store?.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable;
        const [hasLayoutedContentGrid, setLayoutedContentGridState] =
            React.useState(initialLayoutedContentGridState);

        const updateLayoutedContentGridState = (isAvailable: boolean) => {
            setLayoutedContentGridState(isAvailable);
        }

        return (
            <>
                <div
                    className={styles.details_page_content}
                    style={{
                        right: store.variantStore.isVariantPanelOpen ? store.getRightPosition(width, contentWidth) : 0,
                    }}
                >
                    <div className={styles.hidden_fragment}>
                        <FragmentPlaceholder src={'/layoutedcontentgrid-rmu/layoutedcontentgrid-fragment/assets'}
                            ignoreErrors={true} timeout={2} />
                    </div>
                    {store.isEsApp && (
                        <div className={styles.hidden_fragment}>
                            <FragmentPlaceholder src={'/shippingtext'} ignoreErrors={true} timeout={2} />
                        </div>
                    )}
                    <div
                        className={classNames(styles.background_container, {
                            [styles.dark_background]:
                                store.orderModuleStore.compareShareBookmarkMobileExpanded,
                        })}
                    />
                    {
                        !store.dataStore.contextData.isEsApp &&
                        <div
                            className={classNames(styles.dp_breadcrumb_fragment_container)}
                        >
                            {
                                // eslint-disable-next-line max-len
                                <FragmentPlaceholder src={`/ESPP.Breadcrumb/breadcrumb?pageType=Detail&seoSlug=${store.dataStore.breadcrumb.category.seoSlug}&containerId=dp_main_breadCrumb`}
                                    ignoreErrors={true}
                                    timeout={2}
                                />
                            }
                        </div>
                    }
                    <div className={styles.details_page_container}>
                        {!store.dataStore.contextData.isRelease && <DebugBar />}
                        <MediaOverlay />
                        <MixedMedia />
                        <OrderModule />
                    </div>
                    <div className={styles.dp_bottom_container}>
                        <StickyHeader
                            onHeightChange={store.orderModuleStore.setStickHeaderHeight}
                            isEsApp={store.isEsApp}
                            variantButtonCount={variantButtonCount}
                            top={store.stickyHeaderTop}
                        >
                            <div>
                                <ArticleNumber isStickyHeader />
                                {store.variantStore && <VariantButtonList isStickyHeader />}
                            </div>
                            <div>
                                <ArticlePrice isStickyHeader />
                                <BasketPanel isStickyHeader />
                            </div>
                        </StickyHeader>

                        {showPersonalizationBanner &&
                            <PersonalizationBanner mainStore={store} />
                        }

                        {!store.fetchedData.shutterModule.productInfo.disableProductInfoShutter && (
                            <ShutterModul
                                title={helper.decodeHTML(store.localization.generalL10n.productInfo)}
                                isOpen={true}
                                isMobileShop={store.isMobileShop}
                                showPersonalizationBanner={showPersonalizationBanner}
                            >
                                {store?.dataStore.contextData.featureToggles.enableArticleSetInformationInPDP &&
                                 store.dataStore.shutterModule.productInfo.description.articleSetItemDescriptions &&
                                 store.dataStore.shutterModule.productInfo.description.articleSetItemDescriptions.length > 0
                                    ? (
                                        <ProductInfoArticleSets />

                                    ) : (
                                        <ProductInfo />
                                    )}
                            </ShutterModul>
                        )}
                        <ShutterModul
                            title={helper.decodeHTML(store.localization.generalL10n.additionalDescription)}
                            isOpen={true}
                            isMobileShop={store.isMobileShop}
                            notVisible={!hasAdditionalDescription && !hasLayoutedContentGrid}
                        >
                            {hasAdditionalDescription &&
                                (store.fetchedData.shutterModule.additionalDescriptions.map((value, index) => {
                                    return (
                                        <AdditionalDescription
                                            key={index}
                                            rows={value.rows}
                                            l10n={store.localization}
                                            has3DView={value.has3DView}
                                            threeDContainerId={store.additionalDescription3DContainerId + '_' + index}
                                            fallBackImageUrl={store.mixedMediaStore.fallBackImageUrl}
                                            debuggerIsOn={store.debuggerIsOn}
                                            documentKey={value.documentKey}
                                            isRelease={store.dataStore.contextData.isRelease}
                                        />
                                    );
                                }))}
                            <LayoutedContentGrid
                                debuggerIsOn={store.debuggerIsOn}
                                layoutedContentGridKeys={store?.fetchedData.shutterModule.layoutedContentGridKeys}
                                isRelease={store.dataStore.contextData.isRelease}
                                updateLayoutedContentGridState={updateLayoutedContentGridState} 
                                colorCode={store.dataStore.mixedMedia?.colorCode} />
                        </ShutterModul>
                        {store.hasGlovesRecommendation && (
                            <ShutterModul
                                title={helper.decodeHTML(
                                    store.localization.generalL10n.glovesRecommendationShutterTitle
                                )}
                                isOpen={true}
                                isMobileShop={store.isMobileShop}
                            >
                                <GlovesRecommendation />
                            </ShutterModul>
                        )}
                        {store.fetchedData.shutterModule.productFeaturesGroups && (
                            store.fetchedData.shutterModule.productFeaturesGroups
                                .map((featuresGroup: IProductFeaturesGroup, index: number) => {
                                    const tmpIndex = index + 1;
                                    return (<ShutterModul
                                        key={index}
                                        isOpen={true}
                                        title={featuresGroup.groupName && featuresGroup.groupName !== 'DEFAULT' ?
                                            featuresGroup.groupName :
                                            helper.decodeHTML(store.localization.redOnlyL10n?.productFeature)}
                                        isMobileShop={store.isMobileShop}
                                    >
                                        <ProductFeature featuresGroup={featuresGroup}
                                            masterArticleNo={store.dataStore.mixedMedia?.masterArticleNo}
                                            colorCode={store.dataStore.mixedMedia?.colorCode}
                                            groupIndex={tmpIndex}
                                            isMobileShop={store.isMobileShop} />
                                    </ShutterModul>)
                                })
                        )}
                        <ShutterModul
                            isOpen={true}
                            title={helper.decodeHTML(store.localization.redOnlyL10n?.purchaseAdvice)}
                            isMobileShop={store.isMobileShop}
                            notVisible={!store.isPurchaseAdviceAvailable}
                        >
                            <PurchaseAdvice />
                        </ShutterModul>
                        <MixAndMatchShutterWrapper />
                        <BannerModule />
                    </div>
                </div>
                <VariantPanel />
                <FragmentPlaceholder src='/ESPP1-BasketModal?containerId=dp_basket_modal' ignoreErrors={true} />
                <FragmentPlaceholder src={'/ESPP1-Showcase/assetsView'}
                    ignoreErrors={true}
                    timeout={2} />

                {store?.dataStore.contextData.featureToggles.enableLogoCreator &&
                    <>
                        <FragmentPlaceholder src={logoCreatorUrl} ignoreErrors={true}
                            timeout={2} />
                        <PersonalizeOverlay />
                        <PersonalizeInfoOverlay />
                    </>
                }
            </>
        );
    })
);

export default DetailsPageLayout;
