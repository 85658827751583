import React, { useEffect } from 'react';
import styles from './bannerModule.scss';
import { useViewportWidth } from '../../helper/viewPortWidth';
import defaultStyles from '../../styles/defaults.scss';
import OnlineCatalog from './onlineCatalog/onlineCatalog';
import GlovesBanner from './glovesBanner/glovesBanner';
import ShoesBanner from './shoesBanner/shoesBanner';
import LogoServiceBanner from './logoServiceBanner/logoServiceBanner';

function BannerModule() {
    const { width } = typeof window !== 'undefined' ? useViewportWidth() : { width: 1024 };
    const minDeskTopWidth = parseInt(defaultStyles.breakpointMobileMinWidth);
    const isDesktop = width > minDeskTopWidth;

    useEffect(() => {
        const bannerWrapper = document.getElementById('banner_modul_wrapper');

        if (bannerWrapper) {
            const bannerWrapperChildren = Array.from(bannerWrapper.children as HTMLCollectionOf<HTMLElement>);

            if (isDesktop) {
                if (bannerWrapperChildren.length == 1) bannerWrapperChildren[0].style.margin = '50px auto';
                if (bannerWrapperChildren.length == 3) bannerWrapperChildren[2].style.margin = 'auto';
            }

            if(bannerWrapperChildren.length < 1) {
                const banner_modul = document.getElementById('banner_modul');

                if(banner_modul) {
                    banner_modul.classList.add(styles.no_border);
                }
            }
        }
    });

    return (
        <div id='banner_modul' data-testid='banner_modul' className={styles.banner_modul}>
            <div id='banner_modul_wrapper' data-testid='banner_modul_wrapper' className={styles.banner_modul_wrapper}>
                <OnlineCatalog />
                <GlovesBanner />
                <ShoesBanner />
                <LogoServiceBanner />
            </div>
        </div>
    );
}

export default BannerModule;
