import React from 'react';
import styles from './orderModule.scss';
import ArticleNumber from './articleNumber/articleNumber';
import ArticlePrice from './articlePrice/articlePrice';
import DeliveryTime from './deliveryTime/deliveryTime';
import StoreAvailability from './storeAvailability/storeAvailability';
import { SalesTeaser } from './salesTeaser/salesTeaser';
import BasketPanel from './basketPanel/basketPanel';
import CompareShareBookmark from './compareShareBookmark/compareShareBookmark';
import { IInjectedProps } from '../../types';
import VariantButtonList from '../variantPanel/variantButtonList/variantButtonList';
import { inject, observer } from 'mobx-react';
import { SalesFlag } from './salesFlag/salesFlag';
import FragmentPlaceholder from '../fragmentPlaceholder/fragmentPlaceholder';
import CompareShareBookmarkMobile from './compareShareBookmarkMobile/compareShareBookmarkMobile';
import classNames from 'classnames';

export const OrderModule = inject('store')(
    observer(({ store }: IInjectedProps) => {
        if (!store) return null;

        const variantSelection = store.dataStore.variantSelection;
        const shouldShowStoreAvailability =
            (store.dataStore.contextData.portal.includes('DE') && !store.showKioskLayout) ||
            (store.showKioskLayout && store.variantStore.showAvailableStatusInKiosk);

        return (
            <div className={styles.order_module_container}>
                {store.isMobileShop && <CompareShareBookmarkMobile />}
                {
                    !store.dataStore.contextData.isEsApp &&
                    <div
                        className={classNames(styles.om_breadcrumb_fragment_container)}
                    >
                        {
                            // eslint-disable-next-line max-len
                                <FragmentPlaceholder src={`/ESPP.Breadcrumb/breadcrumb?pageType=Detail&seoSlug=${store.dataStore.breadcrumb.category.seoSlug}&containerId=dp_orderModule_breadCrumb`} ignoreErrors={true} timeout={2} />
                        }
                    </div>
                }
                <div className={styles.first_item}>
                    <ArticleNumber />
                </div>
                <SalesFlag />
                <ArticlePrice />
                <div className={styles.order_module_availability_container}>
                    <DeliveryTime />
                    {shouldShowStoreAvailability && <StoreAvailability />}
                </div>
                {variantSelection && <VariantButtonList />}
                <BasketPanel />
                <SalesTeaser />
                {!store.isMobileShop && <CompareShareBookmark />}
            </div>
        );
    })
);
