import React from 'react';
import { IShoeWeight } from '../../../stores/types';
import styles from './shoeWeights.scss';

export default class ShoeWeights extends React.Component<
    { shoeWeights: IShoeWeight[], approxWeightLbl?: string, gramsInSizeLbl?: string },
    { shoeWeights: IShoeWeight[], }> {

    constructor(props) {
        super(props);

        this.state = {
            shoeWeights: this.props.shoeWeights,
        };
    }

    public update(shoeWeights: IShoeWeight[]): void {
        this.setState({ shoeWeights: shoeWeights });
    }

    public render() {
        return (
            this.state.shoeWeights &&
            <div className={styles.shoeWeights} data-testid='shoeWeights'>
                {
                    this.state.shoeWeights.map((shoeWeight: IShoeWeight, index: number) => {
                        return (
                            <div className={styles.shoeWeight} key={index} data-testid='shoeWeight'>
                                <span className={styles.sw_property}>{this.props.approxWeightLbl}</span>
                                <span className={styles.sw_property}>{shoeWeight.weight}</span>
                                <span className={styles.sw_property}>{this.props.gramsInSizeLbl}</span>
                                <span className={styles.sw_property}>{shoeWeight.sizeLabelCode}</span>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
