import React from 'react';
/* eslint-disable max-len */
const SizeLongMan = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='65' viewBox='0 0 30 65'>
        <path d='M14.97388,13.061a5.25049,5.25049,0,1,0-5.25-5.25A5.256,5.256,0,0,0,14.97388,13.061Zm0-8.7002a3.44971,3.44971,0,1,1-3.4502,3.4502A3.45381,3.45381,0,0,1,14.97388,4.36084Z' />
        <path d='M23.95728,35.83643c-.0044-.40137-.01514-1.60743-.02832-3.18555-.03614-4.16016-.09571-11.124-.13233-12.37109a7.08534,7.08534,0,0,0-.73047-3.626A4.4439,4.4439,0,0,0,20.29663,14.479a5.68668,5.68668,0,0,0-2.8457.2334c-.12989.03516-.248.06641-.34912.09082a12.07585,12.07585,0,0,1-2.103.2461,14.02164,14.02164,0,0,1-2.09912-.2461c-.10254-.02441-.2207-.05566-.35059-.09082a5.67237,5.67237,0,0,0-2.84472-.2334,4.44324,4.44324,0,0,0-2.77051,2.17481,7.08534,7.08534,0,0,0-.73047,3.626c-.03662,1.24707-.09619,8.21093-.13233,12.37109-.01318,1.57812-.02392,2.78418-.02832,3.18555l-.00048.02832V35.894A2.76888,2.76888,0,0,0,9.739,38.47314L9.407,59.19385a3.244,3.244,0,0,0,5.59229,2.24023,3.24546,3.24546,0,0,0,5.59424-2.26367l-.332-20.69727a2.76833,2.76833,0,0,0,3.69629-2.60839Zm-2.76807.99609a.96942.96942,0,0,1-.96924-.96777q0-.02492-.00146-.04981l-.231-14.4082a.89993.89993,0,0,0-.89942-.88574l-.01513.001a.89985.89985,0,0,0-.88526.91407l.60547,37.75781a1.44678,1.44678,0,0,1-2.89355,0V37.73291a.89991.89991,0,1,0-1.79981,0V59.19385a1.44678,1.44678,0,0,1-2.89355.00586l.606-37.76367A.89985.89985,0,0,0,10.927,20.522l-.01513-.001a.89993.89993,0,0,0-.89942.88574l-.231,14.40137c-.001.01855-.00146.03808-.00146.05664a.969.969,0,0,1-1.938.01171c.00488-.38281.01514-1.60449.0293-3.21.03564-4.15332.09521-11.10546.13086-12.334a5.428,5.428,0,0,1,.52246-2.83691,2.64019,2.64019,0,0,1,1.6543-1.28028,4.43657,4.43657,0,0,1,1.90429.23535c.14942.04.28565.07715.40479.1045a12.77348,12.77348,0,0,0,2.52246.29492,13.97255,13.97255,0,0,0,2.50293-.29492c.11767-.02735.2539-.06446.40332-.1045a4.44006,4.44006,0,0,1,1.90527-.23535,2.6408,2.6408,0,0,1,1.65332,1.28028,5.428,5.428,0,0,1,.52246,2.83691c.03565,1.22852.09522,8.18066.13086,12.334.01416,1.60547.02442,2.82715.0293,3.21A.96975.96975,0,0,1,21.18921,36.83252Z' />
    </svg>
);

export default SizeLongMan;
