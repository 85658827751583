import React from 'react';
import styles from './productDescription.scss';
import classNames from 'classnames';

export default class ProductDescription extends React.Component<IDescriptionProps, IDescriptionState> {
    constructor(props) {
        super(props);

        this.state = {
            description: this.props.description,
            functionalLayersExists: this.props.functionalLayersExists,
            disclaimersExists: this.props.disclaimersExists
        };

        this.update = this.update.bind(this);
    }
    
    public update(description: string, 
        functionalLayersExists: boolean,
        disclaimersExists: boolean): void {
        this.setState({ 
            description: description,
            functionalLayersExists: functionalLayersExists,
            disclaimersExists: disclaimersExists
        });
    }

    public render() {
        return (
            <div>
                <div
                    className={classNames(styles.description, {
                        [styles.dividing_line]: this.state.functionalLayersExists 
                            || this.state.disclaimersExists,
                    })}
                    data-testid='product_info_description'
                    dangerouslySetInnerHTML={{ __html: this.state.description }}
                />
            </div>
        );
    }
}

interface IDescriptionProps {
    description: string;
    functionalLayersExists: boolean;
    disclaimersExists: boolean;
}

interface IDescriptionState {
    description: string;
    functionalLayersExists: boolean;
    disclaimersExists: boolean;
}
