import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as helper from '../../../helper/html-helper';
import styles from '../productInfo.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import { MainStore } from '../../../stores/MainStore';

const ProductAdditionalLayout = inject('store')(
    observer((props: IProductAdditionalLayout) => {
        const {
            store,
            renderAdditionalLayout,
            productInfoContentHeight,
            productContentRef,
            showContent,
            setShowContent,
            isArticleSet,
        } = props;

        function handleExpandClick() {
            const currentState = !showContent;
            if (productContentRef.current) {
                const content = productContentRef.current.firstChild as HTMLElement;
                if (content) {

                    const expandedHeight = isArticleSet ? content.scrollHeight : (productInfoContentHeight + 300);
                    content.style.maxHeight = currentState ? expandedHeight + 'px' : '511px';

                    currentState
                        ? productContentRef.current.classList.add('pdp-expand_content')
                        : setTimeout(() => {
                            productContentRef.current?.classList.remove('pdp-expand_content');
                        }, 600);

                    currentState
                        ? setTimeout(() => {
                            productContentRef.current?.classList.add('pdp-removed_overflow')}, 
                            700)
                        : productContentRef.current.classList.remove('pdp-removed_overflow');
                    
                    currentState
                        ? content.style.marginBottom = '24px'
                        : setTimeout(() => {content.style.marginBottom = '0'}, 200);
                }
            }

            setShowContent(!showContent);
        }

        if (!renderAdditionalLayout) {
            return null;
        }

        return (
            <div
                data-testid={'product-additional-layout'}
                className={classNames(styles.wrapper_product_info_separator, { [styles.open]: showContent })}
            >
                <div
                    role='button'
                    className={classNames(styles.button_more_product_info, { [styles.open]: showContent })}
                    onClick={handleExpandClick}
                >
                    {showContent
                        ? helper.decodeHTML(store?.localization.generalL10n.less)
                        : helper.decodeHTML(store?.localization.generalL10n.more)}
                    <IconArrowExtraSmallRight />
                </div>
            </div>
        );
    })
);

interface IProductAdditionalLayout {
    store?: MainStore;
    renderAdditionalLayout: boolean;
    productInfoContentHeight: number;
    productContentRef: React.MutableRefObject<HTMLDivElement | null>;
    showContent: boolean;
    setShowContent(newValue: boolean);
    isArticleSet?: boolean;
}

export default ProductAdditionalLayout;
