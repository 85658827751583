import React from 'react';

/* eslint-disable */
const ArrowDownMobileShutters = () => (
    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="39.80042" height="16.80041" viewBox="0 0 39.80042 16.80041">
        <path d="M19.90021,0a.893.893,0,0,1,.55762.19434l19,15a.8997.8997,0,0,1-1.11524,1.41211L19.90021,2.04688,1.45783,16.60645A.8997.8997,0,1,1,.34259,15.19434l19-15A.893.893,0,0,1,19.90021,0Z" />
    </svg>
);

export default ArrowDownMobileShutters;
