import React from 'react';
import { ILocalization } from '../../types';
import Cross from '../icons/cross';
import WorkwearstoreIcon from '../icons/workwearstoreIcon';
import styles from './workwearStoreAvailabilityModal.scss';
import WorkWearStoreStore from '../../stores/WorkWearStoreStore';
import { IVariantsStoreInformation } from '../../stores/types';
import WorkwearStore from './workwearStore/workwearStore';
import { inject, observer } from 'mobx-react';

const WorkwearStoreAvailabilityModal = inject('workWearStoreStore')(
    observer(({ localization, isOpen, workWearStoreStore, onClose }: IWorkwearStoreAvailabilityModal) => {
        const wwsaModalRef = React.useRef<HTMLDivElement>(null);
        const selectedColor = workWearStoreStore?.selectedColor?.name;
        const selectedModel = workWearStoreStore?.selectedModel?.label;
        const selectedSizeType = workWearStoreStore?.selectedSizeType?.sizeTypeName;
        const fullSizeName: string =
            (selectedSizeType ? selectedSizeType + ': ' : '') +
            workWearStoreStore?.wwsVariantSelectionCopy.selectedSize?.label;
        if (!isOpen) {
            return <></>;
        }

        function expandCollapse(storeName: string) {
            if (workWearStoreStore?.noVariants) return;

            if (workWearStoreStore?.openedStore == storeName) workWearStoreStore.changeOpenedStore('');
            else workWearStoreStore?.changeOpenedStore(storeName);
        }

        function checkStoreStatus(variantStoreInformation: IVariantsStoreInformation): number {
            const variant = variantStoreInformation.variants.find(element => {
                return (
                    element.colorCode === workWearStoreStore?.selectedColor?.code &&
                    element.sizeCode === workWearStoreStore.selectedSize?.size.code &&
                    element.modelCode === workWearStoreStore.selectedModel?.code
                );
            });
            if (variant) return variant.availabilityStatus;
            return 3;
        }

        function labelAndText(): string {
            const result: Array<string> = [];
            if (selectedColor) {
                result.push(localization.generalL10n.textColor + ': ' + selectedColor);
            }
            if (fullSizeName) {
                result.push(localization.generalL10n.textSize + ': ' + fullSizeName);
            }
            if (selectedModel) {
                result.push(localization.generalL10n.textModel + ': ' + selectedModel);
            }
            return result.join(' - ');
        }

        function goToWorkwearStore(event: React.MouseEvent) {
            event.preventDefault();
            window.open(localization.wwsOnlyL10n?.workWearStoresLink, '_blank');
        }

        return (
            <div className={styles.wwsa_modal} data-testid='wwsa_modal_root'>
                <div ref={wwsaModalRef} className={styles.wwsa_modal_container} data-testid='modal-container'>
                    <div className={styles.wwsa_modal_content}>
                        <h2 className={styles.wwsa_modal_header}>
                            <span className={styles.wwsa_modal_title}>
                                {localization.wwsOnlyL10n?.checkWorkwearstoreAvailability}
                            </span>
                            <div className={styles.close_button} data-testid='close_button_header' onClick={onClose}>
                                <Cross />
                            </div>
                        </h2>
                        <div className={styles.wwsa_modal_middle}>
                            <p className={styles.wws_selected_values}>
                                <strong>{workWearStoreStore?.mainStore.dataStore.orderModule.articleInfo.title}</strong>
                                {labelAndText()}
                            </p>
                            <div className={styles.wws_wrapper}>
                                {workWearStoreStore?.wwsData?.variantsStoreInformation.map(
                                    (variantStoreInformation: IVariantsStoreInformation, index) => (
                                        <WorkwearStore
                                            key={index}
                                            localization={localization}
                                            variantStoreInformation={variantStoreInformation}
                                            status={checkStoreStatus(variantStoreInformation)}
                                            isCollapsed={
                                                workWearStoreStore.openedStore !=
                                                variantStoreInformation.retailStoreName
                                            }
                                            expandCollapse={() =>
                                                expandCollapse(variantStoreInformation.retailStoreName)
                                            }
                                            workWearStoreStore={workWearStoreStore}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <div className={styles.wwsa_modal_footer}>
                            <div className={styles.opening_hours_container}>
                                <button
                                    data-testid='opening_hours'
                                    className={styles.opening_hours}
                                    onClick={e => goToWorkwearStore(e)}
                                >
                                    <WorkwearstoreIcon />
                                    <p className={styles.opening_hours_text}>
                                        {localization.wwsOnlyL10n?.openingHoursAndAdditionalInfo}
                                    </p>
                                </button>
                            </div>
                            <p className={styles.wwsa_modal_bottom}>* {localization.wwsOnlyL10n?.infoSoldOut}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

interface IWorkwearStoreAvailabilityModal {
    localization: ILocalization;
    isOpen: boolean;
    workWearStoreStore?: WorkWearStoreStore;
    onClose?();
}

export default WorkwearStoreAvailabilityModal;
