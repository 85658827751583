import React from 'react';
/* eslint-disable max-len */
const StopWatch = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20.4093 23.01245'>
        <path d='M13.47864,12.1123H10.90051V7.79492a.90039.90039,0,1,0-1.80078,0V13.0127a.90065.90065,0,0,0,.90039.90039h3.47852a.9004.9004,0,0,0,0-1.80079Z' />
        <path d='M7.82629,1.80078H12.174A.90039.90039,0,0,0,12.174,0H7.82629a.90039.90039,0,1,0,0,1.80078Z' />
        <path
            d='M18.63928,5.70752l.23291.23291A.90046.90046,0,1,0,20.14563,4.667L18.40637,2.92773a.90046.90046,0,1,0-1.27344,1.27344l.23291.23291L16.465,5.335
		c-.01026.01025-.01343.02417-.023.03467a9.95865,9.95865,0,1,0,1.2583,1.26416c.01159-.01062.02685-.01416.03808-.02539ZM10,21.2124a8.2,8.2,0,1,1,8.2-8.19995
		A8.20921,8.20921,0,0,1,10,21.2124Z'
        />
    </svg>
);

export default StopWatch;
