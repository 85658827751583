import React from 'react';
import { observer, inject } from 'mobx-react';
import styles from './colorPanel.scss';
import classNames from 'classnames';
import { IVariantStore } from '../../../types/index';
import { CustomSwiper } from '../customeSwiper/customeSwiper';

export const ColorPanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const isMobile = variantStore?.mainStore.isMobileShop;
        let selectedColor = variantStore?.selectedColor;
        const availableColors = variantStore?.variantSelectionCopy.availableColors;
        const tmpSelectedColorIndex = availableColors?.findIndex(color => {
            return color.code === selectedColor?.code;
        });
        const selectedColorIndex = tmpSelectedColorIndex ? tmpSelectedColorIndex : 0;
        const shouldShowImage =
            isMobile && !!variantStore && variantStore.variantSelectionCopy.selectedColor &&
            variantStore.variantSelectionCopy.selectedColor.code > 0;

        function handleColorClick(event: React.MouseEvent) {
            event.preventDefault();
            event.stopPropagation();
            const target = event.currentTarget as HTMLElement;
            const colorCode = target.dataset.colorcode;
            if (colorCode) {
                const selectedColorInternal = availableColors?.find(
                    availableColor => availableColor.code == parseInt(colorCode)
                );

                if (selectedColorInternal) {
                    selectedColor = { code: selectedColorInternal.code, name: selectedColorInternal.name };
                    variantStore?.changeSelectedColor(selectedColor);
                }
            }
        }

        return (
            <>
                <div role='color_content_wrapper' className={styles.color_content_wrapper}>
                    {isMobile ? (
                        <label className={styles.choosen_color_label}>
                            {variantStore?.mainStore.localization.generalL10n.selection}:{' ' + selectedColor?.name}
                        </label>
                    ) : null}
                    <div role='color_content' className={styles.color_content}>
                        <div role='color_icons' className={styles.color_icons}>
                            <CustomSwiper
                                type='color'
                                selectedElementIndex={selectedColorIndex}
                                slidesPerColumn={2}
                                columnsPerView={5}
                                enabled={!!isMobile}
                            >
                                {availableColors?.map((availableColor, index) => (
                                    <a
                                        key={index}
                                        className={classNames(styles.color_border, {
                                            'pdp-selected': selectedColorIndex === index,
                                        })}
                                        data-colorcode={availableColor.code}
                                        onClick={handleColorClick}
                                        href={variantStore?.getUrlByAvailableColor(availableColor)}
                                        title={
                                            variantStore?.mainStore.dataStore.orderModule.articleInfo.title + ' ' +
                                            availableColor.name}
                                    >
                                        <img
                                            className={styles.color_icon}
                                            src={availableColor.iconPath}
                                            title={availableColor.name}
                                            alt={variantStore?.mainStore.localization.generalL10n.colorIcon + ' ' +
                                                availableColor.name}
                                        ></img>
                                    </a>
                                ))}
                            </CustomSwiper>
                            {!isMobile ? (
                                <label className={styles.choosen_color_label}>
                                    {variantStore?.mainStore.localization.generalL10n.selection}:{' ' + selectedColor?.name}
                                </label>
                            ) : null}
                        </div>
                        {shouldShowImage && (
                            <>
                                <hr />
                                <div className={styles.image_content}>
                                    <img
                                        data-testid='side_image'
                                        className={styles.side_image}
                                        src={variantStore?.sideImagePath}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    })
);
