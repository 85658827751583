import React from 'react';

/* eslint-disable */
function OnlineCatalogIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16.80078 23.43829">
            <g data-name="Ebene 2">
                <g>
                    <path d="M15.90039,3.658A.90065.90065,0,0,0,15,4.55841v16.0459a1.02031,1.02031,0,0,1-1.00586,1.03222H6.77722c.63111-.22278,4.08606-1.44336,4.08606-1.44336h.001a2.82208,2.82208,0,0,0,1.98047-3.15332V2.49884c0-1.20215,0-1.86426-.58008-2.27735-.56055-.39941-1.16211-.1914-1.99317.09668C8.88965.79376,3.26465,2.78692,1.98145,3.242A2.886,2.886,0,0,0,0,6.39532v14.541c0,1.20507,0,1.86816.582,2.28125a1.15794,1.15794,0,0,0,.69727.2207l12.71484-.001a2.82332,2.82332,0,0,0,2.80664-2.833V4.55841A.90065.90065,0,0,0,15.90039,3.658ZM1.80078,6.39532c0-.94238.0918-1.21191.78125-1.45605,1.28223-.45508,8.39746-2.96192,8.46-2.9834.002.168.002,15.084.002,15.084,0,.95508-.02833,1.19141-.7793,1.45606,0,0-8.40039,2.96289-8.46192,2.98437C1.80078,21.31231,1.80078,6.39532,1.80078,6.39532Z" /><path d="M8.13086,12.60235l-3.88672,1.3877a.70009.70009,0,0,0,.23535,1.35937.71116.71116,0,0,0,.23535-.041l3.88672-1.3877a.69993.69993,0,1,0-.4707-1.31836Z" /><path d="M4.47949,12.43438a.69848.69848,0,0,0,.23535-.041L8.60156,11.0047a.70064.70064,0,0,0,.46485-.65918V6.074a.7.7,0,0,0-.93555-.65918L4.24414,6.80255a.70062.70062,0,0,0-.46484.65918v4.27246a.7004.7004,0,0,0,.70019.70019Zm.7002-4.47949L7.666,7.0672V9.85235L5.17969,10.741Z" />
                </g>
            </g>
        </svg>
    );
}

export default OnlineCatalogIcon;