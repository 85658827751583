import React from 'react';
/* eslint-disable max-len */
const DesignWithAdviceIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.397"
        height="40.026"
        viewBox="0 0 47.397 40.026"
    >
        <path
            d="M12.968 38.826a4.094 4.094 0 01-4.089-4.092v-18.2l-.89.511a4.067 4.067 0 01-2.035.547 4.117 4.117 0 01-1.065-.141 4.066 4.066 0 01-2.485-1.906l-3.057-5.293a4.1 4.1 0 011.5-5.59L9.836-.53h.008a4.892 4.892 0 012.466-.666h3.188a1.2 1.2 0 011.113.662 6.52 6.52 0 005.858 3.623 6.512 6.512 0 005.856-3.623 1.2 1.2 0 011.113-.662h3.187a4.886 4.886 0 012.562.724l8.971 5.182a4.1 4.1 0 011.5 5.586l-3.057 5.293a4.059 4.059 0 01-2.483 1.906 4.066 4.066 0 01-1.065.143 4.065 4.065 0 01-2.04-.551 1.2 1.2 0 01-.44-1.639 1.2 1.2 0 011.64-.439 1.674 1.674 0 001.282.168 1.679 1.679 0 001.027-.787l3.055-5.293a1.692 1.692 0 00-.617-2.31l-8.982-5.188-.035-.021a2.492 2.492 0 00-1.315-.375h-2.519a8.968 8.968 0 01-2.869 2.909 8.915 8.915 0 01-4.766 1.376 8.919 8.919 0 01-4.766-1.376 8.994 8.994 0 01-2.869-2.909h-2.523a2.5 2.5 0 00-1.234.325l-.04.024-8.989 5.192a1.7 1.7 0 00-.62 2.311l3.057 5.293a1.674 1.674 0 001.027.789 1.682 1.682 0 001.282-.168l2.66-1.526a1.18 1.18 0 01.4-.155l.03-.005h.025a1.2 1.2 0 011.212.591 1.2 1.2 0 01.158.658v20.21a1.693 1.693 0 001.689 1.693h17.474a1.2 1.2 0 011.2 1.2 1.2 1.2 0 01-1.2 1.2z"
            transform="translate(1.199 1.2)"
        ></path>
        <path d="M40.398 37.965a8.442 8.442 0 01-3.852-1 21.767 21.767 0 01-7.393-6.644 21.788 21.788 0 01-3.876-9.152 7.872 7.872 0 01.873-5.194 10.5 10.5 0 012.777-2.831 2.434 2.434 0 011.459-.484 2.5 2.5 0 012.228 1.406l.006.012c.331.678 1.089 2.288 1.642 3.464l.462.98a3.506 3.506 0 01.586 2.194c-.163.779-.789 1.145-1.515 1.568l-.237.138c-.2.114-.5.288-1.039.608l-.162.1a22.512 22.512 0 002.015 3.468 22.2 22.2 0 002.625 3.037l.139-.119c.568-.494.763-.659 1.115-.961l.014-.012a2.756 2.756 0 011.772-.929c.761 0 1.258.481 2.082 1.278.871.84 2.962 2.861 3.532 3.45a2.462 2.462 0 01.713 1.914 2.548 2.548 0 01-1 1.835 9.991 9.991 0 01-3.622 1.737 6.469 6.469 0 01-1.344.137zM30.387 14.859a.246.246 0 00-.149.051 8.653 8.653 0 00-2.2 2.2 5.685 5.685 0 00-.583 3.739 19.8 19.8 0 003.491 8.2 19.776 19.776 0 006.616 5.967 6.243 6.243 0 002.838.756 4.268 4.268 0 00.885-.09 7.764 7.764 0 002.745-1.333.358.358 0 00.142-.247.274.274 0 00-.1-.222c-.565-.583-2.809-2.75-3.483-3.4-.2-.2-.41-.4-.566-.536l-.334.28-.012.01c-.348.3-.54.461-1.1.948a2.5 2.5 0 01-1.6.794 2.136 2.136 0 01-1.455-.707 24.453 24.453 0 01-2.936-3.387 24.765 24.765 0 01-2.244-3.873 2.031 2.031 0 01-.138-1.794 2.766 2.766 0 011.2-1.081c.551-.324.852-.5 1.051-.615l.228-.133c.116-.068.255-.149.371-.22-.084-.2-.212-.47-.322-.7l-.463-.984c-.548-1.165-1.3-2.76-1.624-3.427a.3.3 0 00-.258-.196z"></path>
    </svg>
);

export default DesignWithAdviceIcon;

