import React, { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './modelPanel.scss';
import { IVariantStore } from '../../../types/index';
import { CustomSwiper } from '../customeSwiper/customeSwiper';
import WWSKioskInfo from '../../workwearStoreAvailabilityModal/wwsKioskInfo/wwsKioskInfo';
import { IAvailableModel, VariantType, AvailabilityStatusEnum } from '../../../stores/types';

export const ModelPanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const isMobile = variantStore?.mainStore.isMobileShop;

        const selectedModelName = variantStore?.selectedModel?.label;
        let selectedModel = variantStore?.selectedModel;
        const availableModels = variantStore?.variantSelectionCopy.availableModels;
        const tmpSelectedModelIndex = availableModels?.findIndex(model => {
            return selectedModelName === model.label;
        });
        const selectedModelIndex = tmpSelectedModelIndex ?? 0;
        const shouldShowImage =
            !!isMobile && !!variantStore && variantStore.variantSelectionCopy.selectedModel &&
            variantStore.variantSelectionCopy.selectedModel.code > 0;
        const [column, setColumn] = useState(1);
        const [maxWidth, setMaxWidth] = useState(0);
        const wrapperRef = useRef<HTMLDivElement>(null);
        const [finishedRender, setFinishedRender] = useState(false);
        useEffect(() => {
            calculateWidth();
            setFinishedRender(true);
        }, []);

        function handleModelClick(event) {
            const modelCode = event.currentTarget.dataset.modelcode;
            const selectedModelInternal = availableModels?.find(
                availableModel => availableModel.code == parseInt(modelCode)
            );

            if (selectedModelInternal) {
                selectedModel = { code: selectedModelInternal.code, label: selectedModelInternal.label };
                variantStore?.changeSelectedModel(selectedModel);
            }
        }

        function calculateWidth() {
            if (wrapperRef.current) {
                const wrapperChildren = Array.from(wrapperRef.current.children as HTMLCollectionOf<HTMLElement>);
                let maxChildWidth = 0;
                wrapperChildren.forEach(element => {
                    if (element.clientWidth > maxChildWidth) maxChildWidth = element.clientWidth;
                });
                let tempColumn = 1;
                if (maxChildWidth < 42) {
                    maxChildWidth = 42;
                }
                // extra small devices (iphone 5/SE):
                if (wrapperRef.current.clientWidth < 370) {
                    tempColumn = Math.floor((wrapperRef.current.clientWidth - 80) / maxChildWidth);
                } else {
                    if (maxChildWidth > 110 && maxChildWidth <= 170) tempColumn = 2;
                    else if (maxChildWidth <= 110 && maxChildWidth > 80) tempColumn = 3;
                    else if (maxChildWidth <= 80 && maxChildWidth > 37) tempColumn = 4;
                    else if (maxChildWidth <= 42) {
                        tempColumn = 5;
                    }
                }

                setMaxWidth(maxChildWidth);
                if (!shouldShowImage)
                    wrapperRef.current.setAttribute('style', `grid-template-columns: repeat(${tempColumn}, 1fr);`);
                else setColumn(tempColumn);
            }
        }

        function modelClassNames(index, model: IAvailableModel): string {
            const classNames: string[] = [styles.model_icon];
            if (selectedModelIndex === index) classNames.push('pdp-selected');
            if (!model.salesArticleVariantKey
                && !variantStore?.mainStore.showKioskLayout) classNames.push(styles.sold_out_model);

            if (variantStore?.mainStore.showKioskLayout) {
                if (!variantStore)
                    classNames.push(styles.variant_status_red);
                else {
                    const wwsStore = variantStore.mainStore.workWearStoreStore;
                    const status = wwsStore.
                        variantAvailabilityState(model.code, VariantType.Model,
                            model.salesArticleVariantKey, wwsStore.variantInfoInKiosk);
                    if (status === AvailabilityStatusEnum.InStock)
                        classNames.push(styles.variant_status_green);
                    else
                        classNames.push(styles.variant_status_red);
                }
            }
            
            return classNames.join(' ');
        }

        return (
            <div role='model_content_wrapper' className={styles.model_content_wrapper}>
                {isMobile ? (
                    <label className={styles.choosen_model_label}>
                        {variantStore?.mainStore.localization.generalL10n.selection}:{' ' + selectedModelName}
                    </label>
                ) : null}
                <div role='model_content' className={styles.model_content}>
                    <div role='model_icons' ref={wrapperRef} className={styles.model_icons}>
                        <CustomSwiper
                            type='model'
                            selectedElementIndex={selectedModelIndex}
                            enabled={finishedRender && !!shouldShowImage}
                            slidesPerColumn={2}
                            columnsPerView={column}
                        >
                            {availableModels?.map((availableModel, index) => (
                                <div key={index} className={modelClassNames(index, availableModel)}
                                    title={availableModel.label}
                                    data-modelcode={availableModel.code}
                                    onClick={handleModelClick}
                                    style={maxWidth > 0 ? { width: `${maxWidth}px` } : {}}
                                >
                                    {availableModel.label}
                                </div>
                            ))}
                        </CustomSwiper>
                    </div>
                    {shouldShowImage && (
                        <>
                            <hr />
                            <div className={styles.image_content}>
                                <img
                                    data-testid='side_image'
                                    className={styles.side_image}
                                    src={variantStore?.sideImagePath}
                                />
                            </div>
                        </>
                    )}
                    {!isMobile ? (
                        <label className={styles.choosen_model_label}>
                            {variantStore?.mainStore.localization.generalL10n.selection}:{' ' + selectedModelName}
                        </label>
                    ) : null}
                    <WWSKioskInfo />
                </div>
            </div>
        );
    })
);
