import React from 'react';

/* eslint-disable */
function GlovesPropertiesAndStandardsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 19.34448 22.59503">
            <g data-name="Ebene 2">
                <g>
                    <g data-name="Handschuh Eigenschaften Step 2">
                        <path d="M13.78137,4.416a2.115,2.115,0,0,0-.73145.12988V3.73828A2.13108,2.13108,0,0,0,10.15246,1.749a2.1324,2.1324,0,0,0-4.19531,0,2.133,2.133,0,0,0-2.89844,1.915L3.0509,8.16211A2.2314,2.2314,0,0,0,.02648,10.58887c.02539.14648.61817,3.59375.98731,4.68554a6.279,6.279,0,0,0,1.71093,2.69043l.22071.21875a8.167,8.167,0,0,1,1.54492,1.84082.78843.78843,0,0,0,.94434.2959.69879.69879,0,0,0,.29589-.94433,9.21845,9.21845,0,0,0-1.79882-2.18457l-.2168-.2168A4.84584,4.84584,0,0,1,2.34,14.82715c-.27734-.82129-.76758-3.50879-.93164-4.46289a.83331.83331,0,0,1,1.64746-.252c.0992.46527.7168,3.36133.7168,3.36133a.52031.52031,0,0,0,.57129.38867.49933.49933,0,0,0,.41406-.55273c-.00293-.02441-.29688-2.4834-.30957-3.36914L4.4475,9.93652,4.4591,3.665a.732.732,0,0,1,1.4602.0603c.00342,1.12744.25367,6.43927.26539,6.66626a.49987.49987,0,0,0,.999-.00976c.001-.05133.13965-8.25.13965-8.25a.73145.73145,0,1,1,1.46289,0s.19238,7.95312.20019,8.17285a.50036.50036,0,0,0,1,0c.00782-.22461.2002-6.16309.2002-6.56641a.73145.73145,0,0,1,1.46289,0s.1416,6.54376.14746,6.65625a.50288.50288,0,0,0,.48828.47461.51785.51785,0,0,0,.50879-.45215c.00977-.1084.25349-3.49648.25586-3.86816a.756.756,0,0,1,.73145-.73242.73068.73068,0,0,1,.73242.72265L14.4591,10.3584a.70259.70259,0,0,0,.69043.71.72134.72134,0,0,0,.71-.69043l.05469-3.8291A2.13555,2.13555,0,0,0,13.78137,4.416Z" /><path d="M14.34448,13.795a3.8,3.8,0,1,1-3.80005,3.8,3.80432,3.80432,0,0,1,3.80005-3.8m0-1.2a5,5,0,1,0,5,5,5,5,0,0,0-5-5Z" /><path d="M14.36437,16.22754a.60393.60393,0,0,1-.60449-.59961.59559.59559,0,0,1,.59473-.59961h.00976a.59961.59961,0,1,1,0,1.19922Z" /><path d="M14.34484,20.16113a.59974.59974,0,0,1-.59961-.59961v-2a.59961.59961,0,0,1,1.19922,0v2A.59973.59973,0,0,1,14.34484,20.16113Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default GlovesPropertiesAndStandardsIcon;