import React from 'react';
import styles from './additionalDescription_text.scss';
import { IAdditionalDescriptionText } from '../../../stores/types';

function AdditionalDescriptionText(props: IAdditionalDescriptionText) {
    return (<div className={styles.richtext}>
        <div dangerouslySetInnerHTML={{ __html: props.value }}/>
    </div>);
}

export default AdditionalDescriptionText;
