import { Component } from 'react';
import styles from './layoutedContentGrid.scss';
import classNames from 'classnames';
import React from 'react';
import DebugBarHelper from '../../../helper/debugBarHelper';
import DebuggerDataSource from '../../debugBar/debuggerDataSource';

export default class LayoutedContentGrid extends Component<{
    layoutedContentGridKeys: string[], 
    debuggerIsOn: boolean, isRelease: boolean,
    updateLayoutedContentGridState,
    colorCode: number}, {}> {
    private debugBarHelper: DebugBarHelper;
    private addLcgGridTimer;
    private lcgContentAvailabilityTimer;

    constructor(props) {
        super(props);

        this.debugBarHelper = DebugBarHelper.instance;
    }

    componentDidMount(): void {
        if (this.props.layoutedContentGridKeys
            && this.props.layoutedContentGridKeys.length > 0) {
            this.addLcgGridTimer = setTimeout(() => {
                this.addLcgGrid();

            }, 1000); // 1000 s needed for LCG assets to be ready
        }
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.colorCode !== this.props.colorCode) {
            this.addLcgGrid();
        }
        else {
            if(this.props.layoutedContentGridKeys && this.props.layoutedContentGridKeys.length <= 0)
                this.props.updateLayoutedContentGridState(false);
        }
    }

    private addLcgGrid(): void {
        this.props.layoutedContentGridKeys.forEach(value => {
            const containerId = 'pdp-lcg-' + value;

            window.shell.publishTo('ESMKT.LayoutedContentGrid.AddLcgGrid',
                {
                    gridId: value, containerId: containerId,
                    callback: {
                        resolve: () => this.props.updateLayoutedContentGridState(true),
                        reject: () => this.props.updateLayoutedContentGridState(false)
                    }
                });
        });
    }

    componentWillUnmount(): void {
        clearTimeout(this.addLcgGridTimer);
        clearTimeout(this.lcgContentAvailabilityTimer);
    }

    render() {
        return (
            <div data-testid='layouted-content-grid'
                className={classNames(styles.layoutedContentGrid,
                {
                    [this.debugBarHelper.getDebuggerClassName(
                        DebugBarHelper.LayoutedContentGrid_source)]: this.props.debuggerIsOn
                })}>
                {this.props.layoutedContentGridKeys && 
                    (this.props.layoutedContentGridKeys.map((value, index) => {
                        const containerId = 'pdp-lcg-' + value;
                        return (<div key={index} className={styles.gridContent} data-testid='grid-content'>
                            {!this.props.isRelease &&
                                <DebuggerDataSource value={'Layouted Content Grid source Key: "' + value + '"'}/>
                            }
                            <div id={containerId} data-grid-id={value}></div>
                        </div>)
                    }))}
            </div>
        );
    }
}
