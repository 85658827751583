import React from 'react';

export const useMixedMediaContentWidth = () => {
    const mixedMediaElement = document.querySelector('.pdp-mixed_media_container') || { clientWidth: 0 };
    const [contentWidth, setWidth] = React.useState(mixedMediaElement.clientWidth);

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWidth(mixedMediaElement.clientWidth);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { contentWidth };
};
