import React, { useEffect } from 'react';
import { MainImage } from './element_1/mainImage';
import { AdditionalInfoImage } from './element_3/additionalInfoImage';
import { inject, observer } from 'mobx-react';
import { IMixedMediaStore } from '../../types';
import styles from './mixedMedia.scss';
import ThreeDView from './element_2/threeDView';
import classNames from 'classnames';
import { SmallMainImage } from './element_2/smallMainImage';
import Gallery3dOverlay from './element_2/gallery3dOverlay';
import Icon_3D from '../icons/Icon_3D';

export const MixedMedia = inject('mixedMediaStore')(
    observer(({ mixedMediaStore }: IMixedMediaStore) => {
        if (!mixedMediaStore)
            return null;
        const threeDViewContainerId = 'ThreeSixtyView_mixedMedia';

        useEffect(() => {
            window.shell.subscribeTo(
                'SCS.Close.Gallery3DView',
                () => {
                    mixedMediaStore.handleShowGallery3d();
                },
                'SCS.Close.Gallery3DView'
            );

            window.shell.subscribeTo(
                'SCS.Hide.Fragment',
                payload => {
                    if(payload.containerId === threeDViewContainerId)
                        mixedMediaStore.manageSCSFragment(payload.hidden);
                },
                'SCS.Hide.Fragment'
            );

            return () => {
                window.shell.unsubscribeFrom('SCS.Close.Gallery3DView', 'SCS.Close.Gallery3DView');
                window.shell.unsubscribeFrom('SCS.Hide.Fragment', 'SCS.Hide.Fragment');
            };
        }, []);

        function handleShowGallery3d(): void {
            mixedMediaStore?.handleShowGallery3d();
            window.shell.publishTo('SCS.Open.Gallery3DView', mixedMediaStore?.displayG3dOverlay);
        }

        return (
            <div
                data-testid='mixed_media'
                className={classNames(styles.mixed_media_container, mixedMediaStore.getLayoutClass)}
            >
                {mixedMediaStore.mainImage && <MainImage />}
                <div className={styles.add_images}>
                    <ThreeDView
                        altTag={mixedMediaStore.getAltValue()}
                        variantColorCode={mixedMediaStore.colorCode}
                        fallBackImageUrl={mixedMediaStore.fallBackImageUrl}
                        srcSet={mixedMediaStore.mainImage.srcSet.urls}
                        masterArticleNo={mixedMediaStore.masterArticleNoDefault}
                        colorCode={mixedMediaStore.colorCodeDefault}
                        showThreeDOnly={!mixedMediaStore.shouldRenderAdditionalInfoImage}
                        handleShowGallery3d={handleShowGallery3d}
                        has3DView={mixedMediaStore.has3DView}
                        temporaryHideSCS={mixedMediaStore.temporaryHideSCS}
                        containerId={threeDViewContainerId}
                    />
                    {mixedMediaStore.shouldRenderMainImageInElement2 && <SmallMainImage />}
                    {mixedMediaStore.shouldRenderAdditionalInfoImage && <AdditionalInfoImage />}
                </div>
                <Gallery3dOverlay
                    masterArticleNo={mixedMediaStore.masterArticleNoDefault}
                    colorCode={mixedMediaStore.colorCodeDefault}
                    displayG3dOverlay={mixedMediaStore.displayG3dOverlay}
                />
                {mixedMediaStore.has3DView && (
                    <div
                        className={classNames(styles.mobile_icon_3d, {
                            [styles.temporary_hide_scs]: mixedMediaStore.temporaryHideSCS,
                        })}
                        onClick={handleShowGallery3d}
                    >
                        <Icon_3D />
                    </div>
                )}
            </div>
        );
    })
);
